import logo200Image from 'assets/img/logo/logo_200.png';
import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from 'components/SourceLink';

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          {global.config.copyright}{' '}         
          {global.config.version}{' '}   
          Powered by <img src={logo200Image} height="25px" />.          
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
