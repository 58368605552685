import React from 'react';
import Page from 'components/Page';
import ComboBox from './component/ComboBox';
import {Button,Card,CardBody,CardHeader,Col,Form,FormGroup,Input,Label} from 'reactstrap';
import { Cookies } from 'react-cookie';

import Base64 from 'Base64';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import DataAdapter from './adapter/DataAdapter';

const cookie =new Cookies();

class FormProject extends React.Component {
	notificationSystem = React.createRef();

	constructor(props) {
		super(props);
		var today = new Date();
		var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());

		this.state = {
			clients: [],
			prj_department:12,
			prj_code: '000',
			prj_status: 1,
			prj_type: 1,
			prj_name: '',
			prj_title: '',
			prj_desc: '',
			prj_address: '',
			prj_city: '',
			prj_postal_code: '',
			prj_posted_date: date,
			prj_date: date,
			client_id: 0,
			contact_name: 0,
			refered_by_person_type: 0,
			refered_by_person_id: 0,
			refered_by_prj_id: 0,
			prj_cat_loc: 0,
			prj_folder: '',
			prj_folder_id: '',
			prj_category: '',
			prj_category_1: 0,
			prj_category_2: 0,
			prj_category_3: 0,
			prj_budget_desc: '',
			prj_budget: '',
			prj_budget_lowest: '',
			prj_budget_highest: '',
			start_date_desc: '',
			notes: '',
			uid: 0,
			person_type: 1,
			person_name: '',
			person_lastname: '',
			person_city: '',
			person_address: '',
			postal_code: '',
			person_tel: '',
			person_email: '',
			person_role: 8,
			task_type	:	1,
			has_permit		:	false,
			has_drawings	:	false,
			has_estimate	:	false,
		};

		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		fetch('https://renobuilding.ca/wp-json/reno/v1/rb_person?person_type=-1', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				console.log(responseData);
				this.setState({ clients: responseData });
			})
			.catch(error => {
				console.error('Error:', error);
			})

	}

	onFieldChangeHandler = (event) => {
		event.persist();

		// console.log(event.target.selectedOptions[0].label);

		if (event.target.id == 'has_permit'){
			this.setState({ has_permit: event.target.checked });
		}
		if (event.target.id == 'has_drawings'){
			this.setState({ has_drawings: event.target.checked });
			if(event.target.checked){
				this.setState({
					prj_status	:	3,
					task_type	:	2,
				})
			}

		}
		if (event.target.id == 'has_estimate'){
			this.setState({ has_estimate: event.target.checked });
		}

		if (event.target.id == 'prj_department')
			this.setState({ prj_department: event.target.value });
		if (event.target.id == 'prj_code')
			this.setState({ prj_code: event.target.value });
		if (event.target.id == 'prj_status')
			this.setState({ prj_status: event.target.value });
		if (event.target.id == 'prj_type')
			this.setState({ prj_type: event.target.value });
		if (event.target.id == 'prj_name')
			this.setState({ prj_name: event.target.value });
		if (event.target.id == 'prj_title'){
			this.setState({ prj_title: event.target.value });
			this.setState({ prj_desc: event.target.value });
		}
		if (event.target.id == 'prj_desc')
			this.setState({ prj_desc: event.target.value });
		if (event.target.id == 'prj_address') {
			this.setState({ prj_address: event.target.value });
			this.setState({ person_address: event.target.value });
		}
		if (event.target.id == 'prj_city') {
			this.setState({ prj_city: event.target.value });
			this.setState({ person_city: event.target.value });
		}
		if (event.target.id == 'prj_postal_code')
			this.setState({ prj_postal_code: event.target.value });
		if (event.target.id == 'prj_posted_date')
			this.setState({ prj_posted_date: event.target.value });
		if (event.target.id == 'prj_date')
			this.setState({ prj_date: event.target.value });
		if (event.target.id == 'prj_category')
			this.setState({ prj_category: event.target.value });
		if (event.target.id == 'prj_category_1'){
			
			this.setState({ prj_category_1: event.target.value });
			this.setState({ prj_category: this.state.prj_category + ',' + event.target.selectedOptions[0].label });
		}
		if (event.target.id == 'prj_category_2'){
			this.setState({ prj_category_2: event.target.value });
			this.setState({ prj_category: this.state.prj_category + ',' + event.target.selectedOptions[0].label });
		}
		if (event.target.id == 'prj_category_3'){
			this.setState({ prj_category_3: event.target.value });
			this.setState({ prj_category: this.state.prj_category + ',' + event.target.selectedOptions[0].label });
		}
		if (event.target.id == 'prj_budget_desc')
			this.setState({ prj_budget_desc: event.target.value });
		if (event.target.id == 'prj_budget_lowest')
			this.setState({ prj_budget_lowest: event.target.value });
		if (event.target.id == 'prj_budget')
			this.setState({ prj_budget: event.target.value });
		if (event.target.id == 'prj_budget_highest')
			this.setState({ prj_budget_highest: event.target.value });
		if (event.target.id == 'start_date_desc')
			this.setState({ start_date_desc: event.target.value });
		if (event.target.id == 'notes')
			this.setState({ notes: event.target.value });
		if (event.target.id == 'client_id'){
			this.setState({ client_id		: event.target.value });
			this.setState({ contact_name	: event.target.value });
			var __clientInfo	=	event.target.selectedOptions[0].text;
			var __clientNumber	=	__clientInfo.substring(__clientInfo.indexOf('(') +1, __clientInfo.indexOf(')'));
			if(__clientNumber!="" && __clientNumber!="null"){
				if(this.state.has_drawings){
					this.setState({
						prj_status	:	3,
						task_type	:	2,
					})
				}else{
					this.setState({
						prj_status	:	2,
						task_type	:	2,
					})
				}
			}
		}
		if (event.target.id == 'contact_name'){
			this.setState({ contact_name	: event.target.value });
		}
		if (event.target.id == 'refered_by_person_type')
			this.setState({ refered_by_person_type: event.target.value });
		if (event.target.id == 'refered_by_person_id')
			this.setState({ refered_by_person_id: event.target.value });
		if (event.target.id == 'refered_by_prj_id')
			this.setState({ refered_by_prj_id: event.target.value });
		if (event.target.id == 'uid')
			this.setState({ uid: event.target.value });

		if (event.target.id == 'person_type')
			this.setState({ person_type: event.target.value });
		if (event.target.id == 'person_name'){
			this.setState({ person_name: event.target.value });
			// this.setState({ contact_name: event.target.value });
		}
		if (event.target.id == 'person_lastname')
			this.setState({ person_lastname: event.target.value });
		if (event.target.id == 'person_city')
			this.setState({ person_city: event.target.value });
		if (event.target.id == 'person_address')
			this.setState({ person_address: event.target.value });
		if (event.target.id == 'person_tel')
			this.setState({ person_tel: event.target.value });
		if (event.target.id == 'person_email')
			this.setState({ person_email: event.target.value });
		if (event.target.id == 'postal_code')
			this.setState({ postal_code: event.target.value });

	}

	onSubmitHandler = (event) => {
		// Build formData object.
		let formData = new FormData();

		formData.append('prj_department', this.state.prj_department);
		formData.append('prj_code', this.state.prj_code);
		formData.append('prj_status', this.state.prj_status);
		formData.append('prj_type', this.state.prj_type);
		formData.append('prj_name', this.state.prj_name);
		formData.append('prj_title', this.state.prj_title);
		formData.append('prj_desc', this.state.prj_desc);
		formData.append('prj_address', this.state.prj_address);
		formData.append('prj_city', this.state.prj_city);
		formData.append('prj_postal_code', this.state.prj_postal_code);
		formData.append('prj_posted_date', this.state.prj_posted_date);
		formData.append('prj_date', this.state.prj_date);
		formData.append('prj_category', this.state.prj_category);
		formData.append('prj_category_1', this.state.prj_category_1);
		formData.append('prj_category_2', this.state.prj_category_2);
		formData.append('prj_category_3', this.state.prj_category_3);
		formData.append('start_date_desc', this.state.start_date_desc);
		formData.append('prj_budget_desc', this.state.prj_budget_desc);
		formData.append('prj_budget', this.state.prj_budget);
		formData.append('prj_budget_lowest', this.state.prj_budget_lowest);
		formData.append('prj_budget_highest', this.state.prj_budget_highest);
		formData.append('notes', this.state.notes);
		formData.append('client_id', this.state.client_id);
		formData.append('contact_name', this.state.contact_name);
		formData.append('uid', this.state.uid);
		formData.append('refered_by_person_id', this.state.refered_by_person_id);
		formData.append('refered_by_prj_id', this.state.refered_by_prj_id);
		formData.append('has_permit', (this.state.has_permit? 1:0));
		formData.append('has_drawings', (this.state.has_drawings? 1:0));
		formData.append('has_estimate', (this.state.has_estimate? 1:0));


		fetch("https://renobuilding.ca/wp-json/reno/v1/rb_projects",
			{
				method: "POST",
				headers: {
					'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			})
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				var prj_id = responseData;
				this.addNotification('New Project [' + prj_id + '] has been created.');
				//var dataAdapter = new DataAdapter("INSERT INTO rb_project_tasks (prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,due_date,task_description) VALUES (" + prj_id + "," + cookie.get("v-person-id") + "," + cookie.get("v-person-id") + ",now(), 1,1,'" + this.state.prj_title + "','(NOW() + INTERVAL 14 DAY)','" + this.state.prj_desc + "');");
				var dataAdapter = new DataAdapter("INSERT INTO rb_project_tasks (prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,due_date,task_description,expected_end_date) VALUES (" + prj_id + ",0," + cookie.get("v-person-id") + ",now(), 8," + this.state.task_type + ",'" + this.state.prj_title + "','(NOW() + INTERVAL 14 DAY)','" + this.state.prj_desc + "',now());");
				dataAdapter.getRecords().then((data)=>{
					this.addNotification('New task [' + prj_id + '] has been sent to you.');
				})
			})
			.catch(error => {
				console.error('Error:', error);
			})

	}

	onPersonSubmitHandler = (event) => {
		// Build formData object.
		let formData = new FormData();

		formData.append('person_type', this.state.person_type);
		formData.append('name', this.state.person_name);
		formData.append('lastname', this.state.person_lastname);
		formData.append('city', this.state.person_city);
		formData.append('address', this.state.person_address);
		formData.append('postal_code', this.state.postal_code);
		formData.append('person_tel', this.state.person_tel);
		formData.append('email', this.state.person_email);
		formData.append('person_role', this.state.person_role);


		fetch("https://renobuilding.ca/wp-json/reno/v1/rb_person",
			{
				method: "POST",
				headers: {
					'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			})
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				console.log(responseData);
				this.addNotification('New person [id : ' + responseData + '] has been created.');
				let requestOptions = {
					method: 'GET',
					headers: {
						'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					}
				};
				fetch('https://renobuilding.ca/wp-json/reno/v1/rb_person?person_type=1', requestOptions)
					.then(response => {
						return Promise.all([response.json(), response])
					})
					.then(([responseData, response]) => {
						this.setState({ clients		: responseData });
						
						// if(Number.isInteger(this.state.person_tel*1))
						// this.setState({prj_status:1})
			
					})
					.catch(error => {
						console.error('Error:', error);
					})
				this.setState({ client_id: responseData });
				this.setState({ contact_name: responseData });
			})
			.catch(error => {
				console.error('Error:', error);
			})

	}

	onContactSubmitHandler = (event) => {
		// Build formData object.
		let formData = new FormData();

		formData.append('person_type', this.state.person_type);
		formData.append('name', this.state.person_name);
		formData.append('lastname', this.state.person_lastname);
		formData.append('city', this.state.person_city);
		formData.append('address', this.state.person_address);
		formData.append('postal_code', this.state.postal_code);
		formData.append('person_tel', this.state.person_tel);
		formData.append('email', this.state.person_email);
		formData.append('person_role', this.state.person_role);


		fetch("https://renobuilding.ca/wp-json/reno/v1/rb_person",
			{
				method: "POST",
				headers: {
					'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			})
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				console.log(responseData);
				this.addNotification('New person [id : ' + responseData + '] has been created.');
				let requestOptions = {
					method: 'GET',
					headers: {
						'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
					}
				};
				fetch('https://renobuilding.ca/wp-json/reno/v1/rb_person?person_type=1', requestOptions)
					.then(response => {
						return Promise.all([response.json(), response])
					})
					.then(([responseData, response]) => {
						this.setState({ clients		: responseData });
						
						// if(Number.isInteger(this.state.person_tel*1))
						// this.setState({prj_status:1})
			
					})
					.catch(error => {
						console.error('Error:', error);
					})
				this.setState({ contact_name: responseData });
			})
			.catch(error => {
				console.error('Error:', error);
			})

	}

	addNotification(_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
			message: _MESSAGE_,
			level: 'success'
		});
	};


	render() {
		return (
			<Page title={this.props.form} breadcrumbs={[{ name: 'New Project', active: true }]}>
				<Col xl={12} lg={12} md={12}>
					<Card>
						<CardHeader>Define New Project</CardHeader>
						<CardBody>
							<Form>
								<FormGroup row >
									<Label for="prj_department" md={2} >Department</Label>
									<Col md={10}>
										<ComboBox id="prj_department" onChange={this.onFieldChangeHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="prj_department" defaultValue={this.state.prj_department} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_type" md={2}>Type</Label>
									<Col md={10} >
										<Input type="select" name="prj_type" id="prj_type" onChange={this.onFieldChangeHandler} >
											<option value={1} >Residential</option>
											<option value={2} >Commercial</option>
											<option value={3} >Industrial</option>
										</Input>
									</Col>
								</FormGroup>
								{/*
							<FormGroup row >
								<Label for="prj_code" md={2}>Code</Label>
								<Col md={10} >
									<Input
										type="text"
										name="prj_code"
										id="prj_code"
										placeholder=""
										onChange={this.onFieldChangeHandler}
									/>
								</Col>
							</FormGroup>
							{
							<FormGroup row >
								<Label for="prj_name" md={2}>Name</Label>
								<Col md={10} >
									<Input
										type="text"
										name="prj_name"
										id="prj_name"
										placeholder=""
										onChange={this.onFieldChangeHandler}
									/>
								</Col>
							</FormGroup>*/}

								<FormGroup row >
									<Label for="prj_title" md={2}>Title</Label>
									<Col md={10} >
										<Input
											type="text"
											name="prj_title"
											id="prj_title"
											placeholder=""
											onChange={this.onFieldChangeHandler}
										/>
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_desc" md={2} >Desc.</Label>
									<Col md={10}>
										<Input type="textarea" name="prj_desc" id="prj_desc" onChange={this.onFieldChangeHandler} value={this.state.prj_desc} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_address" md={2} >Address</Label>
									<Col md={10}>
										<Input type="textarea" name="prj_address" id="prj_address" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_city" md={2}>City</Label>
									<Col md={10} >
										<Input
											type="text"
											name="prj_city"
											id="prj_city"
											placeholder=""
											onChange={this.onFieldChangeHandler}
										/>
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_postal_code" md={2}>Postal Code</Label>
									<Col md={10} >
										<Input
											type="text"
											name="prj_postal_code"
											id="prj_postal_code"
											placeholder=""
											onChange={this.onFieldChangeHandler}
										/>
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_posted_date" md={2}>Received Date</Label>
									<Col md={10} >
										<Input
											type="date"
											name="prj_posted_date"
											id="prj_posted_date"
											placeholder=""
											defaultValue={this.state.prj_posted_date}
											onChange={this.onFieldChangeHandler}
										/>
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_date" md={2}>Registered Date</Label>
									<Col md={10} >
										<Input
											type="date"
											name="prj_date"
											id="prj_posted_date"
											prj_date=""
											defaultValue={this.state.prj_date}
											onChange={this.onFieldChangeHandler}
										/>
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="t" md={2} >Budget Min.</Label>
									<Col md={10}>
										<Input type="text" name="prj_budget_lowest" id="prj_budget_lowest" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="t" md={2} >Budget Max.</Label>
									<Col md={10}>
										<Input type="text" name="prj_budget_highest" id="prj_budget_highest" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="t" md={2} >Budget</Label>
									<Col md={10}>
										<Input type="text" name="prj_budget" id="prj_budget" onChange={this.onFieldChangeHandler} /*value={((this.state.prj_budget_highest*1+this.state.prj_budget_lowest*1)/2).toFixed(0)}*/ />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="t" md={2} >Budget Desc.</Label>
									<Col md={10}>
										<Input type="text" name="prj_budget_desc" id="prj_budget_desc" onChange={this.onFieldChangeHandler} value={this.state.prj_budget_lowest + '-' + this.state.prj_budget_highest} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_category_1" md={2} >Category</Label>
									<Col md={10}>
										<ComboBox id="prj_category_1" onChange={this.onFieldChangeHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_trade" order="rb_type_desc" />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_category_2" md={2} >Category</Label>
									<Col md={10}>
										<ComboBox id="prj_category_2" onChange={this.onFieldChangeHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_trade" order="rb_type_desc"  />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_category_3" md={2} >Category</Label>
									<Col md={10}>
										<ComboBox id="prj_category_3" onChange={this.onFieldChangeHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_trade" order="rb_type_desc"  />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="prj_category" md={2} >Category Desc.</Label>
									<Col md={10}>
										<Input type="text" name="prj_category" id="prj_category" onChange={this.onFieldChangeHandler} value={this.state.prj_category} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="notes" md={2} >Notes</Label>
									<Col md={10}>
										<Input type="textarea" name="notes" id="notes" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row >
									<Label for="refered_by_person_id" md={2} >Refered By</Label>
									<Col md={10}>
										<ComboBox onChange={this.onFieldChangeHandler} name="refered_by_person_id" id="refered_by_person_id" table="rb_person" valueField="person_id" displayField="name" filterField="person_type" filterValue="7' or 'x'='x" />
									</Col>
								</FormGroup>
								{this.state.refered_by_person_id==5 || this.state.refered_by_person_id==16 || this.state.refered_by_person_id==331?
									<>
										<FormGroup row >
											<Label for="refered_by_prj_id" md={2} >Refered Project Id</Label>
											<Col md={10}>
												<Input type="text" name="refered_by_prj_id" id="refered_by_prj_id" onChange={this.onFieldChangeHandler}/>
											</Col>
										</FormGroup>
										<FormGroup row >
											<Label for="start_date_desc" md={2} >Start Date</Label>
											<Col md={10}>
												<Input type="text" name="start_date_desc" id="start_date_desc" onChange={this.onFieldChangeHandler}/>
											</Col>
										</FormGroup>
										<FormGroup row >
											<Label for="uid" md={2} >Unique Identification Number (UID)</Label>
											<Col md={10}>
												<Input type="text" name="uid" id="uid" onChange={this.onFieldChangeHandler}/>
											</Col>
										</FormGroup>
									</>
								:''}
								<FormGroup row >
									<Label for="client_id" md={2}>Client</Label>
									<Col md={10} >
										<Input type="select" name="client_id" id="client_id" onChange={this.onFieldChangeHandler} value={this.state.client_id} >
											<option value={0} >-</option>
											<option value={-1} >[New Client]</option>
											{
												this.state.clients.map(client => {
													return (
														<option value={client.person_id} >{client.name + ' ' + client.lastname + ',' + client.city + ' (' + client.person_tel + ')'}</option>
													)
												})
											}

										</Input>
									</Col>
								</FormGroup>
								{this.state.client_id == -1 ?
									<div>
										<Col md={2} >
										</Col>
										<Col md={10} >
											<Card >
												<CardHeader>New Person</CardHeader>
												<CardBody>
													<FormGroup row >
														<Label for="person_type" md={2} >Type</Label>
														<Col md={10}>
															<ComboBox onChange={this.onFieldChangeHandler} name="person_type" id="person_type" table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_type" />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_name" md={2} >Name</Label>
														<Col md={4}>
															<Input type="text" name="person_name" id="person_name" onChange={this.onFieldChangeHandler} />
														</Col>
														<Label for="person_lastname" md={2} >Last Name</Label>
														<Col md={4}>
															<Input type="text" name="person_lastname" id="person_lastname" onChange={this.onFieldChangeHandler} />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_city" md={2} >City</Label>
														<Col md={2}>
															<Input type="text" name="person_city" id="person_city" onChange={this.onFieldChangeHandler} value={this.state.person_city} />
														</Col>
														<Label for="person_address" md={2} >Address</Label>
														<Col md={2}>
															<Input type="text" name="person_address" id="person_address" onChange={this.onFieldChangeHandler} value={this.state.person_address} />
														</Col>
														<Label for="postal_code" md={2} >Postal Code</Label>
														<Col md={2}>
															<Input type="text" name="postal_code" id="postal_code" onChange={this.onFieldChangeHandler} value={this.state.postal_code} />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_tel" md={2} >Tel</Label>
														<Col md={4}>
															<Input type="text" name="person_tel" id="person_tel" onChange={this.onFieldChangeHandler} />
														</Col>
														<Label for="person_email" md={2} >e-Mail</Label>
														<Col md={4}>
															<Input type="text" name="person_email" id="person_email" onChange={this.onFieldChangeHandler} />
														</Col>
													</FormGroup>
													<FormGroup row>
														<Col sm={{ size: 10, offset: 2 }}>
															<Button onClick={this.onPersonSubmitHandler} >Save Person</Button>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
										<br/>
									</div>
									:
									''
								}
								<FormGroup row >
									<Label for="contact_name" md={2}>Contact Person</Label>
									<Col md={10} >
										<Input type="select" name="contact_name" id="contact_name" onChange={this.onFieldChangeHandler} value={this.state.contact_name} >
											<option value={0} >-</option>
											<option value={-1} >[New Contact]</option>
											{
												this.state.clients.map(client => {
													return (
														<option value={client.person_id} >{client.name + ' ' + client.lastname + ',' + client.city + ' (' + client.person_tel + ')'}</option>
													)
												})
											}

										</Input>
									</Col>
								</FormGroup>
								{this.state.contact_name == -1 ?
									<div>
										<Col md={2} >
										</Col>
										<Col md={10} >
											<Card >
												<CardHeader>New Contact</CardHeader>
												<CardBody>
													<FormGroup row >
														<Label for="person_type" md={2} >Type</Label>
														<Col md={10}>
															<ComboBox onChange={this.onFieldChangeHandler} name="person_type" id="person_type" table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_type" />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_name" md={2} >Name</Label>
														<Col md={4}>
															<Input type="text" name="person_name" id="person_name" onChange={this.onFieldChangeHandler} />
														</Col>
														<Label for="person_lastname" md={2} >Last Name</Label>
														<Col md={4}>
															<Input type="text" name="person_lastname" id="person_lastname" onChange={this.onFieldChangeHandler} />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_city" md={2} >City</Label>
														<Col md={2}>
															<Input type="text" name="person_city" id="person_city" onChange={this.onFieldChangeHandler} value={this.state.person_city} />
														</Col>
														<Label for="person_address" md={2} >Address</Label>
														<Col md={2}>
															<Input type="text" name="person_address" id="person_address" onChange={this.onFieldChangeHandler} value={this.state.person_address} />
														</Col>
														<Label for="postal_code" md={2} >Postal Code</Label>
														<Col md={2}>
															<Input type="text" name="postal_code" id="postal_code" onChange={this.onFieldChangeHandler} value={this.state.postal_code} />
														</Col>
													</FormGroup>
													<FormGroup row >
														<Label for="person_tel" md={2} >Tel</Label>
														<Col md={4}>
															<Input type="text" name="person_tel" id="person_tel" onChange={this.onFieldChangeHandler} />
														</Col>
														<Label for="person_email" md={2} >e-Mail</Label>
														<Col md={4}>
															<Input type="text" name="person_email" id="person_email" onChange={this.onFieldChangeHandler} />
														</Col>
													</FormGroup>
													<FormGroup row>
														<Col sm={{ size: 10, offset: 2 }}>
															<Button onClick={this.onContactSubmitHandler} >Save Contact</Button>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
									</div>
									:
									''
								}
								<FormGroup row>
									<Label for="has_permit" md={2} >Has Permit</Label>
									<Col md={4}>
										<Input type="checkbox" name="has_permit" id="has_permit" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="has_drawings" md={2} >Has Drawing</Label>
									<Col md={4}>
										<Input type="checkbox" name="has_drawings" id="has_drawings" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="has_estimate" md={2} >Has Estimate</Label>
									<Col md={4}>
										<Input type="checkbox" name="has_estimate" id="has_estimate" onChange={this.onFieldChangeHandler} />
									</Col>
								</FormGroup>
								<FormGroup row>
									<Col sm={{ size: 10, offset: 2 }}>
										<Button onClick={this.onSubmitHandler} >Save Lead</Button>
									</Col>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE} />
			</Page>
		);
	}
}
export default FormProject;