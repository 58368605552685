import logo200Image from 'assets/img/logo/logo_200.png';
import renoBuildingImage from 'assets/img/logo/logo-website.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import Base64 from 'Base64';
import {
  MdAccountCircle,
  MdArrowDropDownCircle,
  MdAssignment,
  MdBorderAll,
  MdBrush,
  MdChromeReaderMode,
  MdComment,
  MdDashboard,
  MdExtension,
  MdFileUpload,
  MdGroupWork,
  MdImage,
  MdInsertChart,
  MdKeyboardArrowDown,
  MdNotificationsActive,
  MdPages,
  MdPerson,
  MdRadioButtonChecked,
  MdSend,
  MdStar,
  MdTextFields,
  MdTimeline,
  MdTimer,
  MdViewCarousel,
  MdViewDay,
  MdViewList,
  MdWeb,
  MdWidgets,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Badge
} from 'reactstrap';
import bn from 'utils/bemnames';

import { Cookies } from 'react-cookie';
const cookie =new Cookies();
const __PERSON_ID_ = cookie.get('v-person-id');
const __PERSON_ROLE_ = cookie.get('v-person-role');
const __PERSON_TYPE_ = cookie.get('v-person-type');

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};



const bem = bn.create('sidebar');

const getRestAPI = () => {
  return `${process.env.REACT_APP_REST_URL}`;
};

var navContents = [];

class Sidebar extends React.Component {
  constructor(props){
    super(props);
    var link = require('vortex/config/link.json');

		this.state = {
      isOpen : [],
      links    : link,
      showMenu  : true,
      };
  
      /**
       * Fetching Sidebar items by person access
       */
       let requestOptions = {
        method: 'GET',
        headers: {
          'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
        }
      };
      fetch( getRestAPI() + '/wp-json/reno/v1/rb_forms?role=' + __PERSON_TYPE_ , requestOptions)
      .then(response => {
        return Promise.all([response.json(),response])
      })
      .then(([responseData,response]) => {
        this.setState({forms : responseData});
        responseData.map(item=>{
          navContents.push({ to: '/' + item.form_name, name: item.form_title, exact: false, Icon: MdDashboard });
        })
      })
      .catch(error => {
        console.error('Error:', error);
      })	
      
      
      fetch( getRestAPI() + '/wp-json/reno/v1/rb_link', requestOptions)
      .then(response => {
        return Promise.all([response.json(),response])
      })
      .then(([responseData,response]) => {
        this.setState({links : responseData});
      })
      .catch(error => {
        console.error('Error:', error);
      })		    

      };
  


  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    var link = this.state.links;
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand">
              <img src={renoBuildingImage} width="100%" className="pr-2" alt="" />
              <br />
              <span className="text-white">
                <h6>Design-Build-Reno</h6>
              </span>
            </SourceLink>
          </Navbar>
          {this.state.showMenu?
          <Nav vertical>

          <NavItem className={bem.e('nav-item') + ' d-none'} onClick={this.handleClick('Dashboard')} >
              <BSNavLink className={bem.e('nav-item-collapse')}>
                <div className="d-flex">
                  <MdExtension className={bem.e('nav-item-icon')} />
                  <span className=" align-self-start">Boards</span>
                </div>
                <MdKeyboardArrowDown className={bem.e('nav-item-icon')} style={{padding: 0,transform: this.state.isOpenDashboard? 'rotate(0deg)': 'rotate(-90deg)',transitionDuration: '0.3s',transitionProperty: 'transform',}}
                />
              </BSNavLink>
            </NavItem>
            <Collapse isOpen={this.state.isOpenDashboard}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse>


              {link.filter(i=>i.parent==0 && 
              (
                i.access_type.indexOf(';' + __PERSON_TYPE_ + ';')!=-1 || 
                i.access_role.indexOf(';' + __PERSON_ROLE_ + ';')!=-1 || 
                i.access_id.indexOf(';' + __PERSON_ID_ + ';')!=-1 
              )
              ).map(i=>{
                return (
                  <>
                    <NavItem className={bem.e('nav-item')} onClick={this.handleClick(i.id )}  >
                      <BSNavLink className={bem.e('nav-item-collapse')}>
                        <div className="d-flex">
                          <MdDashboard className={bem.e('nav-item-icon')} />
                          <span className=" align-self-start">{i.caption}</span>
                        </div>
                        <MdKeyboardArrowDown className={bem.e('nav-item-icon')} style={{padding: 0, transform: this.state[`isOpen` + i.id]? 'rotate(0deg)': 'rotate(-90deg)', transitionDuration: '0.3s', transitionProperty: 'transform',}} />
                      </BSNavLink>
                    </NavItem>
                    <Collapse isOpen={this.state['isOpen' + i.id]}>
                      {link.filter(j=>j.parent==i.id  && 
                      (
                        j.access_type.indexOf(';' + __PERSON_TYPE_ + ';')!=-1 || 
                        j.access_role.indexOf(';' + __PERSON_ROLE_ + ';')!=-1 || 
                        j.access_id.indexOf(';' + __PERSON_ID_ + ';')!=-1 
                      )
                      ).map(j=>{
                        return (
                          <NavItem className={bem.e('nav-item')}>
                            <BSNavLink tag={NavLink} className="text-uppercase" to={j.url} activeClassName="active" exact="true" >
                                <MdRadioButtonChecked className={bem.e('nav-item-icon')} />
                                <span><small>{j.caption}</small></span>
                            </BSNavLink>
                        </NavItem>
                        )
                      })}
                    </Collapse>
                  </>
                )
              })}
          </Nav>
          :''}
        </div>
      </aside>
    );
  }
}

export default Sidebar;