import logo200Image from 'assets/img/logo/logo_200.png';
import renoBuildingImage from 'assets/img/logo/logo-website.png';
import sidebarBgImage from 'assets/img/sidebar/sidebar-4.jpg';
import SourceLink from 'components/SourceLink';
import React from 'react';
import Base64 from 'Base64';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Badge
} from 'reactstrap';
import bn from 'utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};



const bem = bn.create('sidebar');

class EmptySidebar extends React.Component {
  constructor(props){
    super(props);

		this.state = {
      };
  

      };
  
  render() {
    return (
      // <aside className={bem.b() + ' cr-sidebar--open'} data-image={sidebarBgImage}>
      <aside className={bem.b() + ' cr-sidebar'} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <SourceLink className="navbar-brand">
              <img src={renoBuildingImage} width="100%" className="pr-2" alt="" />
              <br />
              <span className="text-white">
                <h6>Design-Build-Reno</h6>
              </span>
            </SourceLink>
          </Navbar>
        </div>
      </aside>
    );
  }
}

export default EmptySidebar;