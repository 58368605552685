import Base64 from 'Base64';
import { Cookies } from 'react-cookie';

const cookie =new Cookies();

function _T() {
    let requestOptions = {
        method: 'GET',
        headers: {
          'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
        }
      };
    
      const getRestAPI = () => {
        return `${process.env.REACT_APP_REST_URL}`;
      };
      
      fetch( getRestAPI() + '/wp-json/reno/v1/rb_link', requestOptions)
      .then(response => {
        return Promise.all([response.json(),response])
      })
      .then(([responseData,response]) => {
        return responseData;
      })
      .catch(error => {
        console.error('Error:', error);
      })		    
}

function getAccess (_module){
  var _result = false;
  var person_id = cookie.get('v-person-id');
  var person_role = cookie.get('v-person-role');
  /**
   * By Id
   */
  if(person_id*1 === 66){
    if(_module ==='ViewProjectsOfPerson-NewLeadButton')_result = true;
  }
  /**
   * By Role
   */
  if(person_role*1 === 1){ //ADMIN
    if(_module ==='ViewProjectsOfPerson-NewLeadButton')_result = true;
  }
  if(person_role*1 === 2){ //MANAGER
    if(_module ==='ViewProjectsOfPerson-NewLeadButton')_result = true;
  }
  if(person_role*1 === 3){ //EMPLOYEE
    if(_module ==='ViewProjectsOfPerson-NewLeadButton')_result = true;
  }	
  if(person_role*1 === 4){ //Verifier
  }
  if(person_role*1 === 5){ //Staff
  }
  if(person_role*1 === 6){ //External Staff
  }
  if(person_role*1 === 7){ //TRADE PERSON
  }		
  if(person_role*1 === 8){ //CLIENT
  }
  if(person_role*1 === 9){ //GUEST
  }
  return _result;
}

function getPublicURL () {
  //return `${process.env.REACT_APP_SOURCE_URL}`;
  return 'https://app.renobuilding.ca';
};

export default global.config = {
    link      : _T(),
    access    : (args)=>{return getAccess(args)},
    url       : getPublicURL(),
    officeEmail   : 'info@renobuilding.ca',
    projectEmail  : 'projects@renobuilding.ca',
    copyright : '© 2017-2023 Reno Building Canada Inc.',
    version   : 'ver. 2023.06.29',
};