import React from 'react';
import Base64 from 'Base64';
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

class ComboBox extends React.Component  {
	constructor(props){
		super(props);
		this.state = {
            data : [],
            defaultValue : this.props.default, 
            table : this.props.table, 
            valueField : this.props.valueField, 
            displayField : this.props.displayField, 
            filterField : this.props.filterField, 
            filterValue  : this.props.filterValue,
            order : this.props.orderby
        };
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		fetch('http://renobuilding.ca/wp-json/reno/v1/query?table=' + this.state.table + '&filterfield=' + this.state.filterField + '&filtervalue=' + this.state.filterValue + (this.state.order?'&order=' + this.state.order:'') , requestOptions)
		.then(response => {
		    return Promise.all([response.json(),response])
		})
		.then(([responseData,response]) => {
            this.setState({data : responseData});
			//console.log(responseData);
		})
		.catch(error => {
			console.error('Error:', error);
		})		
    }
    onChnageHandler = (event) =>{
        event.persist();
        //console.log(event.target.value);
        this.props.onChange(event);
    };
    componentWillReceiveProps(nextProps){
        console.log(nextProps);
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		fetch('http://renobuilding.ca/wp-json/reno/v1/query?table=' + nextProps.table + '&filterfield=' + nextProps.filterField + '&filtervalue=' + nextProps.filterValue + (nextProps.order?'&order=' + nextProps.order:'') , requestOptions)
		.then(response => {
		  return Promise.all([response.json(),response])
		})
		.then(([responseData,response]) => {
            this.setState({data : responseData});
			//console.log(responseData);
		})
		.catch(error => {
			console.error('Error:', error);
		})		
    }
    render(){
        const data = this.state.data;
        return(
            <>
            <span>
                <Input type="select" multiple={this.props.multiple? this.props.multiple: false}  size={this.props.size? this.props.size:1} value={this.props.defaultValue} id={this.props.id? this.props.id : this.state.table + '-' + this.state.filterField + '-' + this.state.filterValue } name={this.props.id? this.props.id : this.state.table + '-' + this.state.filterField + '-' + this.state.filterValue } onChange={this.onChnageHandler} >
                    <option value="-1" >-</option>                                
                    {
                        data.map(item=>{
                            return(
                                <>
                                    <option value={item[this.state.valueField]} >{item[this.state.displayField.split(',')[0]]} {item[this.state.displayField.split(',')[1]]} {item[this.state.displayField.split(',')[2]]}</option>                                
                                </>
                            )
                        })
                    }
                </Input>
            </span>
            </>
        );
    }
}

export default ComboBox;