import { STATE_LOGIN, STATE_SIGNUP } from 'vortex/component/AuthForm';
import React from 'react';
import '../styles/reduction.scss';
import '../App.css';

import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import { BrowserRouter, HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import PageSpinner from 'components/PageSpinner';
import componentQueries from 'react-component-queries';
import AuthPage from 'vortex/AuthPage';
import ViewProjectTaskNote from 'vortex/ViewProjectTaskNote';
import { Cookies } from 'react-cookie';
import Base64 from 'Base64';
import DataAdapter from './adapter/DataAdapter';

import ViewTaskOfPerson from 'vortex/ViewTaskOfPerson';
import ViewProjectOfPerson from 'vortex/ViewProjectOfPerson';

const DashBoard = React.lazy(() => import('vortex/dashboard/DashBoard'));
const DashBoardFinancial = React.lazy(() => import('vortex/dashboard/DashBoardFinancial'));

var link = require('vortex/config/link.json');

/*
var page = {};
link.filter(l=>l.module!='').map(l=>{
    page['l' +l.id] = React.lazy(() => import('./' + l.module));
})
*/

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split('/').pop()}`;
  };
  const getRestAPI = () => {
    return `${process.env.REACT_APP_REST_URL}`;
  };
  
const cookie =new Cookies();
  
class App extends React.Component{
    constructor(props){
        super(props);
        this.state = {
          forms : [],
          links    : link
        };
    
        let requestOptions = {
          method: 'GET',
          headers: {
            'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
          }
        };
    
        fetch( getRestAPI() + '/wp-json/reno/v1/rb_forms?role=8' , requestOptions)
        .then(response => {
          return Promise.all([response.json(),response])
        })
        .then(([responseData,response]) => {
          this.setState({forms : responseData});
        })
        .catch(error => {
          console.error('Error:', error);
        })		

        fetch( getRestAPI() + '/wp-json/reno/v1/rb_link', requestOptions)
        .then(response => {
          return Promise.all([response.json(),response])
        })
        .then(([responseData,response]) => {
          this.setState({links : responseData});
        })
        .catch(error => {
          console.error('Error:', error);
        })		    
          
    }
    render(){
      console.log(('v-person-role'))
      console.log(cookie.get('v-person-role'))
      var forms = this.state.forms;
      var _token = this.getQueryString('token');
      console.log(window.location.href.indexOf('ViewProjectTaskNote'));
      if(window.location.href.indexOf('ViewProjectTaskNote')!=-1){
        return(
          <HashRouter basename={getBasename()} >
              <Switch>
                  <LayoutRoute path="/ViewProjectTaskNote/:id/:receiver/:type" layout={EmptyLayout} component={props => (<ViewProjectTaskNote {...props}  />)} />  
              </Switch>
          </HashRouter>
        )

      }else if(cookie.get('v-email') != null){
          return(
          <>
            <HashRouter basename={getBasename()} >
                <Switch>
                    <MainLayout breakpoint={this.props.breakpoint}>
                        <React.Suspense fallback={<PageSpinner />}>
							{/*
                        <Route exact path="/login"   component={React.lazy(() => import('./ViewTaskOfPerson'))} />
                        <Route exact path="/"   component={React.lazy(() => import('./ViewTaskOfPerson'))} />
							*/}
							{/*
                        <Route exact path="/login"   component={React.lazy(() => import('./ViewTaskOfPerson/2,3,4,5,6,7,8,9,11'))} />
                        <Route exact path="/"   component={React.lazy(() => import('./ViewTaskOfPerson/2,3,4,5,6,7,8,9,11'))} />
							*/}
                        <Route exact path="/login"   render={props => (<ViewProjectOfPerson status="2,3,4,5,6,7,8,9,11" /> )} />
                        <Route exact path="/"   render={props => (<ViewProjectOfPerson status="2,3,4,5,6,7,8,9,11" /> )} />
                            {
                              forms.map(form=>{
                                return(
                                  <>
                                    {form.form_type == 0?
                                    <Route exact path={"/" + form.form_name} >
                                      <DashBoard pageTitle={form.form_title} status={form.elements} />
                                    </Route>
                                  :''}
                                  </>
                                )
                              })
                            }

                            {
                              this.state.links.map(l=>{
                                  return(
                                    <>
                                      {l.module?
                                      <Route exact path={l.path }    component={React.lazy(() => import('./' + l.module))} />
                                      :''}
                                    </>
                                  )
                              })
                            }
                        </React.Suspense>
                    </MainLayout>
                    <Redirect to={'/'} />
                </Switch>
            </HashRouter>
          </>
        )
      }else if(_token){
        if(_token.split('-')[0]*1 == _token.split('-')[1]*1){
          var _person_id  = _token.split('-')[0];
            var dataAdapter =new DataAdapter("select * from rb_person where person_id=" + _person_id ).getRecords().then((data)=>{
              console.log(data);
              var role  = data[0].person_role.indexOf(',');
              role  = data[0].person_role.substring(role*1 +1, data[0].person_role.indexOf(',',role+1));
          
    
              cookie.set('v-person-id', data[0].person_id);
              cookie.set('v-email', data[0].email);
              cookie.set('v-tel', data[0].person_tel);
              cookie.set('v-name', data[0].name);
              cookie.set('v-lastname', data[0].lastname);
              cookie.set('v-person-title', data[0].person_title);
              cookie.set('v-person-type', data[0].person_type);
              cookie.set('v-person-role', role);
              cookie.set('v-person-role-name', data[0].rb_type_desc);
              cookie.set('v-person-desc', data[0].person_desc);
              cookie.set('v-person-token', _token);
              //window.location.reload();
          })
      };

        return(
          <HashRouter basename={getBasename()} >
            <Switch>
                <MainLayout breakpoint={this.props.breakpoint}>
                  <Route exact path="/ViewProjectPublic/:id/:task"   component={React.lazy(() => import('./ViewProject'))} />
                </MainLayout>
            </Switch>
        </HashRouter>
        )
      }else if(this.getQueryString('public-token')){
        return(
          <HashRouter basename={getBasename()} >
            <Switch>
                <MainLayout breakpoint={this.props.breakpoint}>
                  <Route exact path="/ViewProjectPublic/:id/:task"   component={React.lazy(() => import('./ViewProject'))} />
                </MainLayout>
            </Switch>
        </HashRouter>
        )
      }else{
        return(
          <HashRouter basename={getBasename()} >
              <Switch>
                  <LayoutRoute exact path="/login" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_LOGIN} />)} />
                  <LayoutRoute exact path="/signup" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_SIGNUP} />)} />
                  <LayoutRoute path="/" layout={EmptyLayout} component={props => (<AuthPage {...props} authState={STATE_LOGIN} />)} />  
              </Switch>
          </HashRouter>
        )
      }
    }
    getQueryString=(param)=>{
      var _result = "";
      var indexOfQuestionMark = window.location.href.indexOf('?');
      if(indexOfQuestionMark == -1)
        return _result;
      var queryString = window.location.href.substring(indexOfQuestionMark);
      var indexOfParam = queryString.indexOf(param + '=');
      var indexOfParamEnd = queryString.indexOf('&', indexOfParam);
      if(indexOfParamEnd==-1)
        _result = queryString.substring(indexOfParam + param.length +1);
      else
        _result = queryString.substring(indexOfParam + param.length +1, indexOfParamEnd);
    
      return _result;
    }
    
}

const query = ({ width }) => {
    if (width < 575) {
      return { breakpoint: 'xs' };
    }
  
    if (576 < width && width < 767) {
      return { breakpoint: 'sm' };
    }
  
    if (768 < width && width < 991) {
      return { breakpoint: 'md' };
    }
  
    if (992 < width && width < 1199) {
      return { breakpoint: 'lg' };
    }
  
    if (width > 1200) {
      return { breakpoint: 'xl' };
    }
  
    return { breakpoint: 'xs' };
};


export default componentQueries(query)(App);
  