// Assets
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconConversation from 'assets/img/icon/conversation.png';
import iconToDo from 'assets/img/icon/todo.png';
// Assets
import iconPhone from 'assets/img/icon/phone-color.png';
import iconEmail from 'assets/img/icon/email-color.png';
import iconT from 'assets/img/icon/letter-t.png';
import iconH from 'assets/img/icon/letter-h.png';
import iconThumbsUp from 'assets/img/icon/thumbs-up.png';
import iconHourGlass from 'assets/img/icon/hour-glass.png';
import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconHightPerformance from 'assets/img/icon/triangle-green.png';
import iconMiddlePerformance from 'assets/img/icon/triangle-yellow.png';
import iconLowPerformance from 'assets/img/icon/triangle-red.png';
import iconDuration from 'assets/img/icon/duration.png';
import iconDeadline from 'assets/img/icon/deadline.png';
import iconLocation from 'assets/img/icon/location.png';
import iconSchedule from 'assets/img/icon/schedule.png';

import React from 'react';
import Base64 from 'Base64';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import VtxGrid from '../../component/VtxGrid';
import DataAdapter from '../../adapter/DataAdapter.js';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
import ComboBox from '../../component/ComboBox.js';
import { Cookies } from 'react-cookie';
import TaskAction from './TaskAction';
import ViewTask from './ViewTask';
import Avatar from '../../../components/Avatar';

// Constants
const cookie = new Cookies();
const _BACKEND_URL_ = "https://node.renobuilding.ca:3001"

class ViewProjectTasks extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		this.state = {projecttasks : [], project : this.props.project, task: this.props.taskId,
			taskModal : false, 
			taskActionModal : false, 
			conversationModal : false, 
			activeTask : -1,
			person_type		:	8,
			person_trade	:	-1,
		};
		var dataAdapter =new DataAdapter("SELECT * FROM rb_projects WHERE prj_id = " + props.project).getRecords()
		.then(data=>{
			this.setState({projects : data[0]})
		})

		this.load(this.props.taskId);
	}
	
	load = (_taskId) =>{
		console.log("SELECT * FROM rb_project_tasks WHERE parent = " + _taskId);
		
		if(_taskId)
		var dataAdapter =new DataAdapter("SELECT * FROM rb_project_tasks WHERE parent = " + _taskId).getRecords()
		.then(data=>{
			this.setState({projecttasks : data})
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ project: nextProps.project , task: nextProps.taskId });
		this.load(nextProps.taskId);
	}	
	openURL = (event) => {
		window.location = '/#/FormTask';
	};

    onCellEdit = (e)=>{
		const __TABLE__		=	"rb_project_tasks";
		const __PRIMARY__	=	"pt_id";
		var _table	        =	__TABLE__;
		var _field          =	e.args.datafield;
		var _value          =	e.args.value;
		var _oldvalue       =	e.args.oldvalue;
        var _primaryfield   =   __PRIMARY__;   
		var _primary        =	e.args.row[__PRIMARY__];
		var sql = "UPDATE " + _table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryfield + " = '" + _primary + "'";
        var dataAdapter =new DataAdapter(sql);
        if(_value != _oldvalue){
            dataAdapter.getRecords().then((_records) => {
                this.addNotification(_field + ' has been changed to ' + _value);
                })    
            console.log(sql);
        }
    }

	onSendSMSHandler = (_text, _receiver) => {
		if (_receiver) {
			let formData = new FormData();

			formData.append('receiver', _receiver);
			formData.append('text', _text);
			const options = {
				method: "POST",
				headers: {
					'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
				},
				body: formData
			};

			fetch('https://node.renobuilding.ca:3001/sms', options)
				.then(response => response.json())
				.then(response => {
					this.addNotification('SMS has been sent to ' + _receiver);
				})
				.catch(err => console.error(err));
				// this.runQuery("INSERT INTO rb_project_task_details (pt_id,prj_id,sender_id, receiver_id, task, task_type, task_description, note, start_date) values (" + __PROJECT_TASK_ID__ + "," + __PROJECT_ID__ + "," + cookie.get("v-person-id") + "," + this.state.receiver_id + ",'SMS', 111,'" + this.state.othernotes + "','', now())","SMS log inserted");
			} else {
			this.addNotification("Receiver have no tel")
		}

	}
	onSendEmailHandler = () => {
		let formData = new FormData();
		formData.append("from", 'info@renobuilding.ca');
		formData.append("bcc", 'projects@renobuilding.ca');
		formData.append("to", this.state.receiver_email);
		formData.append("subject", 'RE : Prj' + this.state.projects.prj_id + ' - ' + this.state.projects.prj_address + ' - ');
		formData.append("text", this.state.othernotes);
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://node.renobuilding.ca:3001/message', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				// console.log(responseData);
				this.addNotification('E-Mail has been sent to ' + this.state.receiver_email);

			})
			.catch(error => {
				console.error('Error:', error);
			})
			// this.runQuery("INSERT INTO rb_project_task_details (pt_id,prj_id,sender_id, receiver_id, task, task_type, task_description, note, start_date) values (" + __PROJECT_TASK_ID__ + "," + __PROJECT_ID__ + "," + cookie.get("v-person-id") + "," + this.state.receiver_id + ",'Email',111,'" + this.state.othernotes + "', '', now())","Call log inserted");


	}

    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: 'success'});
	};	
	onFollowUp= (e) => {
		(new DataAdapter("select * from rb_person where person_id = " + e.target.value)).getRecords().then(data=>{
			this.setState({

				receiver_id			:	data[0].person_id,
				receiver_name		:	data[0].name,
				receiver_lastname	:	data[0].lastname,
				receiver_postal_code:	data[0].postal_code,
				receiver_address	:	data[0].address,
				receiver_city		:	data[0].city,
				receiver_tel		:	data[0].person_tel,
				receiver_email		:	data[0].email,
				receiver_type		:	data[0].person_type,
			 });
		})
	}
	getToken = () => {
		//return Base64.btoa(this.state.projects.client_id) + '-' + this.state.projects.client_id + '-' + Date.now();
		return this.state.receiver_id + '-' + this.state.receiver_id + '-' + Date.now();
	}

	onTemplateIdFieldChnageHandler = (e) => {
		var __template_id = e.target.value;
		this.setState({ templateId: __template_id });

		var dataAdapter = new DataAdapter("select * from rb_templates where template_id = " + __template_id);
		dataAdapter.getRecords().then((data) => {
			if(data[0])
			this.setState({
				othernotes: data[0].task_desc

					.replaceAll('[RECEIVER_NAME]'			, this.state.receiver_name)
					.replaceAll('[RECEIVER_USERNAME]'		, this.state.receiver_name.toLowerCase())
					.replaceAll('[RECEIVER_PASSWORD]'		, this.state.receiver_tel.slice(-4))

					// .replaceAll('[CLIENT_NAME]', this.state.receiver_name)
					// .replaceAll('[CLIENT_PASS]', this.state.receiver_tel.slice(-4))

					.replaceAll('[PROJECT_CODE]'			, this.state.projects.prj_code)
					.replaceAll('[PROJECT_TITLE]'			, this.state.projects.prj_title)
					.replaceAll('[PROJECT_TOKEN]'			, global.config.url + "/#/ViewProject/" + this.state.projects.prj_id + "/" + this.state.activeTask + "?prj_id=" + this.state.projects.prj_id + "&token=" + this.getToken())
					.replaceAll('[PROJECT_LINK]'			, global.config.url + "/#/ViewProject/" + this.state.projects.prj_id + "/" + this.state.activeTask + "?prj_id=" + this.state.projects.prj_id + "&token=" + this.getToken())
					.replaceAll('[PROJECT_ESTIMATE_LINK]'	, global.config.url + "/#/ViewProjectTaskNote/" + this.state.projects.prj_id + "/" + this.state.receiver_id + "/3")
					.replaceAll('[PROJECT_QUOTE_LINK]'		, global.config.url + "/#/ViewProjectTaskNote/" + this.state.projects.prj_id + "/" + this.state.receiver_id + "/6")

					.replaceAll('[USER_NAME]'				, cookie.get('v-name') + ' ' + cookie.get('v-lastname'))
					.replaceAll('[USER_DESC]'				, cookie.get('v-person-desc'))
					.replaceAll('[USER_TITLE]'				, cookie.get('v-person-role-name'))
					.replaceAll('[USER_CELL]'				, cookie.get('v-cell'))
					.replaceAll('[USER_TEL]'				, cookie.get('v-tel'))
					.replaceAll('[USER_EMAIL]'				, cookie.get('v-email'))
					.replaceAll('[USER_ORGANIZATION_TEL]'	, cookie.get('v-tel-organization'))
					.replaceAll('[USER_ORGANIZATION_EMAIL]'	, cookie.get('v-email-organization'))

					.replaceAll('[OFFICE_TEL]'				, "(416) 821-3733")
					.replaceAll('[OFFICE_INFO_EMAIL]'		, "info@renobuilding.ca")
					.replaceAll('[OFFICE_PROJECT_EMAIL]'	, "projects@renobuilding.ca")
			});
		})

	}

	assignNewTask = () =>{
		if (this.state.taskModal) {
			var expected_end_date = (new Date()).toISOString().split('T')[0];
			var query	=	"INSERT INTO rb_project_tasks (prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,task_description,expected_end_date,estimate_hours) VALUES (" + this.state.projects.prj_id + "," + cookie.get("v-person-id") + "," + this.state.receiver_id + ",now(), 8," + this.state.task_type + ",'" + this.state.projects.prj_title + "','" + this.state.othernotes + "','" + expected_end_date + "',1);";
			let formData = new FormData();
			formData.append("query", query);
			let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
			fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
				.then(response => {
					return Promise.all([response.json(), response])
				})
				.then(([responseData, response]) => {
					this.load();
					this.addNotification("New task inserted", "success");
	
				})
				.catch(error => {
					console.error('Error:', error);
				})

			this.setState({ taskModal: false });
		
		} else {
			this.setState({ taskModal: true });
		}
	}
	assignNewTaskForm = () => {
		return (
			<>
				<Modal isOpen={this.state.taskModal} >
					<ModalHeader>
						<>
							<b>Receiver Tel : </b> {this.state.receiver_tel} &nbsp;&nbsp;&nbsp;&nbsp;
							<b>Receiver e-Mail : </b> {this.state.receiver_email} &nbsp;&nbsp;&nbsp;&nbsp; 
							<Button style={{float:'right'}} color="primary" onClick={(e) => { this.setState({ taskModal: false }) }} >X</Button>{' '}
						</>
					</ModalHeader>
					<ModalBody>
						<>
							<Label>Assign to : </Label><br/>
							Person Type : 
							<ComboBox onChange={(e)=>{this.setState({person_type   : e.target.value}); this.setState({person_trade  :   -1});}} table="rb_types" filterField="rb_type" filterValue={'person_type'} valueField="rb_type_no" displayField="rb_type_desc" defaultValue={this.state.person_type} />
							{this.state.person_type*1 == 2 ? 
								<>
									Trade : 
									<ComboBox onChange={(e)=>{this.setState({person_trade : e.target.value})}} table="rb_types" filterField="rb_type" filterValue={'person_trade'} valueField="rb_type_no" displayField="rb_type_desc" defaultValue={this.state.person_trade} order="rb_type_desc" />
								</>
							:''}
							Person:
							<ComboBox onChange={(event) => { this.onFollowUp(event) }} table="rb_person" filterField="person_type" filterValue={ this.state.person_type + "' /*AND user_expire_date>=now()*/) OR (person_id='" + (this.state.projects? this.state.projects.client_id : '-1') } valueField="person_id" displayField="name,lastname" />{'	'}
							<Label>Task Type</Label>
							<ComboBox onChange={(event) => { this.setState({task_type: event.target.value}) }} table="rb_types" filterField="rb_type" filterValue={"task_type' and rb_type_type_id='20"} valueField="rb_type_no" displayField="rb_type_desc" />{'	'}
							<br />
							<Button color="secondary" onClick={(e) => { this.assignNewTask(e) }} >Assign</Button>{' '}
						</>
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</>
		);
	}

	openConversationForm = (e) =>{
		if (this.state.conversationModal) {
			this.setState({ conversationModal: false });
		} else {
			this.setState({
				conversationModal : true, 
				activeTask 		:	e.target.attributes[0].nodeValue,
				receiver_id		:	e.target.attributes[1].nodeValue,
				receiver_name	:	e.target.attributes[2].nodeValue,
				receiver_tel	:	e.target.attributes[3].nodeValue,
				receiver_email	:	e.target.attributes[4].nodeValue,
			});
		}
	}
	openTaskActionForm = (e) =>{
		if(this.state.taskActionModal){
			this.setState({ taskActionModal: false });
		}else{
			this.setState({ taskActionModal: true });
		}
	}
	conversationForm = () => {
		return (
			<>
				<Modal isOpen={this.state.conversationModal} >
					<ModalHeader>
						<>
							<b>Receiver Tel : </b> {this.state.receiver_tel} &nbsp;&nbsp;&nbsp;&nbsp;
							<b>Receiver e-Mail : </b> {this.state.receiver_email} &nbsp;&nbsp;&nbsp;&nbsp; 
							<Button style={{float:'right'}} color="primary" onClick={(e) => { this.setState({ conversationModal: false }) }} >X</Button>{' '}
						</>
					</ModalHeader>
					<ModalBody>
						<>
							<Label>Message Tempates</Label>
							{/* <ComboBox onChange={this.onTemplateIdFieldChnageHandler} table="rb_templates" valueField="template_id" displayField="template_id,template_name" filterField="template_type" filterValue={this.state.task_type + "' AND person_type='" + this.state.receiver_type } order="template_name" /> */}
							<ComboBox onChange={this.onTemplateIdFieldChnageHandler} table="rb_templates" valueField="template_id" displayField="template_id,template_name" filterField="resources" filterValue="0" order="template_id" />
							<Label>Message Text</Label>
							<textarea style={{ width: '100%', height: '150px' }} onChange={(e) => { this.setState({ othernotes: e.target.value }); }} value={this.state.othernotes} />
							<Button color="secondary" onClick={(e) => { this.onSendSMSHandler(this.state.othernotes, this.state.receiver_tel); }} >Send SMS</Button>{' '}
							<Button color="secondary" onClick={(e) => { this.onSendEmailHandler(e); }} >Send E-mail</Button>{' '}
							<button type="button" className="btn btn-outline-secondary btn-sm" onClick={async (event) => { await navigator.clipboard.writeText(this.state.othernotes) }} >COPY MESSAGE</button>{'	'}
						</>
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</>
		);
	}
	taskActionForm = () => {
		return (
			<>
				<Modal isOpen={this.state.taskActionModal} >
					<ModalHeader>
						<>
							<Button style={{float:'right'}} color="primary" onClick={(e) => { this.setState({ taskActionModal: false }) }} >X</Button>{' '}
						</>
					</ModalHeader>
					<ModalBody>
						<>
							<ViewTask projectId={this.state.project} taskId={this.state.activeTask} />
							{/* <TaskAction projectId={this.state.project} taskId={this.state.activeTask} /> */}
						</>
					</ModalBody>
					<ModalFooter>
					</ModalFooter>
				</Modal>
			</>
		);
	}

	removeTask = (pt_id) =>{
		var query	=	"DELETE FROM rb_project_tasks WHERE pt_id = " + pt_id;
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = { method: 'POST', headers: { 'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO') }, body: formData };
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/', requestOptions)
			.then(response => {
				return Promise.all([response.json(), response])
			})
			.then(([responseData, response]) => {
				this.load();
				this.addNotification("Task #" + pt_id + " removed", "success");

			})
			.catch(error => {
				console.error('Error:', error);
			})

	}

	render(){
		return (
		<div>
			<TaskList projects={this.state.projecttasks} project={this.state.project} onActionHanlder={(e)=>{this.openConversationForm(e)}} />
			<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
			{this.assignNewTaskForm()}
			{this.conversationForm()}
			{this.taskActionForm()}
		</div>
		);
	}
}


export const TaskList = (props) =>{
	var today = new Date();
	var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());
	var task_priority_color	=	'';
	var card_priority_color	=	'';
	var _performance;
	var _performance_color;
	var date0, date1, date2;
	
	const getSource	=(_id)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + _id + '.jpg');
		}catch(err){}
		return _src;
	}
	{/*
	if(props.projects)
		props.projects.map(p=>{
			return(<li>{p.receiver_id}</li>);
		});
	*/}
	var _project	=	props.project;
	return (
		<div className="p-2 mb-1 rounded" >
		{props.projects.filter(project=>project.prj_id==_project).map(project=>{				
				_performance	= Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours))>100? 100: Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours));
				
				date0 	=new Date(project.start_date);
				date1 	=new Date();
				date2	=new Date(project.expected_end_date);

				const diffTime0 = (date1 - date0);
				const diffDays0 = Math.ceil(diffTime0 / (1000 * 60 * 60 * 24)); 

				const diffTime = (date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

				const diffTime1 = (date2 - date0);
				const diffDays1 = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
			
				const min	=	0;
				const slider=	1;
				const max	= 	(diffDays<0? diffDays0+slider: diffDays1+slider);
				const left	=	(diffDays<0? diffDays1: diffDays0);
				const right	=	(diffDays<0? max+1-left: diffDays);

				const Pmax		=	100;
				const Pleft		=	left*100/max;
				const Pslider	=	1;
				const Pright	=	right*100/max;
		return(
		<>
			<div className={project.task_status_id*1 ==10? 'border rounded p-1 mt-1 bg-success' : 'border rounded p-1 mt-1'} >
				<Avatar size={32} className='float-left' src={getSource(project.receiver_id)} title={project.receiver_id} />
				<img src={iconDuration} width={16} title="Days You have Worked On" />
				{'	'}<small>{diffDays0}</small>
				<img src={iconDeadline} width={16} title="Dys To Deadline"  />
				{'	'}<small>{diffDays}</small>{'	'}

				<img style={{cursor: 'pointer'}} src={iconSchedule} width={16} title="Estimate Hours" />
				<small>{project.estimate_hours? project.estimate_hours : '-'}</small>{'	'}
				
				<img src={iconHourGlass} width={13} title="Real Minutes" />
				<small>{project.real_hours?project.real_hours*1 : 0} ′</small>{'	'}

				<img src={iconThumbsUp} width={16} title="Percent Done" />
				<small>{project.percent_done?project.percent_done*1 : 0}</small>{'	'}
				
				{!_performance || _performance<60?
					<img src={iconLowPerformance} width={13} title="Performance" />
				:''}
				{_performance>=60 && _performance<80?
					<img src={iconMiddlePerformance} width={13} title="Performance" />
				:''}
				{_performance>=80?
					<img src={iconHightPerformance} width={13} title="Performance" />
				:''}
				<small>{_performance? _performance: 0}</small>

				<br/>
				<Badge
					style={{cursor:'pointer'}}
					className="float-right" 
					data-task-id={project.pt_id?project.pt_id:0} 
					data-project-id={project.prj_id? project.prj_id : 0}
					onClick={(e)=>props.onActionHanlder(e)}
				> 
				#{project.pt_id}</Badge>&nbsp;&nbsp;
				<small><b>{project.task_type}</b></small>&nbsp;&nbsp;
				<small className="float-right mr-1" ><b>{project.task_status}</b></small>
				
			</div>
		</>
		)})}
		</div>
		);
}

export default ViewProjectTasks;