import { Content } from 'components/Layout';
import React from 'react';
import EmptySidebar from './EmptySidebar';

const EmptyLayout = ({ children, ...restProps }) => (
  // <main className="cr-app bg-light" {...restProps}>
  //   <EmptySidebar />
  //   <Content fluid>{children}</Content>
  // </main>
    <>
      {children}
    </>
);

export default EmptyLayout;
