import React from 'react';
import Base64 from 'Base64';

import Page from 'components/Page';
import {Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { Cookies } from 'react-cookie';

import ViewProjectTaskDetails from './ViewProjectTaskDetails.js';
import DataAdapter from './adapter/DataAdapter.js';

import ComboBox from './component/ComboBox.js';

const cookie =new Cookies();

var __PROJECT_ID__;
var __PROJECT_TASK_ID__;

class ViewProjectTask extends React.Component {
	notificationSystem = React.createRef();
	constructor(props){
		super(props);

		if(props.match){
			__PROJECT_ID__ 		= this.props.match.params.id;
			__PROJECT_TASK_ID__ = this.props.match.params.task;
		  }else{
			__PROJECT_ID__ = this.props.id;
			__PROJECT_TASK_ID__ = this.props.task;
		  }
	

		this.state = {
			actionModal	:	false,
			quickEditTaskModal	:	false,
			task				:	[],
			task_id				:	'',
			task_project		:	'',
			task_task			:	'',
			task_type			:	0,
			task_description	:	'',
			task_percent_done	:	'',
			task_estimate_hours	:	'',
			task_real_hours		:	'',
			mynotes				:	'',
			clockIn		:	false,
			date		:	this.getDate(),
			time		:	this.getTime(),
			action_percent	:0,
			action_note	:	'',
		};

		var _SQL_	=	"select * from rb_project_tasks WHERE pt_id=" + __PROJECT_TASK_ID__;
		// var _SQL_	=	"SELECT pt.pt_id, pt.prj_id, prj.prj_title AS prj_desc, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task, pt.task_status, pt.sender_id, pt.receiver_id, pt.priority, pt.expected_start_date, pt.start_date, pt.expected_end_date, pt.end_date, pt.task_description, pt.estimate_hours, pt.real_hours, pt.percent_done FROM `rb_project_tasks` pt"	+
		// 			"	INNER JOIN 	rb_projects prj ON prj.prj_id=pt.prj_id"	+
		// 			"	INNER JOIN 	rb_types ty ON ty.rb_type_no=pt.task_type and ty.rb_type='task_type'"	+
		// " WHERE pt.pt_id=" + __PROJECT_TASK_ID__;
		var dataAdapter =new DataAdapter(_SQL_);
			dataAdapter.getRecords()
						.then(data=>{
							this.setState({
								task				:	data[0],
								task_id				:	data[0].pt_id,
								task_project		:	data[0].prj_id,
								task_task			:	data[0].task,
								task_status			:	data[0].task_status,
								task_type			:	data[0].task_type,
								task_description	:	data[0].task_description,
								task_percent_done	:	data[0].percent_done,
								task_estimate_hours	:	data[0].estimate_hours,
								task_real_hours		:	data[0].real_hours,
								mynotes				:	data[0].note,
							});
						})
						.catch(error=>{
							console.error('Error:', error);
						})
	}
	getDate=()=>{
		var today = new Date();
		
		var date = today.toISOString().split('T')[0];
		return date;			 
	}
	getTime=()=>{
		var today = new Date();
		var time = today.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }); //today.toISOString().split('T')[1].slice(0,5);
		return time;			 
	}

	runQuery=(query, message, type)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				this.addNotification(message, type);					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}

	onClockInHandler=(e)=>{
		e.persist();
		//var _UTC_ = new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[1].slice(0, 5);

		if(this.state.actionModal){
			var _UTC_ = new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[1].slice(0, 5);
			var	sql ="INSERT INTO rb_project_task_details ( pt_id, prj_id, task_status, task_type, sender_id, receiver_id, Clock_in ) values " +
					"(" + __PROJECT_TASK_ID__ + ", " + __PROJECT_ID__ + ", 9 , 98 , " + cookie.get('v-person-id') + "," + cookie.get('v-person-id') + ", '" + _UTC_ + "')" ;
			this.runQuery( sql, "Clocked In Successfully", "success");
			this.setState({clockIn	:	true});
			this.onTimerStart();
			this.setState({actionModal: false});
		}else{
			this.setState({actionModal: true, date: this.getDate(), time: this.getTime()});
		}
	}
	onClockOutHandler=(e)=>{
		e.persist();
		//var _UTC_ = new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[1].slice(0, 5);
		if(this.state.actionModal){
			var _UTC_ = new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.state.date + ' ' + this.state.time).toUTCString()).toISOString().split('T')[1].slice(0, 5);
			var sql	=	"UPDATE rb_project_task_details SET percent_done='" + this.state.action_percent + "',note='" + this.state.action_note + "', task_status = 10, Clock_out = '" + _UTC_ + "', real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "') " +
			"WHERE task_status=9 AND pt_id=" + __PROJECT_TASK_ID__ + " AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ";";
			this.runQuery(sql, "Clock Out Successfully", "error");
			
			this.setState({clockIn	:	false});
			this.onCalculateHandler();
			this.setState({actionModal: false});
		}else{
			this.setState({actionModal: true, date: this.getDate(), time: this.getTime()});
		}
	}
	onTimerStart=()=>{
		this.setState({time	:	this.getTime()})
		// setInterval(()=>{
		// 	this.setState({time	:	this.getTime()})
		// },1000)
	}
	onCalculateHandler=()=>{
		var sql	=	"UPDATE rb_project_tasks SET real_hours = (" +
					"SELECT sum(TIMESTAMPDIFF(MINUTE, Clock_in, Clock_out)) FROM `rb_project_task_details`" +
					"WHERE pt_id = " + __PROJECT_TASK_ID__ + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + "), percent_done = (select sum(percent_done) from rb_project_task_details WHERE pt_id = " + __PROJECT_TASK_ID__ + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ") where pt_id = " + __PROJECT_TASK_ID__  + ";";
		//console.log(sql)
		this.runQuery(sql, "Real hours calculated", "success");
		var dataAdapter =new DataAdapter("SELECT * FROM rb_project_tasks WHERE pt_id=" + __PROJECT_TASK_ID__);
			dataAdapter.getRecords()
						.then(data=>{
							this.setState({task : data[0]});
						})
						.catch(error=>{
							console.error('Error:', error);
						})

	}
	quickEditeTask	=	(e)	=>{
		e.persist();
		if(this.state.quickEditTaskModal){
			this.setState({
				quickEditTaskModal	:	false
			});
		}else{
			this.setState({
				quickEditTaskModal	:	true
			});
			var dataAdapter =new DataAdapter("SELECT * FROM rb_project_tasks WHERE pt_id = " + e.target.attributes[0].value)
								.getRecords()
								.then((data)=>{
									this.setState({
										task_id					:	data[0].pt_id,
										task_project			:	data[0].prj_id,
										task_task				:	data[0].task,
										task_type				:	data[0].task_type,
										task_description		:	data[0].task_description,
										task_percent_done		:	data[0].percent_done,
										task_estimate_hours		:	data[0].estimate_hours,
										task_real_hours			:	data[0].real_hours,
		
									});
						
								})
		}
	}
	onTypeChnageHandler=(e)=>{
		this.setState({task_type	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_type = '" + e.target.value + "' WHERE pt_id=" + __PROJECT_TASK_ID__, 'Task Type Updated!', 'success');
	}
	onStatusChnageHandler=(e)=>{
		this.setState({task_status	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_status = '" + e.target.value + "' WHERE pt_id=" + __PROJECT_TASK_ID__, 'Task Status Updated!', 'success');
	}
	onProjectChnageHandler=(e)=>{
		this.setState({task_project	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET prj_id = '" + e.target.value + "' WHERE pt_id=" + __PROJECT_TASK_ID__, 'Task Project Updated!', 'success');
	}
	onNoteChange = (e) => {
		e.persist();
		this.runQuery("UPDATE rb_project_tasks SET note='" + this.state.mynotes + "' WHERE pt_id ="  + __PROJECT_TASK_ID__ , "Your Notes has been saved.", "success");
	}

	render(){
		return (
			<Page className="DashboardPage" title={' '} >					
				<Row>
					<Col md={12} sm={12} xs={12} className="mb-3">
						<Card>
							<ListGroup flush>
								<ListGroupItem>
									<button disabled={this.state.clockIn & false} type="button" className="btn btn-success btn-sm" onClick={(event)=>{this.onClockInHandler(event)}} >Clock In</button>{'    '}
									<button disabled={!this.state.clockIn & false} type="button" className="btn btn-danger btn-sm" onClick={(event)=>{this.onClockOutHandler(event)}} >Clock Out</button>
									{'	'}
									{this.state.date} - {this.state.time}
								</ListGroupItem>
							</ListGroup>
							<CardBody>							
								<CardTitle><b>#{this.state.task_id} - {this.state.task_task}</b>{'	'}
									<Badge 
										color='primary'
										data-task-id={this.state.task_id}
										onClick={async (event) => { await navigator.clipboard.writeText('#' + this.state.task_id + ' - ' + this.state.task_task); this.addNotification("Copied!", "info");									}} 
										href="javascript:(0);" className="mr-1">
										Copy
									</Badge>{'	'}
									<Badge 
										data-task-id={this.state.task_id}
										onClick={(e)=>{this.quickEditeTask(e)}}
										href="javascript:(0);" className="mr-1">
										Edit
									</Badge>

								</CardTitle>
								<CardText>
									<div  dangerouslySetInnerHTML={{ __html: this.state.task_description }} />
								</CardText>
							</CardBody>
							<ListGroup flush>
								<ListGroupItem><b>Percent Done :</b> {this.state.task_percent_done}</ListGroupItem>
								<ListGroupItem><b>Project :</b> 
									<ComboBox onChange={this.onProjectChnageHandler} table="rb_projects" valueField="prj_id" displayField="prj_title" filterField="prj_department" filterValue="17' OR 'prj_department'<>'12" defaultValue={this.state.task_project} order="prj_code" />
								</ListGroupItem>
								<ListGroupItem><b>Task Type :</b> 
									<ComboBox onChange={this.onTypeChnageHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="task_type" defaultValue={this.state.task_type} order="rb_type_no" />
								</ListGroupItem>
								<ListGroupItem><b>Task Status :</b> 
									<ComboBox onChange={this.onStatusChnageHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="task_status" defaultValue={this.state.task_status} order="rb_type_no" />
								</ListGroupItem>
								<ListGroupItem><b>Estimate hours :</b> {this.state.task_estimate_hours} ({this.state.task_estimate_hours*60} <i>Minutes</i>)</ListGroupItem>
								<ListGroupItem>
										<b>Real Minutes :</b> {this.state.task_real_hours} 
										{'      '}
										<button type="button" className="btn btn-outline-danger btn-sm" onClick={(e)=>{this.onCalculateHandler()}} >Calculate from Transaction</button>
								</ListGroupItem>
							</ListGroup>
						</Card>
						<Card>
							<CardBody>
								<CardTitle><b>My Notes</b></CardTitle>
							</CardBody>
							<ListGroup flush>
								<ListGroupItem>
									<textarea style={{width:'100%', height: '200'}} onChange={(e)=>{this.setState({mynotes:e.target.value});}} onBlur={this.onNoteChange} value={this.state.mynotes} />	
								</ListGroupItem>
							</ListGroup>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col  md={12} sm={12} xs={12} className="mb-3">
						<Card>
							<CardBody>
								<CardTitle><b>ACTIONS</b></CardTitle>
								<CardText>
									<ViewProjectTaskDetails project={__PROJECT_TASK_ID__} />
								</CardText>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.actionForm()}
				{this.quickEditTasktForm()}
			</Page>
		);
	}

	actionForm = () => {
		return (
			<>
				<Modal isOpen={this.state.actionModal} >
					<ModalHeader>
						<Button color="primary" onClick={(e)=>{this.setState({actionModal:false})}} >X</Button>{' '}
					</ModalHeader>
					<ModalBody>
						<Label>Date</Label>
						<Input type="date" onChange={(v)=>this.setState({date:v.target.value})} value={this.state.date} />
						<Label>Time</Label>
						<Input type="time" onChange={(v)=>this.setState({time : v.target.value})} value={this.state.time} />
						<Label>Progress (%)</Label>
						<Input type="text" onChange={(v)=>this.setState({action_percent:v.target.value})} value={this.state.action_percent} />
						<Label>Note</Label>
						<Input type="textarea" onChange={(v)=>this.setState({action_note:v.target.value})} value={this.state.action_note}></Input>
					</ModalBody>
					<ModalFooter>
						<Button color="success" onClick={(e)=>{this.onClockInHandler(e)}} >Clock In</Button>{' '}
						<Button color="danger" onClick={(e)=>{this.onClockOutHandler(e)}} >Clock Out</Button>{' '}
					</ModalFooter>
				</Modal>
			</>
		);
	}
	quickEditTasktForm = () => {
		return (
			<>
				<Modal isOpen={this.state.quickEditTaskModal} >
					<ModalHeader>
					</ModalHeader>
					<ModalBody>
						<b>Task : </b>
						<Input id="task" type="text" onChange={(e)=>{this.setState({task_task			:	e.target.value});}} onBlur={this.onTaskChangeHandler}  value={this.state.task_task} />
						<b>Description : </b>
						<Input id="task_description" type="textarea" onChange={(e)=>{this.setState({task_description	:	e.target.value});}} onBlur={this.onTaskChangeHandler}  value={this.state.task_description} />
						<b>Percent Done : </b>
						<Input id="percent_done" type="text" onChange={(e)=>{this.setState({task_percent_done	:	e.target.value});}} onBlur={this.onTaskChangeHandler}  value={this.state.task_percent_done} />
						<b>Estimate Hours : </b>
						<Input id="estimate_hours" type="text" onChange={(e)=>{this.setState({task_estimate_hours	:	e.target.value});}} onBlur={this.onTaskChangeHandler}  value={this.state.task_estimate_hours} />
						<b>Real Minutes : </b>
						<Input id="real_hours" type="text" onChange={(e)=>{this.setState({task_real_hours	:	e.target.value});}} onBlur={this.onTaskChangeHandler}  value={this.state.task_real_hours} />
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={(e)=>{this.setState({quickEditTaskModal:false})}} >Close</Button>{' '}
						<Button color="secondary" onClick={(e)=>{this.setState({quickEditTaskModal:false})}} >Save</Button>{' '}
					</ModalFooter>
				</Modal>
			</>
		);
	}

    addNotification (_MESSAGE_, _TYPE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};	
	onTaskChangeHandler	=	(e)=>{
		e.persist();
		this.runQuery("UPDATE rb_project_tasks SET " + e.target.id  + " = '" + e.target.value + "' WHERE pt_id =" + this.state.task_id, e.target.id + ' Updated!', "success");
	}
	
}


export default ViewProjectTask;