import React from "react";
import { Badge } from "reactstrap";
import { Cookies } from 'react-cookie';
import Base64 from 'Base64';
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardTitle from "reactstrap/lib/CardTitle";
import CardText from "reactstrap/lib/CardText";

const ViewDrive = React.lazy(() => import('vortex/ViewDrive/ViewDrive'));

const cookie =new Cookies();

const _BACKEND_URL_ = "https://node.renobuilding.ca:3001"
//const _BACKEND_URL_ = "http://localhost:3001"

class DriveGridUploader extends React.Component {
    constructor (props){
        super(props);
        window.DriveGrid = this;
        this.state = {
			selectedFolderId : 0,
            parent : this.props.parent,
            projects : [],
            projectimages : [],
            indexedImages : [],
			selectedIndex : 0,
			selectedImage : '',
			image_id : 0,
			image_thumbnail : '',
			selectedImages : [],
        };
        this.fetchData(this.props.parent);
    }
    componentDidUpdate(prevProps, prevState, snapshot){
		console.log(this.props.parent)
		console.log(prevProps.parent)
        if (this.props.parent != prevProps.parent) {
            this.fetchData(this.props.parent);
        }
    }
    fetchData(parent){
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		
		//fetch(_BACKEND_URL_ + '/search?fullText=&mimeType=image/jpeg&parent=' + parent, requestOptions)
		fetch(_BACKEND_URL_ + '/file?parent=' + parent, requestOptions)
		.then(response => {
			return Promise.all([response.json(),response])
		})
		.then(([responseData,response]) => {
			console.log(responseData);
			this.setState({projectimages : responseData});
		})
		.catch(error => {
			console.error('Error:', error);
		})		

        fetch('https://renobuilding.ca/wp-json/reno/v1/rb_images', requestOptions)
            .then(response => {
                return Promise.all([response.json(),response])
            })
            .then(([responseData,response]) => {
                console.log(responseData);
                this.setState({indexedImages : responseData});
            })
            .catch(error => {
                console.error('Error:', error);
            })		

    }
	checkImage(e){
		e.persist();
		
		const selectedIndex = e.target.attributes[1].nodeValue;
		var _selectedImages = this.state.selectedImages;
		_selectedImages.push(this.state.projectimages[selectedIndex].thumbnailLink);
		console.log(e)
		this.setState({
			selectedIndex : selectedIndex,
			selectedImage : this.state.projectimages[selectedIndex].thumbnailLink,
			image_id : this.state.projectimages[selectedIndex].id,
			image_thumbnail : this.state.projectimages[selectedIndex].thumbnailLink,
			selectedImages : _selectedImages,
		});

		//this.setState({visible : true, assignFormVisible : false});
	};

	showDriveContent = (e) =>{
		this.setState({selectedFolderId : e.target.attributes[0].nodeValue});
		this.props.onChildrenChange(e.target.attributes[0].nodeValue);
	}

    render(){
		const projectimages = this.state.projectimages.filter(i=>!i.trashed);
        return(
            <>
						{projectimages.map(i=>{
							return (
								<>
								{i.mimeType === 'application/vnd.google-apps.folder' && (i.name === 'Images' || i.name === 'Drawings') ?
									<>
										{/*<a href={"/#/ViewDrive/" + i.id } className="btn btn-secondary btn-sm  mx-1" role="button" aria-disabled="true">OPEN</a>*/}
										<a data-id={i.id} href="javascript:void(0);" onClick={(e)=>{this.showDriveContent(e)}} className="btn btn-secondary btn-sm  mx-1" role="button" aria-disabled="true">Upload to {i.name}</a>
									</>
									:''}
								</>
							)
						})
						}
            </>
        );
    }
}


export default DriveGridUploader;