// Assets
import loadingGif from 'assets/img/line-loading.gif';
import iconPhone from 'assets/img/icons8-phone-50.png';
import iconEdit from 'assets/img/icon/edit.png';
import 'styles/reduction.scss';
import 'App.css';

// Core modules
import React, {useState, useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';

// Framework modules
import Page from 'components/Page';
import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

import { Cookies } from 'react-cookie';
import ComboBox from '../../component/ComboBox.js';
import ViewProject from '../Projects/ViewProject.js';
import Task from './Task.js';
import ViewTaskDetail from '../TaskDetails/ViewTaskDetail.js';
import TaskDetail from '../TaskDetails/TaskDetail.js';
import DateTime from '../../lib/DateTime.js';
import DataAdapter from '../../adapter/DataAdapter.js';
import Project from '../Project.js';
import TaskAction from './TaskAction.js';
import ViewProjectTasks from './ViewProjectTasks.js';

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	

function ViewTask(props){
	const [isProgressing, setIsProgressing] = useState(false);
	const [isOpen, setIsOpen] = useState(true);
	const [task, setTask] = useState(0);
	const [note, setNote] = useState('');
	const [reload, setReload] = useState(false);
	const [client, setClient] = useState([]);
	const [receiver, setReceiver] = useState([]);
	

	useEffect(()=>{
		/*
		var task =new Task();
		task.viewById(task.pt_id).then(data=>{
			if(data[0])
				setTask(data[0])
		})
		var project =new Project();
		project.getClientId(task.prj_id).then(data=>{
			console.log(data);
		})
		*/
		setTask(props.project);
		
	},[]);

	const onChangeTaskStatus = async (_id, _status) =>{
		setIsProgressing(true);
		const _task	=new Task();
		const response = await _task.changeStatus(_id, _status);
		if(response){
			setIsProgressing(false);
			addNotification("Task status changed", "info");
			props.callback(response);
		};

	}

    return(
        <>
			<Modal  isOpen={isOpen} >
				<ModalHeader>
					<Row>
						<Col>
							<span className=''></span>
						</Col>
						<Col>						
							<button type="button" className="btn btn-primary btn-sm float-right ml-1 mr-1" onClick={(event)=>{setIsOpen(false)}} >X</button>{' '}
						</Col>
					</Row>
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col md={12} sm={12} xs={12} >
							<Card>
								<ListGroup>
									<ListGroupItem>
										<Accordion defaultActiveKey="1" flush>
											<Accordion.Item eventKey="0">
												<Accordion.Header >PROJECT #{task.prj_id} DETAILS </Accordion.Header>
												<Accordion.Body>
													<ViewProject projectId={task.prj_id} />
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="1">
												<Accordion.Header >TASK #{task.pt_id} DETAILS</Accordion.Header>
												<Accordion.Body>
													<Row>
														<Col xl={11} lg={11} md={11}>
															<Card>
																<CardBody>
																	<ListGroup flush>
																		<ListGroupItem>
																			<Label md="2"><b>Task Type</b></Label>
																			{task?task.task_type:<img src={loadingGif} height="32"  />}
																		</ListGroupItem>
																		<ListGroupItem>
																			<Label md="2"><b>Task Status</b></Label>
																			{task?
																			<>
																				{task.task_status}
																				&nbsp;&nbsp;
																				<Label className="mr-1" >
																					<b>
																					Set task status to :
																					</b>
																				</Label>
																				<Button color="primary" data-id={task.pt_id} data-status="To Win" data-status-id={7} onClick={(e)=>{onChangeTaskStatus(task.pt_id, 7)}} >To Win</Button>{' '}
																				<Button color="primary" data-id={task.pt_id} data-status="To Start" data-status-id={8} onClick={(e)=>{onChangeTaskStatus(task.pt_id, 8)}} >To Start</Button>{' '}
																				<Button color="primary" data-id={task.pt_id} data-status="To Progress" data-status-id={9} onClick={(e)=>{onChangeTaskStatus(task.pt_id, 9)}} >In Progress</Button>{' '}
																				<Button color="primary" data-id={task.pt_id} data-status="To Done" data-status-id={10} onClick={(e)=>{onChangeTaskStatus(task.pt_id, 10)}} >Done</Button>{' '}
																				<Button color="primary" data-id={task.pt_id} data-status="Assign To Close" data-status-id={17} onClick={(e)=>{onChangeTaskStatus(task.pt_id, 17)}} >Close the Job</Button>{' '}
																			</>
																			:<img src={loadingGif} height="32"  />}
																		</ListGroupItem>
																		<ListGroupItem>
																			<Label md="2"><b>Title</b></Label>
																			{task? '#' + task.pt_id + '-' + task.task:<img src={loadingGif} height="32"  />}
																		</ListGroupItem>
																		<ListGroupItem>
																			<Label md="2"><b>Description</b></Label>
																			{task?task.task_description:<img src={loadingGif} height="32"  />}
																		</ListGroupItem>
																		<ListGroupItem>
																			<Label md="2"><b>My reports</b></Label>
																			<>
																				<Input type="textarea" onChange={(e)=>setNote(e.target.value)} ></Input> <br />
																				<Button color="secondary" className="btn"  ><small>Save my report to project notes</small></Button>{' '}
																			</>
																		</ListGroupItem>
																	</ListGroup>
																</CardBody>
															</Card>
														</Col>
														<Col xl={1} lg={1} md={1}>
															<img style={{cursor:'pointer'}} src={iconEdit} width={16} onClick={(e)=>{
																var _task	=new Task();
																	_task.viewTaskUpdateForm(task);
																}
															} />
														</Col>
													</Row>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="2">
												<Accordion.Header >
												{/*<small>{task? '#' + task.pt_id + ' - ' + task.task:<img src={loadingGif} height="32"  />}</small> */}
													TASK #{task.pt_id} ACTIONS
												</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={6} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																	{/*<Label>Task Action</Label><br />*/}
																		<TaskAction task={task} projectId={task.prj_id} taskId={task.pt_id} onChangeTaskStatus={(e)=>{}}  />
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
														<Col  md={6} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		<ViewProjectTasks project={task.prj_id} taskId={2589} />
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
											<Accordion.Item eventKey="3">
												<Accordion.Header >
													ACTIONS HISTORY {task.pt_id}
												</Accordion.Header>
												<Accordion.Body>
													<FormGroup row >
														<Col  md={12} sm={12} xs={12}>
															<Card>
																<ListGroup flush>
																	<ListGroupItem>
																		{task?
																			<ViewTaskDetail taskId={task.pt_id} reload={reload} />
																		:<img src={loadingGif} height="32"  />}
																	</ListGroupItem>
																</ListGroup>
															</Card>
														</Col>
													</FormGroup>
												</Accordion.Body>
											</Accordion.Item>
										</Accordion>
									</ListGroupItem>
								</ListGroup>
							</Card>
						</Col>
					</Row>
				</ModalBody>
			</Modal>			
				<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
        </>
    );

}
export default ViewTask;