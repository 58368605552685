// Assets
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';

import React, {useState, useEffect} from "react";

import {Progress,Input, Modal, ModalBody, ModalFooter, ModalHeader, Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, ListGroup, ListGroupItem, CardLink,CardText,CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, CardFooter } from 'reactstrap';
import TaskDetail from './TaskDetail';

const __TASK_TYPE_NOTE__    =   37;
const __TASK_TYPE_CLOCK__   =   98;
const __TASK_TYPE_SMS__     =   116;
const __TASK_TYPE_CALL__     =   117;
const __TASK_TYPE_EMAIL__   =   118;

function TaskDetailCard(props){
    return(
        <>
            {props.taskDetail.task_type_id*1 == __TASK_TYPE_NOTE__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="info" title="" >{props.taskDetail.sender_id}</Badge>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="info" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail.task_type_id*1 == __TASK_TYPE_SMS__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="success" title="" >{props.taskDetail.sender_id}</Badge>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="success" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail.task_type_id*1 == __TASK_TYPE_CALL__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="light" title="" >{props.taskDetail.sender_id}</Badge>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="light" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}

            {props.taskDetail.task_type_id*1 == __TASK_TYPE_EMAIL__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="warning" title="" >{props.taskDetail.sender_id}</Badge>
                                        <small>{props.taskDetail.start_date}</small> <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="warning" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.note}</small> 
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
            {props.taskDetail.task_type_id*1 == __TASK_TYPE_CLOCK__  ?
            <div>
                <Card>
                    <CardBody>
                        <CardTitle className="text-capitalize">
                                <>
                                    <div className='float-right' >
                                        <Badge className='float-right' color="dark" title="" >{props.taskDetail.sender_id}</Badge>{' '}
                                        <small>{props.taskDetail.start_date}</small>{'      '} <br />
                                        <a href='javascript:void(0)' onClick={(e)=>{
                                            e.persist();
                                            var taskDetail =new TaskDetail();
                                            if(window.confirm("Are you sure?"))
                                                taskDetail.deleteById(e.target.attributes["data-id"].nodeValue).then(data=>{
                                                    props.reload();
                                                });
                                            }} ><img data-id={props.taskDetail.ptd_id} src={iconRubbishBin} width={16} /></a>
                                    </div>
									<Badge color="dark" title="" >{props.taskDetail.task_type}</Badge><br />
                                    <small>{props.taskDetail.Clock_in} - {props.taskDetail.Clock_out? props.taskDetail.Clock_out: ' ... '}</small> <br /> 
                                    <small>{props.taskDetail.note}</small> 
                                </>
                        </CardTitle>
                    </CardBody>
                </Card>
                <br />
            </div>
           :''}
        </>
    )
}
export default TaskDetailCard;