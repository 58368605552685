// Assets
import iconPhone from 'assets/img/icon/phone-color.png';
import iconEmail from 'assets/img/icon/email-color.png';
import iconT from 'assets/img/icon/letter-t.png';
import iconH from 'assets/img/icon/letter-h.png';
import iconThumbsUp from 'assets/img/icon/thumbs-up.png';
import iconHourGlass from 'assets/img/icon/hour-glass.png';
import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconHightPerformance from 'assets/img/icon/triangle-green.png';
import iconMiddlePerformance from 'assets/img/icon/triangle-yellow.png';
import iconLowPerformance from 'assets/img/icon/triangle-red.png';
import iconDuration from 'assets/img/icon/duration.png';
import iconDeadline from 'assets/img/icon/deadline.png';
import iconLocation from 'assets/img/icon/location.png';
import iconSchedule from 'assets/img/icon/schedule.png';
import iconDrawing from 'assets/img/icon/design.png';
import iconPermit from 'assets/img/icon/permit.png';
import iconAdd from 'assets/img/icon/add.png';

import React, { Component, useState, useEffect} from 'react';
import Base64	from 'Base64';
import { gapi } from 'gapi-script';
import Switch	from "react-switch";

import Task		from "./model/Tasks/Task";
import Page		from 'components/Page';
import ComboBox from './component/ComboBox';
import {Progress,Input, Modal, ModalBody, ModalFooter, ModalHeader, Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, ListGroup, ListGroupItem, CardLink,CardText,CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, CardFooter } from 'reactstrap';
import SearchInput from 'components/SearchInput';
import { Cookies } from 'react-cookie';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import DataAdapter from './adapter/DataAdapter';
import Avatar from '../components/Avatar';
import ViewTask from './model/Tasks/ViewTask';


const cookie =new Cookies();
const colorMap = {};
const selectedColors = {};


var __PERSON__, __PERSON_ROLE__, __STATUS__,__COLUMNS__;
var __STATUS__		=	"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25";

var isNumber = function isNumber(value){
   return typeof value === 'number' && isFinite(value) && value != 'null';
}

var numberWithCommas = (x) => {
    return (x?x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'');
}

function allowDrop(e) {
	e.preventDefault();
}
const generateColor = (alfa) => {
  let randomColorString = "#";
  const arrayOfColorFunctions = "0123456789abcdef";
  for (let x = 0; x < 6; x++) {
    let index = Math.floor(Math.random() * 16);
    let value = arrayOfColorFunctions[index];

    randomColorString += value;
  }
  return randomColorString + alfa;
};
const newColorFind = (id, alfa) => {
  // If already generated and assigned, return
  if (colorMap[id]) return colorMap[id];

  // Generate new random color
  let newColor;

  do {
    newColor = generateColor(alfa);
  } while(selectedColors[newColor]);

  // Found a new random, unassigned color
  colorMap[id] = newColor;
  selectedColors[newColor] = true;

  // Return next new color
  return newColor;
}

class ViewProjectsOfPerson extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		__PERSON__ = cookie.get("v-person-id");
		__PERSON_ROLE__ = cookie.get("v-person-role");
        if(props.match){
            __STATUS__ = this.props.match.params.status;
        }else{
            __STATUS__ = this.props.status;
        }
		__STATUS__ = (__STATUS__==null?"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25": __STATUS__);

		this.state = {
			projects			: [], 
			dataset				: [], 
			columns				: __STATUS__.split(','), 
			person				: __PERSON__,  
			status				: __STATUS__, 
			actionModal			: false, 
			loadingStatusForm	: false, 
			statusModal			: false, 
			project_id			:0, 
			project				: [], 
			task_id				:0, 
			task				:[], 
			hasDrawings			:false,
			hasPermit			:false,
			search				:'',
			};
		this.loadData(__STATUS__, __PERSON__);


	}

	loadData = (_status, _person) => {
		//var query = "SELECT tyyy.rb_type_desc as category_desc, prj.prj_category_1 as category, prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, ty.rb_type_desc AS prj_status, prj_status as prj_status_id, prj_budget, prj_budget_highest, prj_budget_lowest, prj_posted_date, refered_by_person_id, refered_by_prj_id, pr.* FROM rb_projects prj LEFT JOIN rb_types ty ON ty.rb_type_no = prj.prj_status AND ty.rb_type = 'prj_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_person pr ON pr.person_id = prj.client_id LEFT JOIN rb_types tyyy ON (tyyy.rb_type_no = prj.prj_category_1 AND tyyy.rb_type = 'person_trade') ORDER BY ty.rb_type_no, prj.prj_posted_date desc;";
		//var query = "SELECT pt.pt_id,pt.sender_id, pt.receiver_id, p.name AS `receiver` , pt.expected_end_date, pt.start_date, pt.end_date, pt.task_status AS `task_status_id`, tyyyyy.rb_type_desc as `task_status`, tyyyy.rb_type_desc AS `task_type`, pt.percent_done, pt.real_hours, pt.estimate_hours ,tyyy.rb_type_desc as category_desc, prj.prj_category_1 as category, prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, ty.rb_type_desc AS prj_status, prj_status as prj_status_id, prj_budget, prj_budget_highest, prj_budget_lowest, prj_posted_date, refered_by_person_id, refered_by_prj_id, year(prj_posted_date) as prj_year, pr.* FROM rb_projects prj LEFT JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id LEFT JOIN rb_types ty ON ty.rb_type_no = prj.prj_status AND ty.rb_type = 'prj_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_person pr ON pr.person_id = prj.client_id LEFT JOIN rb_types tyyy ON (tyyy.rb_type_no = prj.prj_category_1 AND tyyy.rb_type = 'person_trade') LEFT JOIN rb_types tyyyy ON (tyyyy.rb_type_no = pt.task_type AND tyyyy.rb_type = 'task_type') LEFT JOIN rb_types tyyyyy ON (tyyyyy.rb_type_no = pt.task_status AND tyyyyy.rb_type = 'task_status') LEFT JOIN rb_person p ON p.person_id = pt.receiver_id ORDER BY ty.rb_type_no, prj.prj_posted_date desc, pt.pt_id;";
		var query = "SELECT pt.pt_id, pt.task, task_description, pt.sender_id, pt.receiver_id, p.name AS `receiver` , pt.expected_end_date, pt.start_date, pt.end_date, pt.task_status AS `task_status_id`, tyyyyy.rb_type_desc as `task_status`, pt.task_type AS `task_type_id`, tyyyy.rb_type_desc AS `task_type`, pt.percent_done, pt.real_hours, pt.estimate_hours ,tyyy.rb_type_desc as category_desc, prj.prj_category_1 as category, prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, ty.rb_type_desc AS prj_status, prj_status as prj_status_id, prj_budget, prj_budget_highest, prj_budget_lowest, prj_posted_date, refered_by_person_id, refered_by_prj_id, year(prj_posted_date) as prj_year, prj.has_drawings, prj.has_permit, pr.* FROM rb_projects prj LEFT JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id LEFT JOIN rb_types ty ON ty.rb_type_no = prj.prj_status AND ty.rb_type = 'prj_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_person pr ON pr.person_id = prj.client_id LEFT JOIN rb_types tyyy ON (tyyy.rb_type_no = prj.prj_category_1 AND tyyy.rb_type = 'person_trade') LEFT JOIN rb_types tyyyy ON (tyyyy.rb_type_no = pt.task_type AND tyyyy.rb_type = 'task_type') LEFT JOIN rb_types tyyyyy ON (tyyyyy.rb_type_no = pt.task_status AND tyyyyy.rb_type = 'task_status') LEFT JOIN rb_person p ON p.person_id = pt.receiver_id WHERE prj.prj_department=12 AND " + (__PERSON_ROLE__*1==0 || __PERSON_ROLE__*1==1 || __PERSON_ROLE__*1==10? "true":"pt.receiver_id=" + __PERSON__) + " ORDER BY ty.rb_type_no, prj.prj_posted_date desc, pt.pt_id;";
		var dataAdapter =new DataAdapter(query);
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({projects : response, dataset : response});
			})

		dataAdapter =new DataAdapter("SELECT * FROM rb_types WHERE rb_type ='prj_status';");
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({prj_status_array : response});
			})

	}

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.status != prevProps.status) {
			this.setState({person : prevProps.person,  status : prevProps.status});
			this.loadData(prevProps.status, prevProps.person);
		}
    }
	
	onClearFilter		=	(e) =>{
		e.persist();
		this.setState({projects	:	this.state.dataset})

	}
	onFilterChange 		=	(e) =>{
		e.persist();
		var projects = this.state.dataset;
		var valueField	=	e.target.attributes[0].value;
		this.setState({projects	:	projects.filter(p=>p[valueField]==e.target.value || e.target.value==-1)})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]==e.target.value || e.target.value==-1)})
	}
	onFromDateChange	=	(e) =>{
		e.persist();
		var projects = this.state.projects;
		var valueField	=	e.target.attributes[0].value;
		this.setState({projects	:	projects.filter(p=>p["start_date"]>=e.target.value || p["end_date"]>=e.target.value )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]>=e.target.value )})
	}
	onToDateChange		=	(e) =>{
		e.persist();
		var projects = this.state.projects;
		var valueField	=	e.target.attributes[0].value;
		this.setState({projects	:	projects.filter(p=>p["start_date"]<=e.target.value || p["end_date"]<=e.target.value  )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]<=e.target.value )})
	}
	onHasDrawings		=	( ) =>{
		
		var projects = this.state.dataset;		
		if(!this.state.hasDrawings)
			this.setState({projects	:	projects.filter(p=>p["has_drawings"])});
		else
			this.setState({projects	:	projects});
		
		this.setState({hasDrawings : !this.state.hasDrawings});
	}
	onHasPermit			=	( ) =>{
		
		var projects = this.state.dataset;		
		if(!this.state.hasPermit)
			this.setState({projects	:	projects.filter(p=>p["has_permit"])});
		else
			this.setState({projects	:	projects});
		
		this.setState({hasPermit : !this.state.hasPermit});
	}
	
	render(){
		var projects = this.state.projects;
		return (
			<Page className="DashboardPage" title="Projects Board" >
				<Row>
					<Col lg={12} md={12} sm={12} xs={12} >
						<SearchInput onChange={(e)=>{
							e.persist();
							var projects = this.state.dataset;
							var valueField	=	e.target.value;
							
							console.log(valueField);

							this.setState({projects	:	projects.filter(p=>p["prj_id"].includes(valueField) || p["prj_title"].includes(valueField) || p["name"].includes(valueField) || e.target.value=='')});
							}} />
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter filter={this.state.columns} text="Year" displayField="prj_year" valueField="prj_year" projects={this.state.dataset} onFilterChange={this.onFilterChange} />&nbsp;
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter filter={this.state.columns} text="Department" displayField="department" valueField="prj_department" projects={this.state.dataset} onFilterChange={this.onFilterChange} />&nbsp;
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter filter={this.state.columns} text="Category" displayField="category_desc" valueField="category" projects={this.state.dataset} onFilterChange={this.onFilterChange} />&nbsp;
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter filter={this.state.columns} text="Person" displayField="receiver" valueField="receiver_id" projects={this.state.dataset} onFilterChange={this.onFilterChange} />&nbsp;
					</Col>
					{/*
					<Col lg={12} md={12} sm={12} xs={12} >
							<span>Has Drawings  </span>
							<Switch height={20} width={64} onChange={()=>{this.onHasDrawings();}} checked={this.state.hasDrawings} />
							<span>Has Permit  </span>
							<Switch height={20} width={64} onChange={()=>{this.onHasPermit();}} checked={this.state.hasPermit} />
					</Col>
					*/}
					{/* <Col lg={6} md={6} sm={6} xs={12} >
						<Input text="start_date" type={'date'} onChange={this.onFromDateChange} />
					</Col>
					<Col lg={6} md={6} sm={6} xs={12} >
						<Input text="end_date" type={'date'} onChange={this.onToDateChange} />
					</Col> */}
					<Col lg={12} md={12} sm={12} xs={12} >
						<Grid callback={this.callback} columns={this.state.columns} projects={this.state.projects} onActionHanlder={(e)=>{this.onActionHanlder(e)}} onStatusHanlder={(e)=>{this.onStatusHanlder(e)}} drag={(e)=>{this.drag(e)}} drop={(e)=>{this.drop(e)}}  />
					</Col>
				</Row>	
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.statusForm()}
			</Page>
				);
	}

    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
		  message: _MESSAGE_,
		  level: 'success'
		});
	};	

	onStatusHanlder				=	(e)=>{
		e.persist();
		if(this.state.statusModal){
			this.setState({statusModal: false});
		}else{
			this.setState({
				statusModal				:	true,
				loadingStatusForm		:	true,
				prj_id				:	e.target.attributes[0].value,
				prj_status			:	e.target.attributes[1].value,
				prj_department		:	e.target.attributes[2].value,
			});
		}
	}
	onActionHanlder				=	(e)=>{
		e.persist();
		
		if(this.state.actionModal){
			this.setState({actionModal: false});
		}else{
			this.setState({
				task				:	this.state.projects.filter(p=>p.pt_id*1 == e.target.attributes["data-task-id"].nodeValue*1)[0], 
				actionModal			:	true,
				task_id				:	e.target.attributes["data-task-id"].nodeValue,
				project_id			:	e.target.attributes["data-project-id"].nodeValue,
				});
		}
	}
	onProjectStatusChnageHandler=	(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.prj_id == this.state.prj_id);
		_projects[_index].prj_status_id =	e.target.value;
		_projects[_index].prj_status =	this.state.prj_status_array.find(s=>s.rb_type_no*1 == _projects[_index].prj_status_id*1).rb_type_desc;

		this.runQuery("UPDATE rb_projects SET prj_status = '" + e.target.value + "' WHERE prj_id=" + this.state.prj_id, 'Project Status Updated!');
		
		this.setState({ projects : _projects});

	}
	onPreStatus					=	(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.prj_id == this.state.prj_id);
		_projects[_index].prj_status_id =	_projects[_index].prj_status_id*1 -1;
		_projects[_index].prj_status =	this.state.prj_status_array.find(s=>s.rb_type_no*1 == _projects[_index].prj_status_id*1).rb_type_desc;

		var _project	= this.state.project;
		_project.prj_status	= _projects[_index].prj_status_id*1;

		this.runQuery("UPDATE rb_projects SET prj_status = prj_status-1 WHERE prj_id=" + this.state.prj_id, "Project Status changed");

		this.setState({ projects : _projects, project : _project});
	}
	onNxtStatus					=	(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.prj_id == this.state.prj_id);
		_projects[_index].prj_status_id =	_projects[_index].prj_status_id*1 +1;
		_projects[_index].prj_status =	this.state.prj_status_array.find(s=>s.rb_type_no*1 == _projects[_index].prj_status_id*1).rb_type_desc;

		var _project	= this.state.project;
		_project.prj_status	= _projects[_index].prj_status_id*1;

		this.runQuery("UPDATE rb_projects SET prj_status = prj_status+1 WHERE prj_id=" + this.state.prj_id, "Project Status changed");

		this.setState({ projects : _projects, project : _project});
	}
	onHoldHandler				=	(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.prj_id == this.state.prj_id);
		_projects[_index].prj_status_id =	12;
		_projects[_index].prj_status =	this.state.prj_status_array.find(s=>s.rb_type_no*1 == _projects[_index].prj_status_id*1).rb_type_desc;
		
		var _project	= this.state.project;
		_project.prj_status	= _projects[_index].prj_status_id*1;

		this.runQuery("UPDATE rb_project_tasks SET task_status = 12 WHERE prj_id=" + this.state.prj_id, "Task has been holded");
		this.runQuery("UPDATE rb_projects SET prj_status = 12 WHERE prj_id=" + this.state.prj_id, "Project has been holded");

		this.setState({ projects : _projects, project : _project, modal : false});
	}
	onCloseHandler				=	(e)=>{
		e.persist();
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.prj_id == this.state.prj_id);
		_projects[_index].prj_status_id =	_projects[_index].prj_status_id*1 + 13;
		_projects[_index].prj_status =	this.state.prj_status_array.find(s=>s.rb_type_no*1 == _projects[_index].prj_status_id*1).rb_type_desc;
		
		var _project	= this.state.project;
		_project.prj_status	= _projects[_index].prj_status_id*1;

		this.runQuery("UPDATE rb_project_tasks SET task_status = 10 WHERE prj_id=" + this.state.prj_id, "Task has been closed");
		this.runQuery("UPDATE rb_projects SET prj_status = prj_status + 13 WHERE prj_id=" + this.state.prj_id, "Project has been closed");

		this.setState({ projects : _projects, project : _project, modal : false});
	}
	onChangeTaskStatus			=	(e)=>{
		e.persist();
		var task =new Task();
		task.changeStatus(e.target.attributes["data-id"].nodeValue, e.target.attributes["data-status-id"].nodeValue);
		//props.onChangeTaskStatus(e);
		this.addNotification("Task status changed", "info");
	}

	statusForm					= 	() => {
		if(this.state.loadingStatusForm){
			var query = "SELECT * FROM rb_projects WHERE prj_id=" + this.state.prj_id;
			var dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({project : response[0], loadingStatusForm : false});
				})	
		}
		
		return (
			<>
				<Modal isOpen={this.state.statusModal} >
					<ModalHeader>
						<b>#{this.state.prj_id}</b> <small>{this.state.project.prj_title}</small>
					</ModalHeader>
					<ModalBody>
						<b>Status : </b>
						<ComboBox defaultValue={this.state.project.prj_status}  onChange={this.onProjectStatusChnageHandler}  table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="prj_status" order="rb_type_no" />
					</ModalBody>
					<ModalBody>
						<button id={'pre'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onPreStatus(event) }} >Pre Status</button>{'	'}
						<button id={'nxt'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onNxtStatus(event) }} >Next Status</button>{'	'}
						<button type="button" className="btn btn-outline-info btn-sm" onClick={(event) => { this.onHoldHandler(event) }} >Hold/Stop the Job</button>{'	'}
						<button type="button" className="btn btn-outline-danger btn-sm" onClick={(event) => { this.onCloseHandler(event) }} >Close the Job</button>{'	'}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={(e)=>{this.setState({statusModal:false})}} >Close</Button>{' '}
					</ModalFooter>
				</Modal>
			</>
		);
	}
	runQuery					=	(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}
	drag						=	(e) => {
		e.persist();
		if(this.state.projects){
			e.dataTransfer.setData("text", e.target.id);
		}
	}
	drop						=	(e) => {
		e.persist();
		if(e.target.attributes['status']){
			var _prj_id = e.dataTransfer.getData("text");
			var _projects	= this.state.projects;
			var _index	= _projects.findIndex(p=>p.prj_id == _prj_id);
			_projects[_index].prj_status = e.target.attributes['status'].nodeValue;
			_projects[_index].prj_status_id = e.target.attributes['status-id'].nodeValue;
			this.setState({ projects : _projects});
			this.runQuery("UPDATE rb_projects SET prj_status = " + e.target.attributes['status-id'].nodeValue + " WHERE prj_id=" + _prj_id, "Project status has been updated");
		}
	  }
	callback					=   (obj)	=>{
		console.log(obj);
		
			var _projects	= this.state.projects;
			var _index	= _projects.findIndex(p=>p.pt_id == obj[0].pt_id);
			_projects[_index].task_type = obj[0].task_type_desc;
			_projects[_index].task_status_id = obj[0].task_status;
			_projects[_index].task_status = obj[0].task_status_desc;
			this.setState({ projects : _projects});
			
	}
}

export const Filter		= (props) =>{
	console.log(props.filter)
	var projects = props.projects;
	var tempValue	=	[];
	var displayField	=	props.displayField;
	var valueField		=	props.valueField;
	if(!projects)
		return null;
	return(
		<>
			<select id={props.valueField} onChange={(e)=>props.onFilterChange(e)} value={props.defaultValue} className="w-100" >
				<option value="-1" style={{color:'gray'}} > {props.text}</option>
				{projects.map(project=>{
					return(
						<>
							{ tempValue.findIndex((v)=> v==project[displayField]) == -1 && props.filter.findIndex(i=>i==project["prj_status_id"]*1) !=-1?
								<>
									<option value={project[valueField]} >{project[displayField]}</option>
									{tempValue.push(project[displayField]) }
								</>
							:''}
						</>
					)
				})}
			</select>
		</>
	);
}

export const LeadLabel	= (props) =>{
	var UnitProject	=	[];
	props.projects.map(project=>{
		if(UnitProject.findIndex(i=>i.prj_id*1 == project.prj_id*1) == -1){
			UnitProject.push(project);
		}
	});
	
	var _filtered_project	=	UnitProject.filter(p=>p.prj_status_id==props.status && isNumber(p.prj_budget*1));

	var _budget			=	(_filtered_project?_filtered_project.reduce((a, b) => {
		b	=	(isNumber(b.prj_budget*1) ? b.prj_budget*1 :0);
		return a+b;
	},0) :0);
	
	return <div className="myHeader border-top rounded-top p-1" style={{width:'300px', backgroundColor:'lightgray',color:'black'}}>
				{'  '}
				<Badge color="dark" pill className="mr-1">
					{UnitProject? UnitProject.filter(p=>p.prj_status_id==props.status).length :''}
				</Badge>
				{'  '}
				{props.text}
				{'  '}
				<small className="mr-1 float-right">
					<b>${numberWithCommas(_budget)}</b>
				</small>
				<small className="mr-1 float-right">
					${numberWithCommas(Math.round(_budget*0.2))} /
				</small>
			</div>
			
}

export const Lead		= (props) =>{
	var projects 		= props.projects;
	var _status = props.status;
	var task_priority_color	=	'light';
	
	var UnitProject	=	[];
	if(!projects)
		return null;
	
	return(
		<>
			{projects.map(project=>{
				if(UnitProject.findIndex(i=>i*1 == project.prj_id*1) == -1){
					UnitProject.push(project.prj_id);
					return(
					<>
					{project.prj_status_id == _status?
						<>
							<div id={project.prj_id? project.prj_id: 0} draggable="false" onDragStart={(event)=>props.drag(event)} >
								<Card>
									<CardBody style={{backgroundColor: newColorFind(project.category,20)}}>
										<CardTitle className="text-capitalize">
											<b title="Project Type" >{project.category_desc}</b>
											<br/>
											<>
												<span><a style={{color: 'black'}} target={'_blank'} href={"/#/ViewProject/" + project.prj_id } >{project.prj_title}</a></span>
												<span className="float-right" ><small><b>${ numberWithCommas(project.prj_budget)}</b></small></span>
											</>
											<br/>
											<span><a target={'_blank'} href={'https://maps.google.com?q=' + project.prj_address + ',' + project.prj_city }><img src={iconLocation} height='16' className="mr-1" /></a></span>
											<small>{project.prj_address}, {project.prj_city}, {project.prj_postal_code}</small><br />
											<small><i>{project.prj_posted_date}</i></small>
											{project.has_drawings?
												<img className="float-right mr-1" src={iconDrawing} height='16' title="Has Drawing" />
											:''}
											{project.has_permit?
												<img className="float-right mr-1" src={iconPermit} height='16' title="Has Permit" />
											:''}
										</CardTitle>
										<TaskList callback={props.callback} projects={projects} project={project.prj_id} onActionHanlder={(e)=>{props.onActionHanlder(e)}} />

									</CardBody>
									<CardFooter style={{backgroundColor: newColorFind(project.category,80)}} >
										{project.name}{' '}{project.lastname}{'	'}
										{project.person_tel?
											<img role="button" src={iconPhone} height='16' title={project.person_tel} onClick={()=>{window.location='tel:' + project.person_tel}} />
										:''}
										{'	'}
										{project.email?
											<img src={iconEmail} height='16' title={project.email} onClick={()=>{window.location='mailto:' + project.email}}/>
										:''}
										{'	'}
											{/*
										{project.refered_by_person_id*1==5?
											<span>| <a target={'_blank'} href={'https://trustedpros.ca/members_contractor_search_for_lead_results_view.asp?job_id=' + (project.refered_by_prj_id?project.refered_by_prj_id.replace('HCA',''):'') }><img src={iconT} height='16' /></a></span>
										:''}{'	'}
										{project.refered_by_person_id*1==16?
											<span>| <img src={iconH} height='16' /></span>
										:''}{'	'}
											*/}
										<span className="float-right">
												<b>{project.prj_id}</b>
										</span>
										<small className="float-right mr-1" >
											<i 
											data-prj-id={project.prj_id? project.prj_id: 0} 
											data-prj-status={project.prj_status? project.prj_status: 0} 
											data-prj-department={project.prj_department? project.prj_department: 0} 									
											style={{cursor:'pointer'}} color="dark" onClick={(e)=>props.onStatusHanlder(e)} >
												{project.prj_status}
											</i>
										</small>
										{/*
											<h4 style={{display:'inline'}} ><Badge 
													data-prj-id={project.prj_id? project.prj_id: 0} 
													data-prj-status={project.prj_status? project.prj_status: 0} 
													data-prj-department={project.prj_department? project.prj_department: 0} 
													onClick={(e)=>props.onActionHanlder(e)}
													href="javascript:(0);" color="dark" className="float-right mr-1">
													{project.prj_id}
											</Badge></h4>
										*/}
									</CardFooter>
								</Card>
							</div>
							<br />
						</>					
					:''}
					</>
					)				
				}
			})}
		</>
	)	
}

export const TaskList	= (props) =>{
	var today = new Date();
	var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());
	var task_priority_color	=	'';
	var card_priority_color	=	'';
	var _performance;
	var _performance_color;
	var date0, date1, date2;
	
	const getSource	=(_id)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + _id + '.jpg');
		}catch(err){}
		return _src;
	}
	{/*
	if(props.projects)
		props.projects.map(p=>{
			return(<li>{p.receiver_id}</li>);
		});
	*/}
	var _project	=	props.project;
	return (
		<div className="p-2 mb-1 rounded" >
		{props.projects.filter(project=>project.prj_id==_project).map(project=>{				
				_performance	= Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours))>100? 100: Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours));
				
				date0 	=new Date(project.start_date);
				date1 	=new Date();
				date2	=new Date(project.expected_end_date);

				const diffTime0 = (date1 - date0);
				const diffDays0 = Math.ceil(diffTime0 / (1000 * 60 * 60 * 24)); 

				const diffTime = (date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

				const diffTime1 = (date2 - date0);
				const diffDays1 = Math.ceil(diffTime1 / (1000 * 60 * 60 * 24));
			
				const min	=	0;
				const slider=	1;
				const max	= 	(diffDays<0? diffDays0+slider: diffDays1+slider);
				const left	=	(diffDays<0? diffDays1: diffDays0);
				const right	=	(diffDays<0? max+1-left: diffDays);

				const Pmax		=	100;
				const Pleft		=	left*100/max;
				const Pslider	=	1;
				const Pright	=	right*100/max;
				var		color	=	(project.task_status_id*1==7? 'light': (project.task_status_id*1==8? 'warning': (project.task_status_id*1==9? 'info': (project.task_status_id*1==10? 'success': (project.task_status_id*1==11? 'dark': 'light')))))
		return(
		<>
			<div className={project.task_status_id*1 ==10? 'border rounded p-1 mt-1 bg-success' : 'border rounded p-1 mt-1'} >
				<Avatar size={32} className='float-left' src={getSource(project.receiver_id)} title={project.receiver} />
				<img src={iconDuration} width={16} title="Days You have Worked On" />
				{'	'}<small>{diffDays0}</small>
				<img src={iconDeadline} width={16} title="Dys To Deadline"  />
				{'	'}<small>{diffDays}</small>{'	'}

				<img style={{cursor: 'pointer'}} src={iconSchedule} width={16} title="Estimate Hours" />
				<small>{project.estimate_hours? project.estimate_hours : '-'}</small>{'	'}
				
				<img src={iconHourGlass} width={13} title="Real Minutes" />
				<small>{project.real_hours?project.real_hours*1 : 0} ′</small>{'	'}

				<img src={iconThumbsUp} width={16} title="Percent Done" />
				<small>{project.percent_done?project.percent_done*1 : 0}</small>{'	'}
				
				{!_performance || _performance<60?
					<img src={iconLowPerformance} width={13} title="Performance" />
				:''}
				{_performance>=60 && _performance<80?
					<img src={iconMiddlePerformance} width={13} title="Performance" />
				:''}
				{_performance>=80?
					<img src={iconHightPerformance} width={13} title="Performance" />
				:''}
				<small>{_performance? _performance: 0}</small>
				
				<br/>
				<Badge
					color={color}
					style={{cursor:'pointer'}}
					className="float-right" 
					data-task-id={project.pt_id?project.pt_id:0}
					data-project-id={project.prj_id? project.prj_id : 0}
					onClick={(e)=>{
						var _task	=new Task();
						_task.ViewTask(project).then(response=>{
							props.callback(response);							
						});
					}}
				> 
					<small>{project.task_status}</small>&nbsp;&nbsp;
						{project.pt_id}
				</Badge>&nbsp;&nbsp;
				<small
					color={color}
					style={{cursor:'pointer'}}
					data-task-id={project.pt_id?project.pt_id:0} 
					onClick={(e)=>{
						var _task	=new Task();
						_task.viewTaskUpdateForm(project).then(response => {
							props.callback(response);
						})
					}}
				>{project.task_type}</small>&nbsp;&nbsp;
				  <Progress multi className="d-none">
					<Progress bar value={Pleft} max={Pmax} color="warning" >
						{left}
					</Progress>
					<Progress bar value={Pslider} max={Pmax} color="dark" ></Progress>
					<Progress bar value={Pright} max={Pmax} color={diffDays<0 ? "danger": "light"} >
						{right}
					</Progress>
				  </Progress>								
			</div>
		</>
		)})}
			<div className={'border rounded p-1 mt-1'} >
				<img src={iconAdd} width={16} title="Assign To" 
					style={{cursor:'pointer'}}
					data-project-id={_project} 
					onClick={(e)=>{
						var _task	=new Task();
						_task.assignTask(_project).then(response => {
							props.callback(response);
						})
					}}
				/>
			</div>
		</div>
		);
}

export const Grid		= (props) =>{
	var projects	=	props.projects;
	projects = projects.sort((a,b) => a.prj_status_id - b.prj_status_id)
	var tempValue	=	[]; 
	var _style		=	{ float: 'left', width : '250px'};
	return (
		<>
			<div className='Container Flipped'>
				<div className='Content' >
					<table className='card' >
						<tr>
						{projects.map(project=>{
							return(
								<>
									{ tempValue.findIndex((v)=> v==project["prj_status"]) == -1 ?
											<td className="border-right" status-id={project["prj_status_id"]} status={project["prj_status"]} onDrop={(event)=>props.drop(event)} onDragOver={(event)=>allowDrop(event)} style={props.columns.findIndex(i=>i==project["prj_status_id"]*1) ==-1?{display:'none'}:{}}  >
												<LeadLabel text={project["prj_status"]} projects={projects} status={project["prj_status_id"]} /><br />
												<Lead callback={props.callback} projects={projects} status={project["prj_status_id"]} onActionHanlder={(e)=>{props.onActionHanlder(e)}} onStatusHanlder={(e)=>{props.onStatusHanlder(e)}} drag={(e)=>{props.drag(e)}}   />
												{tempValue.push(project["prj_status"]) }
											</td>
									:''}
								</>
							)
						})}

						</tr>
					</table>
				</div>
			</div>
		</>		
	)
}

export default ViewProjectsOfPerson;

