import React,{Suspense} from "react";
import ReactDOM from 'react-dom';
import DataAdapter from "../../adapter/DataAdapter";

import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

class Task {
    constructor(){

    }
    selectById = async (_id) =>{
        const _query    =   "select * from rb_project_tasks where pt_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    viewById = async (_id) =>{
        const _query    =   "select pt_id, ty.rb_type_desc as task_type, task, task_description, tyy.rb_type_desc as task_status, sender_id, note from rb_project_tasks pt inner join rb_types ty on (ty.rb_type_no=pt.task_type and ty.rb_type = 'task_type') inner join rb_types  tyy on (tyy.rb_type_no=pt.task_status and tyy.rb_type='task_status') where pt_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

    changeStatus = async (_id, _status) =>{
        const _query    =   "UPDATE rb_project_tasks SET task_status=" + _status + " where pt_id = " + _id + ";\r\nSELECT pt.pt_id, pt.prj_id, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task_status, tyy.rb_type_desc AS task_status_desc FROM rb_project_tasks pt INNER JOIN rb_types ty ON (ty.rb_type='task_type' AND ty.rb_type_no = pt.task_type) INNER JOIN rb_types tyy ON (tyy.rb_type='task_status' AND tyy.rb_type_no = pt.task_status) WHERE pt_id = " + _id + ";";
		console.log(_query);
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    changeType = async (_id, _type) =>{
        const _query    =   "UPDATE rb_project_tasks SET task_type=" + _type + " where pt_id = " + _id + ";\r\nSELECT pt.pt_id, pt.prj_id, pt.task_type, ty.rb_type_desc AS task_type_desc, pt.task_status, tyy.rb_type_desc AS task_status_desc FROM rb_project_tasks pt INNER JOIN rb_types ty ON (ty.rb_type='task_type' AND ty.rb_type_no = pt.task_type) INNER JOIN rb_types tyy ON (tyy.rb_type='task_status' AND tyy.rb_type_no = pt.task_status) WHERE pt_id = " + _id + ";";
       var dataAdapter =new DataAdapter(_query);
		var data = await dataAdapter.getRecords();
        return data;
    }
	
	QuickFormTask		=	(_project)	=>{
		const div	=	document.createElement('div');
		document.body.appendChild(div);
		const QuickFormTask = React.lazy(() => import('./QuickFormTask'));
		ReactDOM.render(
			<Suspense fallback={<div>Loading... </div>}>
				<QuickFormTask project={_project} />
			</Suspense>
		, div);		
	}
	assignTask		=	async(_project)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormAssignTask = React.lazy(() => import('./FormAssignTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormAssignTask project={_project} />
				</Suspense>
			, div);		
			
		});
	
		return _return;
	}
	
	ViewTask		=	async (_task)	=>{
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const ViewTask = React.lazy(() => import('./ViewTask'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<ViewTask project={_task} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);		
		});
	
		return _return;
	}
	viewTaskUpdateForm	= async	(_task)	=>{
		
		const _return = await new Promise((resolve, rej) => {
			const div	=	document.createElement('div');
			document.body.appendChild(div);
			const FormTaskUpdate = React.lazy(() => import('./FormTaskUpdate'));
			ReactDOM.render(
				<Suspense fallback={<div>Loading... </div>}>
					<FormTaskUpdate task={_task} callback={(obj)=>resolve(obj)} />
				</Suspense>
			, div);
		});
	
		return _return;
	}	

    clockIn = async () =>{
    }
    clockOut = async () =>{
    }

}
export default Task;