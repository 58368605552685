import React from "react";
import Base64 from 'Base64';
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import ComboBox from './component/ComboBox.js';
import Page from 'components/Page';

import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import DataAdapter from "./adapter/DataAdapter";

import ReactQuill, { Quill, Mixin, Toolbar }  from "react-quill-with-table";
import QuillBetterTable from "quill-better-table";
import 'quill-better-table/dist/quill-better-table.css'
import 'react-quill/dist/quill.snow.css';

class FormConversation extends React.Component {
    notificationSystem = React.createRef();
    constructor(props){
        super(props);
        this.state  =   {
			templateId      :   0,
			templateType    :   0,
            person          :   [],
            person_name     :   '',
            person_tel      :   '',
            person_email    :   '',
            email_from      :   'info@renobuilding.ca',
            email_to        :   '',
            email_subject   :   'Happy Holidays from RENO BUILDING!',
            email_text      :   '',
            email_step      :   20,
            template_text   :   '',
        }
    }
	onSendSMSHandler = (_text, _receiver) =>{
		// if(this.state.receiver_tel){
		// 	let formData = new FormData();

		// 	formData.append('receiver', _receiver);
		// 	formData.append('text', _text);
		// 	const options = {
		// 	  method: "POST",
		// 	  headers: {
		// 		  'Authorization': 'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
		// 	  },
		// 	  body: formData
		// 	};
	
		// 	fetch('https://node.renobuilding.ca:3001/sms', options)
		// 	  .then(response => response.json())
		// 	  .then(response => {
		// 		console.log(response);
		// 		this.runQuery("INSERT INTO rb_project_task_details (pt_id,prj_id,sender_id, receiver_id, task) values (" + __PROJECT_TASK_ID__ + "," + __PROJECT_ID__ + "," + cookie.get("v-person-id") + "," + this.state.projects.client_id.split(';')[0] + ",'" + this.state.othernotes + "',)","Task Status updated");

		// 	})
		// 	  .catch(err => console.error(err));  
		// }else{
		// 	this.addNotification("Receiver have no tel")
		// }
			
	}

    onSendEmailMultiHandler = (e)=>{
        var index   =1;
        var step    = this.state.email_step*1000;
        this.state.person.map(p=>{
            var _timer  = step *  index++
            setTimeout(()=>{
                this.addNotification("An email sent to " + p.person_name );
                //console.log(p.person_email);
                this.onSendEmailHandler(p.person_email, p.email_text)
            }, _timer);
            this.addNotification("An email will be send to " + p.person_name + " after " + _timer/1000 + " s");
        })
    }

	onSendEmailHandler = (email_to, email_text) =>{
		let formData = new FormData();
		formData.append("from", 	this.state.email_from);
		formData.append("to", 		email_to);
		formData.append("subject", 	this.state.email_subject);
		formData.append("text",     email_text);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://node.renobuilding.ca:3001/message' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification('E-Mail has been sent to ' + this.state.email_to);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}

    onTemplateTypeFieldChnageHandler = (e)=>{
		this.setState({templateType : e.target.value});
	}

	onPersonChangeHandler=(e)=>{
        if(this.state.templateId!=0){
            var _selectedOptions    =   e.target.selectedOptions;
            var _selectedId         =   "";
            for (let o of _selectedOptions) {
                _selectedId += o.value + ',';
            }
            var _person  =   [];
            var dataAdapter =new DataAdapter("select * from rb_person where person_id in (" + _selectedId + '-1)').getRecords().then((data)=>{
                data.map(data=>{
                    _person.push({
                        person_name     :   data.name + ' ' + data.lastname,
                        person_tel      :   data.person_tel, 
                        person_email    :   data.email,
                        email_to        :   data.email,
                        email_text      :   this.state.template_text.replaceAll('[CLIENT_NAME]', data.name + ' ' + data.lastname),    
                    });
                })
            this.setState({person  :   _person})});
        }else{
            this.addNotification("Please select a template!")   
        }
        
	}

	onTemplateIdFieldChnageHandler = (e)=>{
		var __template_id = e.target.value;
		this.setState({templateId : __template_id});

		var dataAdapter =new DataAdapter("select * from rb_templates where template_id = " + __template_id);
			dataAdapter.getRecords().then((data)=>{
				this.setState({
                    email_text      : data[0].task_desc,
                    template_text   : data[0].task_desc
				});
			})

	}
    
    onSaveTemplate = (e)=>{
        this.runQuery("UPDATE rb_templates SET task_desk = '" + this.state.email_text + "' WHERE template_id=" + this.state.templateId, "Template has been updated");
    }

    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: 'success'});
	};	

    runQuery=(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}

    render(){
        return(
			<Page className="DashboardPage" title={''} /*breadcrumbs={[{ name: 'Project', active: true }]}*/ >					
				<Row>
                    <Col md={12} sm={12} xs={12} className="mb-3">
                        <FormGroup row >
                            <Col>
                                <Label>Group</Label><br />
                                    <ComboBox  onChange={this.onTemplateTypeFieldChnageHandler} defaultValue={this.state.templateType}  table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="person_type" order="rb_type_no" />
                                <br />
                                <Label>Tempates</Label><br />
                                    <ComboBox onChange={this.onTemplateIdFieldChnageHandler}  table="rb_templates" valueField="template_id" displayField="template_name" filterField="person_type" filterValue={this.state.templateType }  order="template_name" />
                                <br />
                                <Label for="person" >Person</Label><br />
                                    <ComboBox size="5" multiple="true" name="person" onChange={this.onPersonChangeHandler}  table="rb_person" valueField="person_id" displayField="name" filterField="person_type" filterValue={this.state.templateType} order="name" />
                            </Col>
                            <Col>
                                <>
                                    <Label>Email Step (Second): </Label>
                                        <Input type={'number'} onChange={(e)=>{this.setState({email_step    :   e.target.value})}} value={this.state.email_step} /><br/>
                                    <Label>Selected Person : </Label>
                                    <div style={{overflowY: 'scroll', height:'300px'}} >
                                        {this.state.person.map(p=>{
                                            return(
                                                <>
                                                    <small>Name     :   <b>{p.person_name}</b>{' | '}</small>
                                                    <small>Tel      :   <b>{p.person_tel}</b>{' | '}</small>
                                                    <small>email    :   <b>{p.person_email}</b>{' | '}</small>
                                                    <br />
                                                </>
                                            )
                                        })}
                                    </div>
                                </>
                            </Col>
                        </FormGroup>
                        <FormGroup row >
                            <Col>
                                <br />
                                <Label>Subject</Label>
                                <Input type="text"  onChange={(v)=>this.setState({email_subject:v.target.value})} value={this.state.email_subject} ></Input>

                                <Label>Message</Label>
                                <ReactQuill
								ref={(el) =>{this.quillRef = el}}
								theme='snow'
								value={this.state.email_text}
								style={{minHeight: '200px'}}
                                onChange={(value)=>{this.setState({email_text    :   value})}}
							    />			

                                <Button color="secondary" onClick={(e)=>{this.setState({email_text  :   this.state.template_text})}} >Load Template</Button>{' '}
                                <Button color="secondary" onClick={(e)=>{this.onSaveTemplate();}} >Save Template</Button>{' '}
    							<Button color="secondary" onClick={(e)=>{this.onSendEmailMultiHandler(e);}} >Send E-mail</Button>{' '}
                                {/* <Button color="secondary" onClick={(e)=>{this.onSendSMSHandler(this.state.othernotes, this.state.receiver_tel);}} >Send SMS</Button>{' '} */}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
            </Page>

        )
    }
}
export default FormConversation;