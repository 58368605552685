import React from 'react';
import PropTypes from 'utils/propTypes';
import iconCopy from 'assets/img/icon/copy.png';

import classNames from 'classnames';

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from 'reactstrap';

import Avatar from '../Avatar';
import { Cookies } from 'react-cookie';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

const cookie =new Cookies();
const notificationSystem = React.createRef();
const addNotification = (_MESSAGE_, _TYPE_) => {
	const notification = notificationSystem.current;
	notification.addNotification({
	  message: _MESSAGE_,
	  level: _TYPE_
	});
};	

const getSource	=(_id)=>{
	var _src = require("assets/img/users/avetar.jpg");
	try{
		_src = require('assets/img/users/' + _id + '.jpg');
	}catch(err){}
	return _src;
}

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = classNames('bg-gradient-theme', className);

  return (
  <>
    <Card inverse className={classes} {...restProps}>
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
		<Avatar className="mb-2" src={getSource(cookie.get('v-person-id'))} size={avatarSize} />
			{/*
        <Avatar src={avatar} size={avatarSize} className="mb-2" />
			*/}
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardText>
			<hr/>
			<img role="button" className='float-right mr-1' src={iconCopy} height={16} 
				onClick={async (event) => { await navigator.clipboard.writeText(text.replaceAll('<br/>','\n')); addNotification("Signature Copied!", "info");}}
			/>

			<small><div dangerouslySetInnerHTML={{ __html: text }} /></small>
        </CardText>
      </CardBody>
      {children}
    </Card>
	<NotificationSystem ref={notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
 </>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
