import React from 'react';

import Base64 from 'Base64';
import Pdf from 'react-to-pdf';

import Page from 'components/Page';
import {Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import { Cookies } from 'react-cookie';

import ViewProjectTaskDetails from './ViewProjectTaskDetails.js';
import DataAdapter from './adapter/DataAdapter.js';

import ComboBox from './component/ComboBox.js';

const cookie =new Cookies();
var ref;

const options = {
	orientation: 'p',
	unit: 'mm',
	format: 'a4',
	putOnlyUsedFonts:true,
	format: [4,2]
   };
var __PROJECT_ID__;
var __RECEIVER_ID__;
var __TASK_TYPE__;


class ViewProjectTaskNote extends React.Component {
	notificationSystem = React.createRef();
	ref = React.createRef();
	
	constructor(props){
		super(props);

		if(props.match){
			__PROJECT_ID__ 		= this.props.match.params.id;
			__RECEIVER_ID__ = this.props.match.params.receiver;
			__TASK_TYPE__ = this.props.match.params.type;
		  }else{
			__PROJECT_ID__ = this.props.id;
			__RECEIVER_ID__ = this.props.receiver;
			__TASK_TYPE__ = this.props.type;
		  }
	

		this.state = {
			actionModal	:	false,
			quickEditTaskModal	:	false,
			task				:	null,
		};
		var _SQL_;
		if(this.getQueryString("isPreview")*1==1)
			_SQL_	=	"select * from rb_project_task_details WHERE prj_id=" + __PROJECT_ID__ + " AND sender_id=" + __RECEIVER_ID__ + " AND task_type=" + __TASK_TYPE__;
		else
			_SQL_	=	"select * from rb_project_tasks WHERE prj_id=" + __PROJECT_ID__ + " AND receiver_id=" + __RECEIVER_ID__ + " AND task_type=" + __TASK_TYPE__;

		var dataAdapter =new DataAdapter(_SQL_);
			dataAdapter.getRecords()
						.then(data=>{
							console.log(data.slice(-1))
							this.setState({
								task				:	data.slice(-1)[0],
							});
						})
						.catch(error=>{
							console.error('Error:', error);
						})
	}

	runQuery=(query, message, type)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message, type);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}


	render(){
		document.title = (this.state.task ? '#' + this.state.task.pt_id + '-' +  this.state.task.task :'');

		return (
			<>
			<Page className="DashboardPage" title={''} >					
				<Row>
					<Col md={12} sm={12} xs={12} className="mb-3">
						<Card>
							<ListGroup flush>
								<ListGroupItem>
									{__TASK_TYPE__ == 3 ?
										<>
											<Pdf targetRef={this.ref} options={options} x={10} y={10} scale={0.7} filename={(this.state.task ? '#' + this.state.task.pt_id + '-' +  this.state.task.task :'')}>
												{({ toPdf }) => (
													<button onClick={toPdf} type="button" className="btn btn-primary">
														DOWNLOAD
													</button>
												)}
											</Pdf>											
										</>
									:''}
									{__TASK_TYPE__ == 6 ?
										<>
											<Pdf targetRef={this.ref} options={options} x={10} y={10} scale={0.7} filename={(this.state.task ? '#' + this.state.task.pt_id + '-' +  this.state.task.task :'')}>
												{({ toPdf }) => (
													<button onClick={toPdf} type="button" className="btn btn-primary">
														DOWNLOAD
													</button>
												)}
											</Pdf>											
										</>
									:''}
								</ListGroupItem>
							</ListGroup>
							<CardBody>							
								<CardText>
									<div ref={this.ref}  >
										<div dangerouslySetInnerHTML={{ __html: (this.state.task ? this.state.task.note : '...') }} />
									</div>
								</CardText>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
			</Page>
			</>			
		);
	}

    getQueryString=(param)=>{
		var _result = "";
		var indexOfQuestionMark = window.location.href.indexOf('?');
		if(indexOfQuestionMark == -1)
		  return _result;
		var queryString = window.location.href.substring(indexOfQuestionMark);
		var indexOfParam = queryString.indexOf(param + '=');
		var indexOfParamEnd = queryString.indexOf('&', indexOfParam);
		if(indexOfParamEnd==-1)
		  _result = queryString.substring(indexOfParam + param.length +1);
		else
		  _result = queryString.substring(indexOfParam + param.length +1, indexOfParamEnd);
	  
		return _result;
	}  

    addNotification (_MESSAGE_, _TYPE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: _TYPE_});
	};	
	
}


export default ViewProjectTaskNote;