import React, {useState, useEffect} from 'react';
import TaskDetail from './TaskDetail';
import TaskDetailCard from './TaskDetailCard';

function ViewTaskDetail(props){
	const [taskDetail, setTaskDetail] = useState([]);
	const [reload, setReload] = useState(false);

	useEffect(()=>{
        var taskDetail =new TaskDetail();
        taskDetail.selectByTaskId(props.taskId).then(data=>{
            if(data)
                setTaskDetail(data);    
        })
    },[props, reload]);

    return(
        <>
        {taskDetail.map(t=>{
            return(
                <>
                    <TaskDetailCard taskDetail={t} reload={()=>{setReload(!reload)}} />                    
                </>
            )
        })}
        </>
    )
}
export default ViewTaskDetail;