import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import bn from 'utils/bemnames';

import { Cookies } from 'react-cookie';
import DataAdapter from '../../vortex/adapter/DataAdapter';
import Task		from "../../vortex/model/Tasks/Task";

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

const cookie =new Cookies();
const getSource	=(_id)=>{
	var _src = require("assets/img/users/avetar.jpg");
	try{
		_src = require('assets/img/users/' + _id + '.jpg');
	}catch(err){}
	return _src;
}

class Header extends React.Component {
  constructor(props){
    super(props);
    (new DataAdapter("SELECT * FROM rb_person WHERE person_type=8 AND user_expire_date > now();"))
      .getRecords()
      .then(data=>{
        this.setState({persons : data});
      });

    (new DataAdapter("SELECT rb_type_no as id, rb_type_desc as role FROM rb_types where rb_type = 'person_role' and find_in_set(rb_type_no,(select person_role from rb_person where person_id=" +cookie.get('v-person-id') + "))>0 ORDER BY rb_type_no;"))
      .getRecords()
      .then(data=>{
        this.setState({roles : data});
      });
  }
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    persons:[{}],
    roles:[{}],
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  onSignoutHandler = event =>{
    event.preventDefault();

    cookie.remove('v-person-id');
    cookie.remove('v-email');
    cookie.remove('v-tel');
    cookie.remove('v-name');
    cookie.remove('v-lastname');
    cookie.remove('v-person-title');
    cookie.remove('v-person-type');
    cookie.remove('v-person-role');
    cookie.remove('v-person-role-name');
    cookie.remove('v-person-desc');
    cookie.remove('v-email-organization');
    cookie.remove('v-tel-organization');
    cookie.remove('v-name-signature');
    cookie.remove('v-lastname-signature');
    cookie.remove('v-title-signature');
    cookie.remove('v-tel-signature');
    cookie.remove('v-email-signature');
    cookie.remove('v-link-signature');
    cookie.remove('v-ext-signature');
    cookie.remove('v-cell-signature');
    cookie.remove('v-person-token');
    	
    window.location = '/#/login';
    window.location.reload();
  }

  onRoleChangeHandler = e =>{
    cookie.remove('v-person-role');
    cookie.set('v-person-role', e.target.value)
    window.location.reload();
  }
  onPersonChangeHandler = e =>{
    e.persist();
    console.log(e)
    cookie.remove('v-person-id');
    cookie.remove('v-name');
    cookie.remove('v-lastname');
    cookie.set('v-person-id', e.target.value)
    cookie.set('v-name', e.target.selectedOptions[0].attributes[0].nodeValue)
    cookie.set('v-lastname', e.target.selectedOptions[0].attributes[1].nodeValue)
    window.location.reload();
  }
	callback					=   (obj)	=>{
		console.log(obj);
	}

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar className="mr-2">
		{/*
            <SearchInput />
		*/}
        </Nav>
        <Nav navbar className="mr-2">
              <UncontrolledButtonDropdown className="m-1">
                <DropdownToggle caret size="sm">
                  New
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem 
					onClick={(e)=>{
						var _task	=new Task();
						_task.QuickFormTask(419); //419 - Office 
					}}
				  >General Office Task</DropdownItem>
                  <DropdownItem 
					onClick={(e)=>{
						var _task	=new Task();
						_task.QuickFormTask(341); // 341 - VORTEX
					}}
				  >Software Task</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
        </Nav>
        
        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
				<Avatar className="can-click" src={getSource(cookie.get('v-person-id'))} title={cookie.get('v-name')} />
					{/*
				  <Avatar
					onClick={this.toggleUserCardPopover}
					className="can-click"
				  />
				  */}
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={cookie.get('v-name') + ' ' + cookie.get('v-lastname')}
                  subtitle={cookie.get('v-email')}				  
                  text={
					  cookie.get('v-name-signature') + ' ' + cookie.get('v-lastname-signature') + '<br/>' + 
					  cookie.get('v-title-signature') + '<br/>' + 
					  'RENO BUILDING CANADA, INC.' + '<br/>' + 
					  'TELL  | ' + cookie.get('v-tel-signature') + '<br/>' + 
					  cookie.get('v-email-signature')
					  }
                  className="border-light"
                >
                  <ListGroup flush>
				  {/*
                    <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>
				  */}
                    {cookie.get('v-person-id')*1==66?
                    <>
                    <ListGroupItem tag="button" action className="border-light">
                      <label>Person : </label><br/>
                      <select style={{width:'100%'}} defaultValue={cookie.get('v-person-id')} onChange={(e)=>{this.onPersonChangeHandler(e)}} >
                        <option value="">-</option>
                        {this.state.persons?this.state.persons.map(p=>{
                          return(
                            <option data-name={p.name} data-lastname={p.lastname} value={p.person_id}>{p.name + ' ' + p.lastname}</option>
                          )
                        })
                        :''}
                      </select>
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <label>Role : </label><br/>
                      <select style={{width:'100%'}} defaultValue={cookie.get('v-person-role')} onChange={(e)=>{this.onRoleChangeHandler(e)}} >
                        <option value="">-</option>
                        {this.state.roles?this.state.roles.map(r=>{
                          return(
                            <option value={r.id}>{r.role}</option>
                          )
                        })
                        :''}
                      </select>
                    </ListGroupItem>
                    </>                    
                    :''}
                    <ListGroupItem onClick={this.onSignoutHandler} tag="button" action className="border-light">
                      <MdExitToApp  /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
