import DataAdapter from "../adapter/DataAdapter"

class Project{
    constructor(){}
    selectById = async (_id) =>{
        const _query    =   "select * from rb_projects where prj_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    viewById = async (_id) =>{
        const _query    =   "SELECT ty.rb_type_desc as `prj_status_desc`,prj.*, person.*, pr.name as contact FROM rb_projects prj LEFT JOIN rb_types ty ON (ty.rb_type_no = prj.prj_status AND ty.rb_type='prj_status') LEFT JOIN rb_person person ON prj.client_id = person.person_id LEFT JOIN rb_person pr ON prj.contact_name = pr.person_id WHERE prj.prj_id=" + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    getClientId  = async (_id)=>{
        const _query    =   "SELECT client_id from rb_projects where prj_id = " + _id;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
}

export default Project;