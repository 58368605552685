import React, { useState, useEffect } from "react";
import _ from "lodash";
import Signature from "signature_pad";
import { FaSave, FaUndo, FaRedo, FaEraser, FaSignature } from "react-icons/fa";
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';
import Background from '../../assets/img/graph-paper.png';

class NotePad extends React.Component {
    constructor(props){
        super(props);

        this.signaturePad = React.createRef();
        this.signatureCanvas = React.createRef();

        this.state  ={
            signaturePad    :   null,
        };
    }

    componentDidMount=()=>{
        let canvas  =   this.signatureCanvas.current;
        let tempSignaturePad = new Signature(canvas, {
            //backgroundColor: "rgb(255, 255, 255)"
        });


        this.setState({
            signaturePad :   tempSignaturePad
        });
    }

    render(){
        return (
            <>
                <div className="App" style={{background: `url(${Background})`,backgroundPosition: 'center',backgroundSize: 'cover',backgroundRepeat: 'no-repeat', height:'500px'}} >
                    <div ref={this.signaturePad}  id="signature-pad">
                        <canvas ref={this.signatureCanvas} className="signature-canvas" style={{height:'500px'}} ></canvas>
                    </div>
                </div>
            </>
        )
    }


}
export default NotePad;