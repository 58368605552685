import logo200Image from 'assets/img/logo/logo_200.png';
import renoBuildingImage from 'assets/img/logo/logo-website.png';
import analysionLogo from 'assets/img/logo/analysion-logo-v2019.png';
import Base64 from 'Base64';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Form, FormGroup, Input, Label,   Alert} from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import { Cookies } from 'react-cookie';
import DataAdapter from '../adapter/DataAdapter';
import config from '../config/config';

const cookie =new Cookies();

class AuthForm extends React.Component {

  constructor(props){
      super(props);
      this.state = {username:'', password:'', spinned : false, disabled : false, message : ''};
      // var _token = this.getQueryString('token');
      // if(_token){
      //   // if(Base64.atob(_token.split('-')[0]) == _token.split('-')[1]){
      //     if(_token.split('-')[0]*1 == _token.split('-')[1]*1){
      //         var _person_id  = _token.split('-')[0];
      //         // var dataAdapter =new DataAdapter("select * from rb_person where person_id=" + _person_id + " and token<>'" + _token + "'").getRecords().then((data)=>{
      //           var dataAdapter =new DataAdapter("select * from rb_person where person_id=" + _person_id ).getRecords().then((data)=>{
      //               console.log(data[0].password);
      //             cookie.set('v-person-id', data[0].person_id);
      //             cookie.set('v-email', data[0].email);
      //             cookie.set('v-name', data[0].name);
      //             cookie.set('v-lastname', data[0].lastname);
      //             cookie.set('v-person-title', data[0].person_title);
      //             cookie.set('v-person-type', data[0].person_type);
      //             cookie.set('v-person-role', data[0].person_role);
      //             cookie.set('v-person-role-name', data[0].rb_type_desc);
      //             cookie.set('v-person-desc', data[0].person_desc);
      //             cookie.set('v-person-token', _token);
      //             this.state = {username: data[0].user_name, password: data[0].password, spinned : false, disabled : false, message : ''};
      //             this.setState({username:data[0].user_name, password : data[0].password})
      //             // (new DataAdapter("update rb_person set token='" + _token + "' where person_id=" + _person_id )).getRecords().then(data=>{
      //             //   // window.location.reload() //= (this.props.backlink.pathname + this.props.backlink.search);      

      //             // });
      
      //         })
      //   }else
      //     return false;
      // }
  }

  getQueryString=(param)=>{
    var _result = "";
    var indexOfQuestionMark = window.location.href.indexOf('?');
    if(indexOfQuestionMark == -1)
      return _result;
    var queryString = window.location.href.substring(indexOfQuestionMark);
    var indexOfParam = queryString.indexOf(param + '=');
    var indexOfParamEnd = queryString.indexOf('&', indexOfParam);
    if(indexOfParamEnd==-1)
      _result = queryString.substring(indexOfParam + param.length +1);
    else
      _result = queryString.substring(indexOfParam + param.length +1, indexOfParamEnd);

    return _result;
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = event => {
    //event.preventDefault();
    this.setState({spinned : true, disabled : true, message : ''});
    const formData = new FormData()
    formData.append('username', this.state.username);
    formData.append('password', this.state.password);

    fetch('http://renobuilding.ca/wp-json/reno/v1/rb_person_login' , {
			method: 'POST',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			},
			body: formData
			})
			.then(response => response.json())
			.then(data => {
        if(data.length > 0){
          var role  = data[0].person_role.indexOf(',');
          role  = data[0].person_role.substring(role*1 +1, data[0].person_role.indexOf(',',role+1));
      

          cookie.set('v-person-id', data[0].person_id);
          cookie.set('v-email', data[0].email);
          cookie.set('v-tel', data[0].person_tel);
          cookie.set('v-name', data[0].name);
          cookie.set('v-lastname', data[0].lastname);
          cookie.set('v-person-title', data[0].person_title);
          cookie.set('v-person-type', data[0].person_type);
          cookie.set('v-person-role', role);
          cookie.set('v-person-role-name', data[0].rb_type_desc);
          cookie.set('v-person-desc', data[0].person_desc);
          cookie.set('v-email-organization', data[0].organization_email);
          cookie.set('v-tel-organization', data[0].organization_tel);
          cookie.set('v-name-signature', data[0].name_signature);
          cookie.set('v-lastname-signature', data[0].lastname_signature);
          cookie.set('v-title-signature', data[0].title_signature);
          cookie.set('v-tel-signature', data[0].tel_signature);
          cookie.set('v-email-signature', data[0].email_signature);
          cookie.set('v-link-signature', data[0].link_signature);
          cookie.set('v-ext-signature', data[0].ext_signature);
          cookie.set('v-cell-signature', data[0].cell_signature);
          cookie.set('v-person-token', data[0].token);
          window.location.reload() //= (this.props.backlink.pathname + this.props.backlink.search);    
        }else{
          this.setState({spinned : false, disabled : false, message : 'You have entered an invalid username or password'});
        }
			})
			.catch(error => {
			  console.error(error)
			});
      /*
    if(this.state.username=='m.nouei@yahoo.ca' && this.state.password=='Y@allah1'){
      cookie.set('v-user',this.state.username);
      window.location = '/app';
    }
    */
  };

  handleChange = event => {
    event.persist();
    if(event.target.id==='username'){
      this.setState({username:event.target.value});
    }
    if(event.target.id==='password'){
      this.setState({password:event.target.value});
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  onSignInAsGuest = () =>{
    cookie.set('v-person-id', -1);
    cookie.set('v-email', 'guest@renobuilding.ca');
    cookie.set('v-name', 'Guest');
    cookie.set('v-lastname', '');
    cookie.set('v-person-title', 'Dear');
    cookie.set('v-person-type', 1);
    cookie.set('v-person-role', '9');
    window.location.reload();

  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;


    return (
      <Form onSubmit={this.handleSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <a href="https://renobuilding.ca" >
              <img src={renoBuildingImage} className="rounded" style={{ height: 60, cursor: 'pointer' }} alt="logo" onClick={onLogoClick} />
            </a>
            <h6>Intelligent Construction Technologies Software</h6>
            <sub>{config.version}</sub>
          </div>
        )}
        
        <FormGroup>
            {this.state.message!=''?
              <Alert color="danger">{this.state.message}</Alert>
          :''}
        </FormGroup>
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input id="username" {...usernameInputProps} onChange={this.handleChange} value={this.state.username} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input id="password" {...passwordInputProps}  onChange={this.handleChange} value={this.state.password} />
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} />
          </FormGroup>
        )}
        {/* <FormGroup check >
          <Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>
        </FormGroup> */}
        <hr />
        <Button
         disabled={this.state.disabled}
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}>
          {this.renderButtonText()}
         
        </Button>

        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            <a href="#login" onClick={(event)=>this.onSignInAsGuest(event)}>
                signin as guest
              </a>
          </h6>
        </div>
        {/* <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div> */}
        {showLogo && (
          <>
          <br />
          <br />
          <hr />
          <div className="text-center m-top-10 text-muted">
            <h6>{config.copyright} 
            {/* <a href="https://renobuilding.ca" >Reno Building Canada Inc.</a> */}
            , powered by </h6>
            <a href="http://www.analysion.com/" target="_blank" ><img src={analysionLogo} className="rounded" style={{ height: 30, cursor: 'pointer' }} alt="logo" onClick={onLogoClick} /></a>
          </div>
          </>
        )}

        <div className="text-center p-5">
          {this.state.spinned?
            <PageSpinner  />
          :''}
        </div>
       {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Username',
  usernameInputProps: {
    type: 'string',
    placeholder: 'your username',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
