class DateTime {
    constructor(){

    }
    getUTC =()=>{
        return new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[0] + ' ' + new Date(new Date(this.getDate() + ' ' + this.getTime()).toUTCString()).toISOString().split('T')[1].slice(0, 5);
    }
    getDate=()=>{
		var today = new Date();		
		var date = today.toISOString().split('T')[0];
		return date;			 
	}
	getTime=()=>{
		var today = new Date();
		var time = today.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false }); //today.toISOString().split('T')[1].slice(0,5);
		return time;			 
	}

}
export default DateTime;