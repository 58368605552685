import React, {useState, useEffect} from "react";

function VtxTimer(props){
	const [clockIn, setClockIn]	=	useState(props.clockIn);
	const [seconds, setSeconds]	=	useState(0);
	const [minutes, setMinutes]	=	useState(0);
	const [hours, setHours]	=	useState(0);
	const [time, setTime]	=	useState(0);
	const [load, setLoad]		=	useState(true);
	var options = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour:"2-digit",
		minute:"2-digit"
	};
	const startTime =()=> {
	  const today = new Date() ;
	  let h = today.getHours();
	  let m = today.getMinutes();
	  let s = today.getSeconds();
	  m = checkTime(m);
	  s = checkTime(s);
	  setTime(h + ":" + m + ":" + s);
	  setTimeout(startTime, 10000);
	}

	const checkTime = (i) => {
	  if (i < 10) {i = "0" + i};  // add zero in front of numbers < 10
	  return i;
	}
	
	useEffect(()=>{
		if(load){
			//setClockIn(new Date(props.clockIn + ' UTC')).toLocaleString("en", options);
			//setClockIn(new Date(props.clockIn + ' UTC').toLocaleString());
			//console.log(props.clockIn);
			startTime();
			setLoad(false);
		}
		
	},[])
	
	
	return(
		<>
			<p>{time }</p>
		</>
	);
}

export default VtxTimer;