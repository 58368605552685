export const getProjectById =(projectId)=>{
    var query = "SELECT " +
                "prj.prj_id,prj.prj_code,prj.prj_name, prj.prj_title,prj.prj_desc,rbt.rb_type_desc as prj_type, prj.prj_address," +
                "concat(prj.client_id,';',rbp.name,';',rbp.lastname, ';', rbp.address, ', ', rbp.city, ';', rbp.person_tel,';',rbp.email) as client_id, prj.contact_name," +
                "prj.template_id,prj.prj_postal_code,prj.prj_city,prj.prj_posted_date, prj.listing_end_date,prj.prj_category,prj.prj_date,concat(rbp2.name,' ',rbp2.lastname) AS refered_by_person_id, rbt3.rb_type_desc as prj_cat_loc, concat(rbt2.rb_type_no,';',rbt2.rb_type_desc) as prj_status,prj.prj_folder,prj.prj_folder_id, prj_budget, prj.latitude, prj.longitude, prj.prj_budget_desc, prj.start_date_desc," +
                "prj.prj_attached " +
                "FROM rb_projects prj " +
                "LEFT JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id " +
                "LEFT JOIN rb_types rbt on rbt.rb_type_no = prj.prj_type and rbt.rb_type = 'prj_type' " +
                "LEFT JOIN rb_types rbt2 on rbt2.rb_type_no = prj.prj_status and rbt2.rb_type = 'prj_status' " +
                "LEFT JOIN rb_types rbt3 on rbt3.rb_type_no = prj.prj_cat_loc and rbt3.rb_type = 'prj_cat_loc' " +
                "LEFT JOIN rb_person rbp on rbp.person_id = prj.client_id " +
                "LEFT JOIN rb_person rbp2 on rbp2.person_id = prj.refered_by_person_id " +
                "WHERE prj.prj_id=" + projectId + ";";

  return query;
}

export const getMyNotes =(projectId, personId)=>{
  var query = "SELECT pt.note " +
              "FROM rb_project_tasks pt " +
              "WHERE pt.prj_id=" + projectId + " and pt.receiver_id =" + personId + ";";

return query;
}