import Base64 from 'Base64';


const getRestAPI = () => {
	return `${process.env.REACT_APP_REST_URL}`;
  };


class DataAdapter{
	
    constructor(query){
		this._QUERY_ = 	query;
		this._RESULT_ = null;
    }
    async getRecords(){
		let formData = new FormData();
			formData.append('query', this._QUERY_);

		let requestOptions = {
			method: 'POST',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			},
			body : formData
		};
		const response = await fetch( getRestAPI() + '/wp-json/reno/v1/query', requestOptions)
			.then(response => {
			  return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				return responseData;
				})
			.catch(error => {
				console.error('Error:', error);
			})
	return 	response;
    }

}

export default DataAdapter;