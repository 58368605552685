// Assets
import loadingGif from 'assets/img/line-loading.gif';
import iconPhone from 'assets/img/icons8-phone-50.png';
import iconEdit from 'assets/img/icon/edit.png';

import React, {useState, useEffect} from "react";
import Project from "./Project";

import DriveGrid from '../../ViewDrive/DriveGrid.js';

import { Badge, Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label } from 'reactstrap';

function ViewProject(props){
    const [project, setProject] = useState(0);
    const [current, setCurrent] = useState([]);
    const [upload, setUpload] = useState();

    useEffect(()=>{
        initial(props);
    },[props])

    const initial = (props) =>{
        var projects =new Project();
        projects.viewById(props.projectId)
        .then(data=>{
            setProject(data);
        })
    }
	const numberWithCommas = (x) => {
		return (x?x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'');
	}
    
    return(
        <>{project?project.map(p=>{
            return (
                <FormGroup row >
                <Col  md={11} sm={11} xs={12}>
                    <Card>
                        <ListGroup flush>
                            <ListGroupItem>
                                <Label md={2}><b>Project Status</b></Label>
									{p.prj_status_desc}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Title</b></Label>
                                #{p.prj_id} - {p.prj_title}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Description</b></Label>
                                {p.prj_desc}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Location :</b></Label>																	 
                                {p.prj_address}, {p.prj_city}, {p.prj_postal_code}  
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Received :</b></Label>
                                {p.prj_posted_date}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Start Date :</b></Label>
                                {p.start_date}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Budget :</b></Label>
                                ${numberWithCommas(p.prj_budget)}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Label md={2}><b>Contact Name :</b></Label>
                                {p.contact}{'	'}
                                {/* {this.state.project_contact_tel ? <a href={'tel:' + this.state.project_contact_tel}><img src={iconPhone} /></a> : ''} */}
                            </ListGroupItem>
                            <ListGroupItem>
                                <Col md={12} sm={12} xs={12} className="mb-3">
                                    {p.prj_folder_id != null ?
                                        <>
                                            <Row>
                                                { false ?
                                                    <Col md={12} sm={12} xs={12} className="mb-3">
                                                        <form onSubmit={this.handleImageUpload} style={{ display: 'block' }} >
                                                            <div className="mb-3">
                                                                <label htmlFor="formFileMultiple">
                                                                    <i className="btn btn-outline-secondary btn-sm" >Upload</i>
                                                                    <input className="form-control" style={{ display: 'none' }} type="file" id="formFileMultiple" onChange={this.onFileChange} accept="image/*" multiple data-original-title="upload photos" />
                                                                </label>
                                                            </div>
                                                        </form>
                                                    </Col>
                                                    : ''}
                                            </Row>
                                            <Row>
                                                <Col md={12} sm={12} xs={12} className="mb-3">
                                                    <DriveGrid parent={p.prj_folder_id} onChildrenChange={(_id) => { setCurrent(_id) } } />
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <>
                                            {upload ?
                                                <Row>
                                                    <Col md={12} sm={12} xs={12} className="mb-3">
                                                        <div className="mb-3">
                                                            <label htmlFor="btnCreateFolder">
                                                                <input onClick={this.onCreateCloudSpace} className="form-control" type="button" id="btnCreateFolder" value="Create Cloud Folder" />
                                                            </label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : ''}
                                        </>
                                    }
                                </Col>
                            </ListGroupItem>
                        </ListGroup>
                    </Card>
                </Col>
                <Col  md={1} sm={1} xs={1}>
					<img style={{cursor:'pointer'}} src={iconEdit} width={16} onClick={(e)=>{
						var _project	=new Project();
							_project.editProjectForm(project[0].prj_id);
						}
					} />
				</Col>
            </FormGroup>

            )
        }):
            <img src={loadingGif} height="32"  />
        }</>
    );
}

export default ViewProject;