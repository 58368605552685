import DataAdapter from "../../adapter/DataAdapter";

class TaskDetail {
    constructor(){

    }
    selectByTaskId = async (_id) =>{
        const _query    =   "select ptd.ptd_id, ptd.pt_id, ptd.prj_id, ptd.task_status, ty.rb_type_desc as task_type, ptd.task_type as task_type_id, pr.name as sender_id, ptd.receiver_id, ptd.Clock_in, ptd.Clock_out, ptd.start_date, note from rb_project_task_details ptd inner join rb_types ty on (ty.rb_type_no= ptd.task_type and ty.rb_type = 'task_type') inner join rb_person pr on pr.person_id = ptd.sender_id where pt_id = " + _id + " ORDER BY ptd_id DESC;";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    insert = async (_taskDetail) =>{
        const _query    =   "INSERT INTO rb_project_task_details (pt_id, prj_id, task_type, task_status, sender_id, receiver_id, note, start_date, end_date) VALUES (" + _taskDetail.pt_id + "," + _taskDetail.prj_id + "," + _taskDetail.task_type + "," + _taskDetail.task_status + "," + _taskDetail.sender_id + "," + _taskDetail.receiver_id + ",'" + _taskDetail.note + "','" + _taskDetail.start_date + "','" + _taskDetail.end_date + "')";
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }
    deleteById = async (_id) =>{
        const _query    =   "DELETE from rb_project_task_details where ptd_id = " + _id ;
        var dataAdapter =new DataAdapter(_query);
        var data = await dataAdapter.getRecords();
        return data;
    }

}
export default TaskDetail;