import React from 'react';
import ReactDOM from 'react-dom';

import App from './vortex/App'
// import GoogleCalendar from './vortex/GoogleCalendar';

// import ViewTimeLine from './vortex/ViewTimeLine'
// import ViewTask from './vortex/model/Tasks/ViewTask'
// import App from './App'

// import './vortex/config/config'
// import ViewPersons from './vortex/model/Persons/ViewPersons';
// import ViewTimexReport from './vortex/model/Persons/ViewTimexReport';

// ReactDOM.render(<ViewTimeLine />, document.getElementById('root'));
// ReactDOM.render(<ViewPersons />, document.getElementById('root'));
// ReactDOM.render(<ViewTimexReport />, document.getElementById('root'));
// ReactDOM.render(<GoogleCalendar />, document.getElementById('root'));
// ReactDOM.render(<ViewTask projectId="9002" taskId="1749" />, document.getElementById('root'));

ReactDOM.render(<App />, document.getElementById('root'));
