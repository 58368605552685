import React from 'react';
import Base64 from 'Base64';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import VtxGrid from './component/VtxGrid';
import DataAdapter from './adapter/DataAdapter.js';
import { Card, CardBody, CardHeader, CardImg, CardLink, CardText, CardTitle, Col, Input, ListGroup, ListGroupItem, Row, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label} from 'reactstrap';

class ViewProjectTaskDetails extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		this.state = {
			projecttasks : [],
			project : this.props.project,
			note	:	'',
			noteModal	:	false,
		};
		let requestOptions = {
			method: 'GET',
			headers: {
				'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')
			}
		};
		var dataAdapter = new DataAdapter("select ptd.*, ty.*  from rb_project_task_details ptd inner join rb_types ty on ty.rb_type_no = ptd.task_type and ty.rb_type='task_type' where pt_id = " + this.props.project + " order by ptd_id desc;")
								.getRecords()
								.then((data)=>{
									this.setState({projecttasks : data});
								})
		
	}
	
	componentWillReceiveProps(nextProps) {
		this.setState({ project: nextProps.project });
		var dataAdapter = new DataAdapter("select ptd.*, ty.*  from rb_project_task_details ptd inner join rb_types ty on ty.rb_type_no = ptd.task_type and ty.rb_type='task_type' where pt_id = " + this.props.project + " order by ptd_id desc;")
								.getRecords()
								.then((data)=>{
									this.setState({projecttasks : data});
								})
	}	
	openURL = (event) => {
		window.location = '/#/FormTask';
	};

    onCellEdit = (e)=>{
		const __TABLE__		=	"rb_project_tasks";
		const __PRIMARY__	=	"pt_id";
		var _table	        =	__TABLE__;
		var _field          =	e.args.datafield;
		var _value          =	e.args.value;
		var _oldvalue       =	e.args.oldvalue;
        var _primaryfield   =   __PRIMARY__;   
		var _primary        =	e.args.row[__PRIMARY__];
		var sql = "UPDATE " + _table + " SET " + _field + " = '" + _value + "' WHERE " + _primaryfield + " = '" + _primary + "'";
        var dataAdapter =new DataAdapter(sql);
        if(_value != _oldvalue){
            dataAdapter.getRecords().then((_records) => {
                this.addNotification(_field + ' has been changed to ' + _value);
                })    
            console.log(sql);
        }
    }

    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({message: _MESSAGE_, level: 'success'});
	};

	runQuery=(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}

	onClickNoteButtonHandler=(e)=>{
		e.persist();
		if(this.state.noteModal){
			this.runQuery("UPDATE rb_project_task_details SET note='" + e.target.value + "' WHERE ptd_id =" + e.target.attributes[0].values ,"Note Updated.");
			this.setState({noteModal: false});
		}else{
			this.setState({noteModal: true});
		}
	}

	onNoteChange = (e) =>{

	}
	
	render(){
		return (
			<div>
				{/*}
				<div className="mb-3">
					<button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.assign} >ASSIGN</button>
				</div>
				*/}

				<div className="table-responsive" >
					{/* {this.state.projecttasks?
						<VtxGrid data={this.state.projecttasks} view="rb_project_tasks_view" onCellEdit={this.onCellEdit}  />
					:''} */}
					<table className="table table-dark table-striped">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">#</th>
							<th scope="col">TYPE</th>
							<th scope="col">TASK</th>
							{/* <th scope="col">SENDER</th>
							<th scope="col">RECEIVER</th> */}
							<th scope="col">CLOCK IN</th>
							<th scope="col">CLOCK OUT</th>
							<th scope="col">REAL HOURS</th>					  
							<th scope="col">%</th>					  
						</tr>
					</thead>
					<tbody>
						{this.state.projecttasks?this.state.projecttasks.map(ptd=>{
							var options = {
								year: "numeric",
								month: "2-digit",
								day: "2-digit",
								hour:"2-digit",
								minute:"2-digit"
							};
						
							return(
								<>
									<tr>
										<td>
											<button data-ptd={ptd.ptd_id} type="button" className="btn btn-outline-secondary btn-sm" onClick={(e)=>this.onClickNoteButtonHandler(e)} >N</button>
										</td>
										<td>{ptd.pt_id}</td>
										<td>{ptd.rb_type_desc}</td>
										<td>{ptd.task}</td>
										{/* <td>{ptd.sender_id}</td>
										<td>{ptd.receiver_id}</td> */}
										<td>{ptd.Clock_in?(new Date(ptd.Clock_in + ' UTC')).toLocaleString("en", options) : ''}</td>
										<td>{ptd.Clock_out?(new Date(ptd.Clock_out + ' UTC')).toLocaleString("en", options) :''}</td>
										<td>{ptd.real_hours}</td>
										<td>{ptd.percent_done}</td>
									</tr>
								</>
							)
						}):''}
					</tbody>
					</table>
				</div>
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.noteForm()}
			</div>
		);
	}

	noteForm =() =>{
		return (
			<>
				<Modal isOpen={this.state.noteModal} >
                  <ModalHeader>
					  <Button color="primary" onClick={(e)=>{this.setState({noteModal:false})}} >X</Button>{' '}
				  </ModalHeader>
                  <ModalBody>
					  <textarea style={{width:'100%', height: '200'}} onChange={(e)=>{this.setState({note:e.target.value});}} onBlur={this.onNoteChange} value={this.state.note} />					
                  </ModalBody>
                  <ModalFooter>
						<>
							<Button color="secondary" onClick={(e)=>{this.onClickNoteButtonHandler(e);}} >Save</Button>{' '}
						</>
                  </ModalFooter>
                </Modal>				
			</>
		);
	}

}

function showProjectTaskDetail(projecttasks){
	let items = [];
	if(projecttasks)
	for(var i=0; i < projecttasks.length; i++){
		items.push(
		<tr>
				<td>
					<button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.assign} >N</button>
				</td>
				<td>{projecttasks[i].pt_id}</td>
				<td>{projecttasks[i].task_type}</td>
				<td>{projecttasks[i].task}</td>
				<td>{projecttasks[i].sender_id}</td>
				<td>{projecttasks[i].receiver_id}</td>
				<td>{projecttasks[i].Clock_in}</td>
				<td>{projecttasks[i].Clock_out}</td>
				<td>{projecttasks[i].real_hours}</td>
				<td>{projecttasks[i].percent_done}</td></tr>);
			}
	return items;
}

export default ViewProjectTaskDetails;