// Assets
import iconThumbsUp from 'assets/img/icon/thumbs-up.png';
import iconHourGlass from 'assets/img/icon/hour-glass.png';
import iconClockIn from 'assets/img/icon/clock-in.png';
import iconClockOut from 'assets/img/icon/clock-out.png';
import iconRubbishBin from 'assets/img/icon/rubbish-bin.png';
import iconHightPerformance from 'assets/img/icon/triangle-green.png';
import iconMiddlePerformance from 'assets/img/icon/triangle-yellow.png';
import iconLowPerformance from 'assets/img/icon/triangle-red.png';

import iconDuration from 'assets/img/icon/duration.png';
import iconDeadline from 'assets/img/icon/deadline.png';
import iconMoveToNext from 'assets/img/icon/move-right.png';
import iconMoveToPre from 'assets/img/icon/move-left.png';
import iconSchedule from 'assets/img/icon/schedule.png';

import React, { Component, useEffect, useState } from 'react';
import Base64 from 'Base64';
import { gapi } from 'gapi-script';

import Page from 'components/Page';
import ComboBox from './component/ComboBox';
import {Progress,Input, Modal, ModalBody, ModalFooter, ModalHeader, Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardHeader, ListGroup, ListGroupItem, CardLink,CardText,CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown, CardFooter } from 'reactstrap';
import { Cookies } from 'react-cookie';
import iconPhone from 'assets/img/icons8-phone-50.png';
import iconAttach from 'assets/img/attach-16.png';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import DataAdapter from './adapter/DataAdapter';
import FormTaskAction from './FormTaskAction';
import { SettingsSystemDaydreamOutlined } from '@material-ui/icons';
import ViewTask from './model/Tasks/ViewTask';
import Avatar from '../components/Avatar';
import Switch from "react-switch";

import VTXCart from './component/VtxCart';
import VtxTimer from './component/VtxTimer';

const cookie =new Cookies();

var __PERSON__, __STATUS__ ="0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25";
var __PERSON_ROLE__;
var isNumber = function isNumber(value) {
   return typeof value === 'number' && isFinite(value) && value != 'null';
}
function allowDrop(e) {
	e.preventDefault();
	console.log(e);
}


class ViewProjectsOfPerson extends React.Component {
	notificationSystem = React.createRef();

	constructor(props){
		super(props);
		__PERSON__ = cookie.get("v-person-id");
		__PERSON_ROLE__ = cookie.get("v-person-role");
        if(props.match){
            __STATUS__ = this.props.match.params.status;
        }else{
            __STATUS__ = this.props.status;
        }
		__STATUS__ = (__STATUS__==null?"0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25": __STATUS__);

		var date = new Date();
		var _fromdate = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString().split('T')[0];
		var _todate = (new Date(date.getFullYear(), date.getMonth() + 1, 0)).toISOString().split('T')[0];
		// var _fromdate = data.getFullYear() + '-' + (data.getMonth() < 10? '0':'') + (data.getMonth()+1) + (data.getDate() > 15 ?  '-16' : '-01' );
		// var _todate = data.getFullYear() + '-' + (data.getMonth() < 10? '0':'') +  (data.getMonth()+1) + (data.getDate() > 15 ?  '-31' : '-15' );

		// if(__PERSON_ROLE__*1!=1 || __PERSON_ROLE__*1!=2){
		// 	_fromdate = data.getFullYear() + '-01-01';
		// 	_todate = data.getFullYear() + '-12-29';
		// }

		this.state = {projects : [], dataset : [], dataset_filtered : [], columns : __STATUS__.split(','), person : __PERSON__,  status : __STATUS__, loadingActionForm: false, loadingProjectManager:false, actionModal: false, task_id:0, task: null, 
			filter_person		:	-1,
			filter_department	:	-1,
			filter_board		:	-1,
			filter_from			: _fromdate, 
			filter_to			: _todate,
			current_project_id	:0,
			current_task_id		:0,
			current_task		:'',
			current_task_desc	:'',
			inProgress			:	false
		};
		this.loadData(__STATUS__, __PERSON__);
	}
	loadData = (_status, _person) => {
		var query = "SELECT (select if(count(*)>0, Clock_in, 0) from rb_project_task_details where pt_id = pt.pt_id and Clock_in is not null and Clock_out is null) as clockedIn,prj.prj_status,tyyy.rb_type_desc AS prj_status_desc,prj.prj_category as category_desc, prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, prj.prj_postal_code, prj.prj_posted_date, pt.pt_id, pt.task, pt.task_description, p.name AS sender_id, pp.name AS receiver, pt.receiver_id, pt.task_status AS task_status_id, ty.rb_type_desc as task_status, pt.expected_start_date, pt.start_date, pt.expected_end_date, pt.end_date, pt.percent_done, pt.note, pt.task_type AS `task_type_id`, tyt.rb_type_desc AS `task_type`, tyt.rb_type_color, pt.percent_done, pt.priority, pt.real_hours, pt.estimate_hours,pr.* FROM rb_projects prj LEFT JOIN rb_types tyyy ON (tyyy.rb_type_no=prj.prj_status AND tyyy.rb_type='prj_status') LEFT JOIN rb_person pr ON pr.person_id = prj.client_id INNER JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id  LEFT JOIN rb_person p ON p.person_id = pt.sender_id LEFT JOIN rb_person pp ON pp.person_id = pt.receiver_id INNER JOIN rb_types ty ON ty.rb_type_no = pt.task_status AND ty.rb_type = 'task_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_types tyt ON tyt.rb_type_no = pt.task_type AND tyt.rb_type = 'task_type' WHERE expected_end_date like '2023-%' AND " + (__PERSON_ROLE__*1==0 || __PERSON_ROLE__*1==1 || __PERSON_ROLE__*1==10? "true":"pt.receiver_id=" + __PERSON__) + "  ORDER BY pt.priority desc;";
		var dataAdapter =new DataAdapter(query);
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({projects : response, dataset : response});
				this.setState({
					projects			: response.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   )), 
					dataset				: response,
					dataset_filtered	: response,
				});
			})
	}
	componentWillReceiveProps(nextProps) {
		var query = "SELECT prj.prj_department,de.rb_type_desc AS department,prj.prj_id, prj.prj_code, prj.prj_title, prj.prj_address, prj.prj_city, pt.pt_id, pt.task, pt.task_description, p.name AS sender_id, pp.name AS receiver, pt.receiver_id, pt.task_status AS task_status_id, ty.rb_type_desc as task_status, pt.expected_start_date, pt.start_date, pt.expected_end_date, pt.end_date, pt.percent_done, pt.note, pt.task_type AS `task_type_id`, tyt.rb_type_desc AS `task_type`, tyt.rb_type_color, pt.percent_done, pt.priority, pt.real_hours, pt.estimate_hours FROM rb_projects prj INNER JOIN rb_project_tasks pt ON pt.prj_id = prj.prj_id  LEFT JOIN rb_person p ON p.person_id = pt.sender_id LEFT JOIN rb_person pp ON pp.person_id = pt.receiver_id INNER JOIN rb_types ty ON ty.rb_type_no = pt.task_status AND ty.rb_type = 'task_status' LEFT JOIN rb_types de ON de.rb_type_no=prj.prj_department AND de.rb_type='prj_department' LEFT JOIN rb_types tyt ON tyt.rb_type_no = pt.task_type AND tyt.rb_type = 'task_type'  ORDER BY ty.rb_type_no , pt.priority, pt.pt_id desc;";
		var dataAdapter =new DataAdapter(query);
			dataAdapter.getRecords()
			.then(response=>{
				this.setState({projects : response, dataset : response});
			})
	}	

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.props.status != prevProps.status) {
			this.setState({person : prevProps.person,  status : prevProps.status});
			this.loadData(prevProps.status, prevProps.person);
		}
    }
	
	onClearFilter=(e)=>{
		e.persist();
		this.setState({
			dataset_filtered	:	this.state.dataset,
			projects			:	this.state.dataset.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   )),
			filter_person		:	-1,
			filter_department	:	-1,
			filter_board		:	-1,
		})

	}
	onFilterChange = (e) =>{
		e.persist();
		console.log(e);
		if(e.target.id=='receiver_id'){
			this.setState({
				filter_person		:	e.target.value,
			})	
		}
		if(e.target.id=='prj_department'){
			this.setState({
				filter_department		:	e.target.value,
			})				
		}
		if(e.target.id=='task_type_id'){
			this.setState({
				filter_board		:	e.target.value,
			})				
		}
		var dataset_filtered = this.state.dataset_filtered;
		
		// var projects = this.state.dataset; //Reset projects from
		var valueField	=	e.target.attributes[0].value;
		this.setState({
			dataset_filtered	:	dataset_filtered.filter(p=>p[valueField]==e.target.value || e.target.value==-1),
			projects			:	dataset_filtered.filter(p=>p[valueField]==e.target.value || e.target.value==-1).filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   )),
		})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]==e.target.value || e.target.value==-1)})
	}
	onFilterDateChange = (_fromdate, _todate) =>{
		var projects = this.state.dataset_filtered;
		this.setState({projects : projects.filter(p=>(p["expected_end_date"] >= _fromdate && p["expected_end_date"] <= _todate   ))});

	}

	onFromDateChange = (e) =>{
		e.persist();
		var projects = this.state.dataset;
		var valueField	=	e.target.attributes[0].value;
		this.setState({filter_from : e.target.value, projects	:	projects.filter(p=>p["expected_end_date"]>=e.target.value && (this.state.filter_to != '' ? p["expected_end_date"]<=this.state.filter_to : true) )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]>=e.target.value )})
	}
	onToDateChange = (e) =>{
		e.persist();
		var projects = this.state.dataset;
		var valueField	=	e.target.attributes[0].value;
		this.setState({filter_to : e.target.value, projects	:	projects.filter(p=>(this.state.filter_from != ''?p["expected_end_date"]>=this.state.filter_from : true) && p["expected_end_date"]<=e.target.value   )})
		//this.setState({dataset	:	projects.filter(p=>p[valueField]<=e.target.value )})
	}

	onStatusChnageHandler = (e) => {
		this.setState({ prj_status: e.target.value });
		this.runQuery("UPDATE rb_projects SET prj_status = '" + e.target.value + "' WHERE prj_id=" + this.state.prj_id, 'Status Updated!');
		this.runQuery("INSERT INTO rb_project_tasks (prj_id,sender_id, receiver_id, start_date, task_status,task_type,task,task_description,expected_end_date,estimate_hours) VALUES (" + this.state.prj_id + "," + cookie.get("v-person-id") + "," + cookie.get("v-person-id") + ",now(), 8," + e.target.value + ",'" + this.state.task.prj_title + "','', now(),1);", "Project status has been changed")
	}
	onPreStatus = (e)=>{
		this.setState({ prj_status: (this.state.status*1-1)  });
		this.runQuery("UPDATE rb_projects SET prj_status = " + (this.state.status*1-1)  + " WHERE prj_id=" + this.state.prj_id, "Project Status changed");
	}
	onNxtStatus = (e)=>{
		this.setState({ prj_status: (this.state.status*1+1) });
		this.runQuery("UPDATE rb_projects SET prj_status = " + (this.state.status*1+1)  + " WHERE prj_id=" + this.state.prj_id, "Project Status changed");
	}

	onHoldHandler = (e) => {
		this.runQuery("UPDATE rb_project_tasks SET task_status = 12 WHERE prj_id=" + this.state.prj_id, "Task has been holded");
		this.runQuery("UPDATE rb_projects SET prj_status = 12 WHERE prj_id=" + this.state.prj_id, "Project has been holded");
	}
	onCloseHandler = (e) => {
		e.persist();
		this.runQuery("UPDATE rb_project_tasks SET task_status = 10 WHERE prj_id=" + this.state.prj_id, "Task has been closed");
		this.runQuery("UPDATE rb_projects SET prj_status = prj_status + 13 WHERE prj_id=" + this.state.prj_id, "Project has been closed");
	}
	
	onInprogrss	=	() =>{
		var projects = this.state.dataset_filtered;		
		if(!this.state.inProgress)
			this.setState({projects	:	projects.filter(p=>p["clockedIn"]*1==1)});
		else
			this.setState({projects	:	projects});
		
		this.setState({inProgress : !this.state.inProgress});
	}
	
	render(){
		var projects = this.state.projects;
		return (
			<Page className="DashboardPage" title="Tasks Board" >
				<Row>
					<Col lg={12} md={12} sm={12} xs={12} >
						<button class="btn btn-secondary btn-sm"  onClick={(event)=>{window.location="/#/FormPerson/rb_project_tasks"  }} >News Task</button>{'	'}
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter text="Person"  displayField="receiver" valueField="receiver_id" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.filter_person} />{'	'}
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter text="Department" displayField="department" valueField="prj_department" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.filter_department} />{'	'}
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<Filter text="Board" displayField="task_type" valueField="task_type_id" projects={this.state.dataset_filtered} onFilterChange={this.onFilterChange} defaultValue={this.state.filter_board } />{'	'}
					</Col>
					<Col lg={3} md={3} sm={12} xs={12} >
						<button class="btn btn-primary btn-sm " onClick={(event)=>{this.onClearFilter(event)}} >Clear Filter</button>{'		'}
					</Col>
					<Col lg={1} md={1} sm={12} xs={12} >
						<button class="btn btn-primary btn-block" onClick={()=>{

							var date = new Date(this.state.filter_from);
							var _fromdate = (new Date(date.getFullYear(), date.getMonth(), 1)).toISOString().split('T')[0];
							var _todate = (new Date(date.getFullYear(), date.getMonth()+1, 0)).toISOString().split('T')[0];

							this.setState({filter_from	:	_fromdate});
							this.setState({filter_to	:	_todate});
							this.onFilterDateChange(_fromdate, _todate);
						}} > &lt; </button>
					</Col>
					<Col lg={5} md={5} sm={6} xs={6} >
						<Input text="start_date" type={'date'} onChange={this.onFromDateChange} value={this.state.filter_from} />
					</Col>
					<Col lg={5} md={5} sm={6} xs={6} >
						<Input text="end_date" type={'date'} onChange={this.onToDateChange} value={this.state.filter_to} />
					</Col>
					<Col lg={1} md={1} sm={12} xs={12} >
						<button class="btn btn-primary btn-block" onClick={()=>{
							var date = new Date(this.state.filter_to);
							var _fromdate = (new Date(date.getFullYear(), date.getMonth()+1, 1)).toISOString().split('T')[0];
							var _todate = (new Date(date.getFullYear(), date.getMonth()+2, 0)).toISOString().split('T')[0];

							this.setState({filter_from	:	_fromdate});
							this.setState({filter_to	:	_todate});
							this.onFilterDateChange(_fromdate, _todate);

						}}  > &gt; </button>
					</Col>
					<Col lg={12} md={12} sm={12} xs={12} >
						<Grid clockIn={(event, _projects)=>this.onClockInHandler(event, _projects)} clockOut={(event, _projects)=>this.onClockOutHandler(event, _projects)} columns={this.state.columns} projects={this.state.projects} onActionHanlder={(e)=>{this.onActionHanlder(e)}} onProjectManager={(e)=>{this.onProjectManager(e)}} drag={(e)=>{this.drag(e)}} drop={(e)=>{this.drop(e)}} remove={(i)=>{this.remove(i)}} nextScrum={(i)=>this.nextScrum(i)} preScrum={(i)=>this.preScrum(i)} />
					</Col>
				</Row>	
				<NotificationSystem ref={this.notificationSystem} style={NOTIFICATION_SYSTEM_STYLE}/>
				{this.projectManagerForm()}
				{this.actionForm()}
			</Page>
		);
	}


    addNotification (_MESSAGE_) {
		const notification = this.notificationSystem.current;
		notification.addNotification({
		  message: _MESSAGE_,
		  level: 'info'
		});
	};	

	onProjectManager = (e) =>{
		e.persist();
		if(this.state.projectManagerModal){
			this.setState({projectManagerModal: false});
		}else{
			this.setState({
				projectManagerModal	:	true,
				loadingProjectManager				:	true,
				prj_id				:	e.target.attributes[0].value,
				prj_status			:	e.target.attributes[1].value,
			})
		}
	}
	onActionHanlder = (e) =>{
		e.persist();
		if(this.state.actionModal){
			this.setState({actionModal: false});
		}else{
			this.setState({
				actionModal				:	true,
				loadingActionForm				:	true,
				task_id				:	e.target.attributes[0].value,
				task_status			:	e.target.attributes[1].value,
				task_task			:	e.target.attributes[2].value,
				task_description	:	e.target.attributes[3].value,
				task_percent_done	:	e.target.attributes[4].value,
				task_note			:	e.target.attributes[5].value,
				task_end			:	e.target.attributes[6].value,
				task_priority		:	e.target.attributes[7].value,
				task_real_hours		:	e.target.attributes[8].value,
				task_estimate_hours	:	e.target.attributes[9].value,
				task_project		:	e.target.attributes[10].value,
				task_sender			:	e.target.attributes[11].value,
				task_receiver		:	e.target.attributes[12].value,
				task_type			:	e.target.attributes[13].value,
				task_start			:	e.target.attributes[14].value,
				task_expected_end	:	e.target.attributes[15].value,
			});
			this.onClockInHandler(e, this.state.dataset_filtered);
		}
	}
	onTaskStatusChnageHandler=(e)=>{
		this.setState({task_status	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_status = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Status Updated!');

	}
	onTaskTypeChnageHandler=(e)=>{
		this.setState({task_status	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET task_type = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Status Updated!');

	}

	onTaskPercentChangeHandler=(e)=>{
		this.setState({task_percent_done	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET percent_done = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Percent Updated!');
	}
	onTaskNoteChangeHandler=(e)=>{
		this.setState({task_note	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET note = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Note Updated!');
	}
	onTaskStartChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET start_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Start date Updated!');
	}
	onTaskEndChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET end_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task End Date Updated!');
	}
	onTaskExpectedEndChangeHandler=(e)=>{
		this.setState({task_expected_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task expected End Date Updated!');
	}
	onTaskPriorityChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET priority = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task Priority Updated!');
	}
	onTaskRealChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET real_hours = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task real hours Updated!');
	}
	onTaskEstimateChangeHandler=(e)=>{
		this.setState({task_end	:	e.target.value});	
		this.runQuery("UPDATE rb_project_tasks SET estimate_hours = '" + e.target.value + "' WHERE pt_id=" + this.state.task_id, 'Task estimate hours Updated!');
	}
	
	onClockInHandler=(e, _project)=>{
		e.persist();

		var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
		var	sql ="INSERT INTO rb_project_task_details ( pt_id, prj_id, task_status, task_type, sender_id, receiver_id, Clock_in ) values " +
				"(" + e.target.attributes["data-task-id"].nodeValue + ", " + e.target.attributes["data-project-id"].nodeValue + ", 9 , 98 , " + cookie.get('v-person-id') + "," + cookie.get('v-person-id') + ", '" + _UTC_ + "')" ;

		this.runQuery( sql, "Clocked In", "success");
		this.runQuery("UPDATE rb_project_tasks SET task_status = 9 WHERE pt_id=" + e.target.attributes["data-task-id"].nodeValue, "Task status has been updated", "info");

		// this.setState({actionModal: true});

		this.setState({ projects : _project});

	}
	onClockOutHandler=(e, _project)=>{
		e.persist();
		var _UTC_ = (new Date()).toISOString().split('T')[0] + ' ' + (new Date()).toISOString().split('T')[1].slice(0, 5);
		var sql	=	"UPDATE rb_project_task_details SET percent_done=100,note='', task_status = 10, Clock_out = '" + _UTC_ + "', real_hours = TIMESTAMPDIFF(MINUTE, Clock_in, '" + _UTC_ + "') " +
		"WHERE task_status=9 AND pt_id=" + e.target.attributes["data-task-id"].nodeValue + " AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ";";
		this.runQuery(sql, "Clock Out", "error");		

		 sql	=	"UPDATE rb_project_tasks SET real_hours = (" +
					"SELECT sum(TIMESTAMPDIFF(MINUTE, Clock_in, Clock_out)) FROM `rb_project_task_details`" +
					"WHERE pt_id = " + e.target.attributes["data-task-id"].nodeValue + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + "), percent_done = (select sum(percent_done) from rb_project_task_details WHERE pt_id = " + e.target.attributes["data-task-id"].nodeValue + " AND task_status=10 AND task_type=98 AND sender_id=" + cookie.get('v-person-id') + ") where pt_id = " + e.target.attributes["data-task-id"].nodeValue  + ";";

		this.runQuery(sql, "Real hours calculated", "info");		
		// this.setState({actionModal: false});
		var _index	= _project.findIndex(p=>p.pt_id == e.target.attributes['data-task-id'].nodeValue);
		//_project[_index].percent_done	 = 100;
		this.setState({ projects : _project});

	}
	
	projectManagerForm = () => {
		if(this.state.loadingProjectManager){
			var query = "SELECT * FROM rb_projects WHERE prj_id=" + this.state.prj_id;
			var dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({
						task : response[0], 
						loadingProjectManager : false, 
						projectManagerModal:true});
				})	
		}
		
		return (
			<>
				<Modal isOpen={this.state.projectManagerModal} >
					<ModalHeader>
						<button type="button" className="btn btn-primary btn-sm" onClick={(event)=>{this.setState({projectManagerModal	:	false})}} >X</button>
					</ModalHeader>
					<ModalBody>
						<ComboBox id="c1" onChange={this.onStatusChnageHandler} table="rb_types" valueField="rb_type_no" displayField="rb_type_desc" filterField="rb_type" filterValue="prj_status" defaultValue={this.state.prj_status} order="rb_type_no" />{'	'}
						<button id={'pre'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onPreStatus(event) }} >Pre Status</button>{'	'}
						<button id={'nxt'} type="button" className="btn btn-outline-secondary btn-sm" onClick={(event) => { this.onNxtStatus(event) }} >Next Status</button>{'	'}
						{this.state.prj_status * 1 <= 12 ?
							<button type="button" className="btn btn-outline-info btn-sm" onClick={(event) => { this.onHoldHandler(event) }} >Hold/Stop the Job</button>
							: ''}{'			'}
						{this.state.prj_status * 1 <= 13 ?
							<button type="button" className="btn btn-outline-danger btn-sm" onClick={(event) => { this.onCloseHandler(event) }} >Close the Job</button>
							: ''}
					</ModalBody>
				</Modal>
			</>
		);
	}

	actionForm = () => {
		if(this.state.loadingActionForm){
			
			var query = "SELECT * FROM rb_project_tasks WHERE pt_id=" + this.state.task_id;
			//console.log(query);
			var dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({task : response, loadingActionForm : false, actionModal:true});
				})	
				
			query = "SELECT * FROM rb_project_task_details WHERE pt_id=" + this.state.task_id + " AND sender_id=" + cookie.get('v-person-id') + " AND task_type=98 AND Clock_in is not null AND Clock_out is null";
			dataAdapter =new DataAdapter(query);
				dataAdapter.getRecords()
				.then(response=>{
					this.setState({task_detail : response, loadingActionForm : false, actionModal:true});
				})	
		}
		const options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour:"2-digit",
			minute:"2-digit"
		};

		return (
			<>
				<Modal isOpen={this.state.actionModal} >
					<ModalHeader>
						<Row>
							<Col>
							{/*
								<button data-task-id={this.state.task?this.state.task.pt_id:0} data-project-id={this.state.task?this.state.task.prj_id:0} type="button" className="btn btn-success btn-sm float-left" onClick={(e) =>{
									e.persist();
									var _projects	= this.state.projects;
									var _index	= _projects.findIndex(p=>p.pt_id == this.state.task_id);
									_projects[_index].clockedIn	 = 0;
									this.onClockInHandler(_projects);
									
									}} >Clock In</button>{'	'}
							*/}
							{this.state.task_detail?this.state.task_detail.map(ptd=>{
								const _START_DATE_	=	new Date(ptd.Clock_in + ' UTC'); //(new Date(ptd.Clock_in + ' UTC')).toLocaleString("en", options);
								const _TODAY_		=	new Date();
								var diffMins		=	Math.round((((_TODAY_ - _START_DATE_) % 86400000) % 3600000) / 60000); 
								return(
									<>
										<small>You have been working on for <i>{ptd.Clock_in? diffMins:''} minutes</i></small>
									</>
								)
							})
							:''}
							</Col>
							<Col>
							{/*
								<button type="button" className="btn btn-primary btn-sm float-right ml-1 mr-1" onClick={(event)=>{this.setState({actionModal:false})}} >X</button>{' '}
							*/}
								<button data-task-id={this.state.task_id} data-project-id={this.state.task_project} type="button" className="btn btn-primary btn-sm float-right ml-1 mr-1" onClick={(e) => {
									e.persist();
									var _projects	= this.state.projects;
									var _index	= _projects.findIndex(p=>p.pt_id == this.state.task_id);
									_projects[_index].clockedIn	 = 0;
									this.onClockOutHandler(e, _projects);
									this.setState({actionModal:false});
									
								}} >x</button>{'	'}
							</Col>
						</Row>
						{/* <button disabled={!this.state.clockIn & false} type="button" className="btn btn-danger btn-sm float-right" onClick={(event)=>{this.onClockOutHandler(event)}} >Clock Out</button>{' '} */}
					</ModalHeader>
					<ModalBody>
						<ViewTask project={this.state.projects.find(p=>p.pt_id == this.state.task_id)} projectId={this.state.task_project} taskId={this.state.task_id} onChangeTaskStatus={(e)=>this.onChangeTaskStatus(e)} />
						{/* <FormTaskAction id={this.state.task_project} task={this.state.task_id} /> */}
					</ModalBody>
				</Modal>
			</>
		);
	}
	runQuery=(query, message)=>{
		let formData = new FormData();
		formData.append("query", query);
		let requestOptions = {method: 'POST',headers: {'Authorization':'Basic ' + Base64.btoa('sizotech:IsKfs$m$SZV#4Eprtj7zSPbO')},body : formData};
		fetch('https://renobuilding.ca/wp-json/reno/v1/query/' , requestOptions)
			.then(response => {
				return Promise.all([response.json(),response])
			})
			.then(([responseData,response]) => {
				console.log(responseData);
				this.addNotification(message);
					
			})
			.catch(error => {
				console.error('Error:', error);
			})		
	}
	drag = (e) => {
		e.persist();
		if(this.state.projects){
			e.dataTransfer.setData("text", e.target.id);
		}
	}
	drop = (e) => {
		e.persist();
		if(e.target.attributes['status']){
			var _pt_id = e.dataTransfer.getData("text");
			var _projects	= this.state.projects;
			var _index	= _projects.findIndex(p=>p.pt_id == _pt_id);
			_projects[_index].task_status	 = e.target.attributes['status'].nodeValue;
			_projects[_index].task_status_id = e.target.attributes['status-id'].nodeValue;
			this.runQuery("UPDATE rb_project_tasks SET task_status = " + e.target.attributes['status-id'].nodeValue + " WHERE pt_id=" + _pt_id, "Task status has been updated");
			if(e.target.attributes['status-id'].nodeValue*1==10){
				_projects[_index].estimate_hours = Math.round(_projects[_index].real_hours/60);
				this.runQuery("UPDATE rb_project_tasks SET estimate_hours = ROUND(real_hours/60) WHERE (estimate_hours is null or estimate_hours=0) and  pt_id=" + _pt_id, "Task estimate hours has been updated");
			}
			this.setState({ projects : _projects});

		}
	}
	remove =(_index)=>{
		var _projects	= this.state.projects;
		this.runQuery("DELETE FROM rb_project_tasks WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " removed");
		_projects.splice(_index, 1);
		this.setState({ projects : _projects});									
	}
	nextScrum =(_index)=>{
		var _projects	= this.state.projects;
		var expected_end_date = new Date(_projects[_index].expected_end_date);
		var expected_end_date_ =new Date(expected_end_date.setMonth(expected_end_date.getMonth() +1)).toISOString().split('T')[0];
		//var expected_end_date_ = (new Date(expected_end_date.getFullYear(), expected_end_date.getMonth()+1, expected_end_date.getDate()+1)).toISOString().split('T')[0];
		
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date='" + expected_end_date_ + "' WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " moved to the next month");
		_projects[_index].expected_end_date	=	expected_end_date_;
		this.setState({ projects : _projects.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   ))});									
	}
	preScrum =(_index)=>{
		var _projects	= this.state.projects;
		var expected_end_date = new Date(_projects[_index].expected_end_date);
		var expected_end_date_ =new Date(expected_end_date.setMonth(expected_end_date.getMonth() -1)).toISOString().split('T')[0];
		//var expected_end_date_ = (new Date(expected_end_date.getFullYear(), expected_end_date.getMonth()-1, expected_end_date.getDate())).toISOString().split('T')[0];
		this.runQuery("UPDATE rb_project_tasks SET expected_end_date='" + expected_end_date_ + "' WHERE pt_id=" + _projects[_index].pt_id, "Task #" + _projects[_index].pt_id + " moved to the pre month");
		_projects[_index].expected_end_date	=	expected_end_date_;
		this.setState({ projects : _projects.filter(p=>(p["expected_end_date"] >= this.state.filter_from && p["expected_end_date"] <= this.state.filter_to   ))});									
	}

	onChangeTaskStatus =(e)=>{
		var _projects	= this.state.projects;
		var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-id'].nodeValue);
		_projects[_index].task_status	 = e.target.attributes['data-status'].nodeValue;
		_projects[_index].task_status_id = e.target.attributes['data-status-id'].nodeValue;
		this.setState({ projects : _projects});
	}
}

export const Filter = (props) =>{
	// const [defaultValue, setDefaultValue] = useState(props.defaultValue);

	var projects = props.projects;
	var tempValue	=	[];
	var displayField	=	props.displayField;
	var valueField		=	props.valueField;

	// useEffect(()=>{
	// 	setDefaultValue(props.defaultValue);
	// 	console.log(props.defaultValue);
	// 	console.log(defaultValue);
	// });

	if(!projects)
		return null;
	return(
		<>
			<select id={props.valueField} onChange={(e)=>props.onFilterChange(e)} style={{width:'100%'}} >
				<option value="-1" style={{color:'gray'}} > {props.text}</option>
				{projects.map(project=>{
					return(
						<>
							{ tempValue.findIndex((v)=> v==project[displayField]) == -1 ?
								<>
									<option value={project[valueField]} selected={project[valueField]==props.defaultValue? true : false} >{project[displayField]}</option>
									{tempValue.push(project[displayField]) }
								</>
							:''}
						</>
					)
				})}
			</select>
		</>
	);
}

export const LeadLabel = (props) =>{
	var _filtered_project	=	props.projects.filter(p=>p.task_status_id==props.status && isNumber(p.percent_done*1));

	var _average			=	(_filtered_project?_filtered_project.reduce((a, b) => {
		b	=	(isNumber(b.percent_done*1) ? b.percent_done*1 :0);
		return a+b;
	},0) / _filtered_project.length:0);

	var _performance		=	(_filtered_project?_filtered_project.reduce((a, b) => {
		var _performance = (b.percent_done/100)/(b.real_hours/60/b.estimate_hours);
		b	=	(isNumber(_performance) ?   _performance:0);
		return a+b;
	},0) / _filtered_project.length:0);
	
	var _performance_color = (_performance<=60?'#fb2a55':
			_performance<=80?	'#ffd700':
			_performance<=100?	'#45b649':
			''
		);
	var _average_color = (_average<=60?'#fb2a55':
		_average<=80?	'#ffd700':
		_average<=100?	'#45b649':
			''
	);


	return <div className="myHeader border-top rounded-top p-1" style={{width:'300px', backgroundColor:'lightgray',color:'black', marginBottom:'50px'}}>{props.text}{'  '}
				<Badge color="dark" pill className="mr-1">
					{props.projects? props.projects.filter(p=>p.task_status_id==props.status).length :''}
				</Badge><br />

				<img src={iconThumbsUp} width={16} />
				<small>{_average.toFixed(1)}</small>{'	'}

				<img src={iconHourGlass} width={16} />
				<small>{props.projects? Math.round(10*(props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.real_hours*1},0))/60)/10:0} </small>{'	'}

				{_performance<0.6?
					<img src={iconLowPerformance} width={16} />
				:''}
				{_performance>=0.6 && _performance<0.8?
					<img src={iconMiddlePerformance} width={16} />
				:''}
				{_performance>=0.8?
					<img src={iconHightPerformance} width={16} />
				:''}
				{(100*_performance).toFixed(1)}

				<Badge color="light" pill className="mr-1">
					ESTIMATE : {props.projects? props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.estimate_hours*1},0).toFixed(1) :''}
				</Badge>

				{/* <Badge color="info" pill className="mr-1">
					SUM : {props.projects? Math.round(10*(props.projects.filter(p=>p.task_status_id==props.status).reduce((a,b)=>{return a*1 + b.real_hours*1},0))/60)/10 :''}
				</Badge> */}


				{/* <Badge color="light" style={{color : _performance_color}} pill className="mr-1">
					AVG. PERFORM. : {Math.round(_performance*1000)/10}
				</Badge> */}
				
				{/* <Badge color="light" style={{color : _average_color}}  pill className="mr-1">
					AVG. DONE : {Math.round(_average*100)/100}
				</Badge> */}
			</div>
			
}

export const Lead = (props) =>{
	var projects = props.projects;
	var _status = props.status;
	var today = new Date();
	var date = today.getFullYear() + '-' + (today.getMonth() <= 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() <= 9 ? '0' + today.getDate() : today.getDate());
	var task_status_color	=	'';
	var card_priority_color	=	'';
	var _performance;
	var _performance_color;
	var date0, date1, date2;
	const getSource	=(project)=>{
		var _src = require("assets/img/users/avetar.jpg");
		try{
			_src = require('assets/img/users/' + project.receiver_id + '.jpg');
		}catch(err){}
		return _src;
	}
	if(!projects)
		return null;
	return(
		<>
			{projects.map(project=>{
				task_status_color = (project.status*1==0? "black":
					project.priority*1==1?"black":
					project.priority*1==2?"yellow":
					project.priority*1==3?"red":'');
				card_priority_color = (project.priority*1==0? "#dae0e5":
					project.priority*1==1?"#fb2a55":
					project.priority*1==2?"#ffd700":
					project.priority*1==3?"#dae0e5":'');
				
				_performance	= Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours))>100? 100: Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours));
				_performance_color = (_performance<=60?'#fb2a55':
										_performance<=80?	'#ffd700':
										_performance<=100?	'#45b649':
										''
				);
				date0 	=new Date(project.start_date);
				date1 	=new Date();
				date2	=new Date(project.expected_end_date);

				const diffTime0 = Math.abs(date1 - date0);
				const diffDays0 = Math.ceil(diffTime0 / (1000 * 60 * 60 * 24)); 

				const diffTime = Math.abs(date2 - date1);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
				return(
				<>
				{project.task_status_id == _status?
					<>
						<div id={project.pt_id?project.pt_id:0}  draggable="true" onDragStart={(event)=>props.drag(event)} >
							<Card style={{ backgroundColor : (project.clockedIn*1==1? '#348F50' : '#fff')}} >
								{/* <CardHeader style={{borderColor: card_priority_color, backgroundColor : project.rb_type_color }} >
									<Badge color="secondary" title="Task Type" >{project.task_type}</Badge><br />
									<img src={iconClockIn} height={16} id={'in_' + project.pt_id} onClick={(e)=>{}} />
									<img src={iconClockOut} height={16} id={'out_' + project.pt_id} style={{display:'none'}} onClick={(e)=>{alert(e)}}  />
									{'  '}{project.expected_end_date?project.expected_end_date : ''}<sup>exp.e.d.</sup>
								</CardHeader> */}
								<CardBody style={{borderColor: card_priority_color }} >
										<>
											{project.clockedIn != '0' ? 
												<>
													<img className='float-right' src={iconClockOut} height={16} data-task-id={project.pt_id} data-project-id={project.prj_id}
													onClick={(e)=>{
														e.persist();
														var _projects	= props.projects;
														var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-task-id'].nodeValue);
														_projects[_index].clockedIn	 = 0;
														props.clockOut(e, _projects);

													}}  
													/>
													<VtxTimer clockIn={project.clockedIn} />
												</>
											:
												<img className='float-right' src={iconClockIn} height={16} data-task-id={project.pt_id} data-project-id={project.prj_id}
												onClick={(e)=>{
													e.persist();
													var _projects	= props.projects;
													var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes['data-task-id'].nodeValue);
													_projects[_index].task_status	 = "In Progress";
													_projects[_index].task_status_id	 = 9;
													_projects[_index].clockedIn	 = 1;
													props.clockIn(e, _projects);
													// this.runQuery("UPDATE rb_project_tasks SET estimate_hours = ROUND(real_hours/60) WHERE (estimate_hours is null or estimate_hours=0) and  pt_id=" + _pt_id, "Task estimate hours has been updated");
												}} 
												/>
											}
										</>
									
									<CardTitle className="text-capitalize">																		
										<b title="Task Type" >{project.task_type}</b>
										<br />
										<Badge
											data-task-id={project.pt_id?project.pt_id:0} 
											data-task-status={project.task_status_id?project.task_status_id:0} 
											data-task-task={project.task?project.task:''} 
											data-task-description={project.task_description?project.task_description:''} 
											data-task-percent-done={project.percent_done? project.percent_done:0} 
											data-task-note={project.note? project.note:''} 
											data-task-end={project.end_date? project.end_date: ''} 
											data-task-priority={project.priority? project.priority: ''} 
											data-task-real-hours={project.real_hours? project.real_hours: ''} 
											data-task-estimate-hours={project.estimate_hours? project.estimate_hours: ''} 
											data-task-project={project.prj_id? project.prj_id: ''} 
											data-task-sender={project.sender? project.sender: ''} 
											data-task-receiver={project.receiver? project.receiver: ''} 
											data-task-type={project.task_type? project.task_type: ''} 
											data-task-start={project.start_date? project.start_date: ''} 
											data-task-expected-end-date={project.expected_end_date? project.expected_end_date: ''} 
											data-project={project.prj_id? project.prj_id : ''}
											onClick={(e)=>props.onActionHanlder(e)}
											data-project-id={project.prj_id}										
											href="javascript:(0);" 														
											className='float-right' 
										>#{project.pt_id} </Badge>
										
											{ cookie.get('v-person-role')*1===0 || cookie.get('v-person-id')*1===66?
												<>
													<a style={{color:'#000'}} target={'_blank'} href={"/#/ViewProjectTask/" + project.prj_id + "/" + project.pt_id} >{project.task}</a>
												</>
											:
												<>
													<snap style={{color:'#000'}} >{project.task}</snap>
												</>
											}
												
											{ cookie.get('v-person-role')*1===0 || cookie.get('v-person-role')*1===1 || cookie.get('v-person-id')*1===66?
											<>
											<br/>
											<div className='float-right' >
											<a href='javascript:void(0)' onClick={(e)=>{
												e.persist();
												var _projects	= projects;
												var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
												const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
												if(window.confirm("Are you sure to move to next scrum?\n" + taskInfo)){
													props.nextScrum(_index);
												}
												}} >
													<img title='To the Next Month'  data-id={project.pt_id?project.pt_id:0} src={iconMoveToNext} width={16} /></a>
											</div>
											<div className='float-right' >
											<a href='javascript:void(0)' onClick={(e)=>{
												e.persist();
												var _projects	= projects;
												var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
												const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
												if(window.confirm("Are you sure to move to pre scrum?\n" + taskInfo)){
													props.preScrum(_index);
												}
												}} >
													<img title='To the Pre Month'  data-id={project.pt_id?project.pt_id:0} src={iconMoveToPre} width={16} /></a>
											</div>
											<div className='float-right' >
											<a href='javascript:void(0)' onClick={(e)=>{
												e.persist();
												var _projects	= projects;
												var _index	= _projects.findIndex(p=>p.pt_id == e.target.attributes["data-id"].nodeValue);
												const taskInfo = "#" + _projects[_index].pt_id + " - " + _projects[_index].task ;
												if(window.confirm("Are you sure to delete?\n" + taskInfo)){
													props.remove(_index);
												}
												}} >
													<img title='Remove the Task'  data-id={project.pt_id?project.pt_id:0} src={iconRubbishBin} width={16} /></a>
											</div>
											</>

										: ''}											
												
												<br />
												<small>{project.prj_address}, {project.prj_city}, {project.prj_postal_code}</small><br/>
											{/* {project.start_date}<br/> */}

													<Avatar size={32} className='float-left' src={getSource(project)} title={project.receiver} />
													<img src={iconDuration} width={16} title="Days You have Worked On" />
													{'	'}<small>{diffDays0}</small>
													<img src={iconDeadline} width={16} title="Dys To Deadline"  />
													{'	'}<small>{diffDays}</small>{'	'}

													<img style={{cursor: 'pointer'}} src={iconSchedule} width={16} title="Estimate Hours" />
													<small>{project.estimate_hours? project.estimate_hours : '-'}</small>{'	'}
													
													<img src={iconHourGlass} width={13} title="Real Minutes" />
													<small>{project.real_hours?project.real_hours*1 : 0} ′</small>{'	'}

													<img src={iconThumbsUp} width={16} title="Percent Done" />
													<small>{project.percent_done?project.percent_done*1 : 0}</small>{'	'}
													
													{!_performance || _performance<60?
														<img src={iconLowPerformance} width={13} title="Performance" />
													:''}
													{_performance>=60 && _performance<80?
														<img src={iconMiddlePerformance} width={13} title="Performance" />
													:''}
													{_performance>=80?
														<img src={iconHightPerformance} width={13} title="Performance" />
													:''}
													<small>{_performance? _performance: 0}</small>
													
													<br />
													{/*													
													<Row>
														<Col lg={3} md={3} sm={3} xs={6} >
															<img className='float-left' src={iconDuration} width={24} />
															<small>{project.estimate_hours ? (project.real_hours/60/project.estimate_hours).toFixed(1) : ''}</small>
															{'  '}
														</Col>
														<Col lg={9} md={9} sm={9} xs={9} >
															<Progress color="primary" value={project.percent_done} >
																{project.percent_done} %
															</Progress>
														</Col>
													</Row> 
													*/}
												{/* <i>{project.sender_id}</i><Badge color="light" >{project.estimate_hours}</Badge>{'     -     '}
												<i>{project.receiver}</i><Badge color="info" >{Math.round(project.real_hours*10/60)/10}</Badge><br/>
												<Progress
													color="info"
													value={project.estimate_hours ?Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours)):0}
													className="mb-3"
												>
													{project.estimate_hours ?Math.round(100*(project.percent_done/100)/(project.real_hours/60/project.estimate_hours)):0}%
												</Progress> */}

												{/* <img src={iconDeadline} width={24} title={project.expected_end_date?project.expected_end_date : ''} /> */}
											{/* <h4 className='float-right' >{project.prj_id}</h4>	 */}
											{/* <i className='float-left' >{project.receiver}</i> */}
											{/* <br/>
											{project.expected_end_date?project.expected_end_date : ''} */}
									</CardTitle>
								</CardBody>
								<CardFooter style={{ backgroundColor : (project.clockedIn*1==1? '#348F50' : '#fff')}}  >
									<small>
										<i>
											{project.task_status}
										</i>
									</small>
									<h4 
									style={{display:'inline'}} className='float-right' >
											{project.prj_id}
									</h4>									
								</CardFooter>
							</Card>
						</div>
						<br />
					</>
				:''}
				</>
				)
			})}
		</>
	)
}

export const Grid = (props) =>{
	var projects	=	props.projects;
	projects = projects.sort((a,b) => a.task_status_id - b.task_status_id)
	var tempValue	=	[
		{task_status_id:7, task_status:"In Queue"},
		{task_status_id:8, task_status:"To Start"},
		{task_status_id:9, task_status:"In Progress"},
		{task_status_id:10, task_status:"Done"},
		{task_status_id:11, task_status:"Closed"},
		{task_status_id:12, task_status:"Hold/Wait/Pending"},
		]; 
	//var _style		=	{float:'left', width : '200px', height : 'auto', marginLeft : '2px', paddingRight : '2px', borderRight : '1px solid gray'};
	var _style		=	{ float: 'left', width : '250px'};
	return (
		<>
			<div className='Container Flipped'>
				<div className='Content' >
					<table className='card' >
						<tr>
						{tempValue.map(project=>{
							return(
								<>
									<td className="border-right" status-id={project["task_status_id"]} status={project["task_status"]} onDrop={(event)=>props.drop(event)} onDragOver={(event)=>allowDrop(event)}  style={props.columns.findIndex(i=>i==project["task_status_id"]*1) ==-1?{display:'none'}:{}} >
										<LeadLabel  text={project["task_status"]} projects={projects} status={project["task_status_id"]} />											
										<Lead clockOut={(event, _projects)=>props.clockOut(event, _projects)} clockIn={(event, _projects)=>props.clockIn(event, _projects)} projects={projects} status={project["task_status_id"]} drag={(e)=>{props.drag(e)}} onActionHanlder={(e)=>{props.onActionHanlder(e)}} onProjectManager={(e)=>{props.onProjectManager(e)}} remove={(i)=>props.remove(i)} nextScrum={(i)=>props.nextScrum(i)} preScrum={(i)=>props.preScrum(i)} />
									</td>								
								</>
							);
							})
						}
						
						</tr>
					</table>
				</div>
			</div>
		</>		
	)
}

export default ViewProjectsOfPerson;